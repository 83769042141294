import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import api from "../../configs/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const DownloadModal = ({ isOpen, closeModal, fileName }) => {
  console.log("ini modal download");
  const [isSubmit, setIsSubmit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("data submit: ", name, email);

    try {
      const inputData = {
        name,
        phone: "",
        affiliation: "",
        email,
        message: "Download Technical Data Sheet",
      };

      setIsLoading(true);
      const { data } = await axios({
        method: "POST",
        url: `${api}/api/v1/nohara/messages/add`,
        data: inputData,
      });

      if (data && data.status === "success") {
        await setIsLoading(false);
        await setIsSubmit(true);
      }
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  };

  const handleChange = (event) => {
    event.persist();
    console.log(event.target.name);
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;

      default:
        break;
    }
  };

  const emailHandleChange = (event) => {
    setEmail(event.target.value);
  };

  const downloadFile = () => {};
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Download TDS"
      ariaHideApp={false}
    >
      <div className="modal-wrapper">
        <a onClick={closeModal} className="btn-close-modal">
          X
        </a>
        <div className="modal-content-wrapper">
          <div className="modal-title">
            <h2>DOWNLOAD DOCUMENT</h2>
          </div>
          {isSubmit ? (
            <div className="modal-subtitle">Thank You</div>
          ) : (
            <div className="modal-subtitle">
              Enter your email before downloading this document
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {isSubmit ? null : (
              <div className="modal-input-wrapper">
                <input
                  className="input-modal"
                  type="text"
                  placeholder="Enter your name ..."
                  required
                  value={name}
                  name="name"
                  onChange={handleChange}
                />
                <input
                  className="input-modal"
                  type="email"
                  placeholder="Enter your email ..."
                  required
                  value={email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
            )}
            {isSubmit ? null : (
              <button type="submit" className="btn-modal-download">
                {isLoading ? "Loading ..." : "Submit"}
              </button>
            )}
          </form>
          {isSubmit ? (
            <a
              href={require(`../../assets/tds/${fileName}`)}
              onClick={closeModal}
              className="datasheet-btn"
              rel="noopener noreferrer"
              download
            >
              <button className="btn-modal-download">Download Now</button>
            </a>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
