import React, { Component } from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { setNews } from "../../redux/actions/newsAction";
import Navbar from "../NavBar/NavBar";

import DropdownDownloadModal from "../Utils/ModalDownloadDropdown";
import DownloadModal from "../Utils/Modal";

import moment from "moment";

const homeBanner = [
  {
    image: require("../../assets/images/banner/id-1.png"),
    mobImage: require("../../assets/images/banner/id-1.png"),
  },
  {
    image: require("../../assets/images/banner/id-2.png"),
    mobImage: require("../../assets/images/banner/id-2.png"),
  },
  {
    image: require("../../assets/images/banner/id-4.png"),
    mobImage: require("../../assets/images/banner/id-4.png"),
  },
  {
    image: require("../../assets/images/banner/id-4.png"),
    mobImage: require("../../assets/images/banner/id-4.png"),
  },
  {
    image: require("../../assets/images/banner/id-5.png"),
    mobImage: require("../../assets/images/banner/id-5.png"),
  },
  {
    image: require("../../assets/images/banner/id-6.png"),
    mobImage: require("../../assets/images/banner/id-6.png"),
  },
  {
    image: require("../../assets/images/banner/id-7.png"),
    mobImage: require("../../assets/images/banner/id-7.png"),
  },
];

const productList = [
  {
    image: require("../../assets/images/products/home/1.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "Membran Pilihan Cermat",
  },
  {
    image: require("../../assets/images/products/home/2.jpg"),
    name: "Insutech Bitumax",
    desc: "Torch on Waterproofing",
    slogan: "Primadona Aplikator Membran",
  },
  {
    image: require("../../assets/images/products/home/3.jpg"),
    name: "Insutech Insucoat",
    desc: "Bitumen Primer",
    slogan: "Solusi Primer Serba Bisa",
  },
  {
    image: require("../../assets/images/products/home/4.jpg"),
    name: "Flortech Rokrete",
    desc: "Epoxy-PU floor coating",
    slogan: "Proteksi Lantai Paling Kuat",
  },
  {
    image: require("../../assets/images/products/home/5.jpg"),
    name: "Flortech Florcoat",
    desc: "Epoxy-PU floor coating",
    slogan: "Warna Lantai Memukau Lebih Lama",
  },
  {
    image: require("../../assets/images/products/home/7.jpg"),
    name: "Maris Polymers Mariseal 250	g",
    desc: "Polyurethane Coating	",
    slogan: "Premium Waterproofing Paling Tangguh",
  },
  {
    image: require("../../assets/images/products/home/8.jpg"),
    name: "Brai Magnum",
    desc: "Torch on membrane",
    slogan: "Membran Dengan Kualitas Tiada Tanding",
  },
  {
    image: require("../../assets/images/products/home/9.jpg"),
    name: "Laticrete Guard EA",
    desc: "Epoxy Acrylic floor coating",
    slogan: "Perbaiki Lapisan Lantai Lebih Cepat",
  },
  {
    image: require("../../assets/images/products/home/10.jpg"),
    name: "NIPO Polymix 406",
    desc: "Cementitious waterproofing	",
    slogan: "Waterproofing Semen Jangkauan Paling Luas",
  },
  {
    image: require("../../assets/images/products/home/11.jpg"),
    name: "Nipoclad Acrylic",
    desc: "Acrylic coating waterproofing",
    slogan: "Cat Coating Hebat Pencegah Bocor",
  },
  {
    image: require("../../assets/images/products/home/12.png"),
    name: "Oil soluble Poly Urethane",
    desc: "Polyurethane grouting	",
    slogan: "Penutup Celah Pada Beton Penyebab Bocor",
  },
  {
    image: require("../../assets/images/products/home/13.png"),
    name: "Grouting Accessories",
    desc: "",
    slogan: "",
  },
];

const projectList = [
  {
    image: require("../../assets/images/projects/1.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/2.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/3.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/4.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/5.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/6.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/7.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/8.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
  {
    image: require("../../assets/images/projects/9.jpg"),
    name: "Insutech Alphaproof",
    desc: "Torch on Waterproofing",
    slogan: "slogan",
  },
];

class Home extends Component {
  state = {
    news: null,
    modalIsOpen: false,
  };

  openModal = () => {
    console.log("open modal");
    this.setState(
      {
        modalIsOpen: true,
      },
      () => {
        console.log("state home: ", this.state);
      }
    );
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  getNews = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/showall`,
      });

      if (data && data.status === "success") {
        this.setState(
          {
            news: data.message,
          },
          () => {
            console.log(this.state.news);
            this.props.setNews(data.message);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.getNews();
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { news } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DropdownDownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
        />
        <div className="home-container">
          {/* Top Image  */}
          <div className="top-image">
            <img className="desktop" src={homeBanner[0].image} alt="" />
            <img className="mob" src={homeBanner[0].mobImage} alt="" />
          </div>

          <div className="id-section-container">
            <div className="id-content-wrapper">
              <div className="id-content-center">
                <img
                  src={homeBanner[1].image}
                  alt=""
                  style={{ width: "80%", objectFit: "cover" }}
                />
                <h4 style={{ fontWeight: "bold", color: "black" }}>
                  Jalur distribusi kami tersebar di berbagai kota besar di
                  Indonesia : Medan, Batam, Bandar Lampung, Jabodetabek,
                  Bandung, Semarang, Surabaya, Bali-Lombok, Balikpapan,
                  Samarinda, dan Makassar.
                </h4>
              </div>
            </div>
          </div>

          <div className="padding-page">
            <div>
              <div className="sub-content">
                <div className="sub-content-top">
                  <h4
                    style={{
                      borderBottom: "1px solid black",
                      width: "25%",
                      textAlign: "center",
                      lineHeight: "200%",
                      margin: "auto",
                      paddingTop: "30px",
                      marginBottom: "30px",
                    }}
                  >
                    OUR PRODUCTS
                  </h4>
                  <div className="id-home-product-container">
                    {productList.map((item, index) => (
                      <div
                        key={index}
                        className={
                          index == 1 || index == 5 || index == 9
                            ? "id-home-product-wrapper-2nd"
                            : index == 0 ||
                              index == 4 ||
                              index == 8 ||
                              index == 2 ||
                              index == 6 ||
                              index == 10
                            ? "id-home-product-wrapper-3rd"
                            : "id-home-product-wrapper"
                        }
                      >
                        <img
                          className="mb-10"
                          src={item?.image}
                          alt=""
                          style={{
                            width: "100%",
                          }}
                        />
                        <div className="text-center text-light text-small mb-10">
                          {item?.name}
                        </div>
                        <div className="text-center text-light text-small mb-10">
                          {item?.desc}
                        </div>
                        <div className="text-center text-light text-small mb-10">
                          {item?.slogan}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="id-section-container">
            <div className="id-content-wrapper">
              <div className="id-content-center">
                <img
                  src={homeBanner[2].image}
                  alt=""
                  style={{ width: "80%", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>

          <div className="id-section-download">
            <div className="id-download-button-wrapper">
              <h4>
                <a className="id-download-btn" onClick={this.openModal}>
                  Download Techical Data
                </a>
              </h4>
            </div>
            <div className="id-download-button-wrapper">
              <img
                src={require("../../assets/images/brochure-download.png")}
                alt=""
                style={{ width: "50%", objectFit: "cover" }}
              />
            </div>
          </div>

          <div className="id-section-container">
            <div className="id-content-wrapper">
              <div className="id-content-center">
                <img
                  src={homeBanner[3].image}
                  alt=""
                  style={{ width: "80%", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>

          <div className="id-section-container">
            <img
              src={homeBanner[4].image}
              alt=""
              style={{ width: "100%", objectFit: "cover" }}
            />
          </div>

          <div className="padding-page">
            <div>
              <div className="sub-content">
                <div className="sub-content-top">
                  <h4
                    style={{
                      borderBottom: "1px solid black",
                      width: "25%",
                      textAlign: "center",
                      lineHeight: "200%",
                      margin: "auto",
                      paddingTop: "30px",
                      marginBottom: "30px",
                    }}
                  >
                    OUR PROJECTS
                  </h4>
                  <div className="id-home-product-container">
                    {projectList.map((item, index) => (
                      <div
                        key={index}
                        className={
                          index == 1 || index == 4 || index == 7
                            ? "id-home-project-wrapper-2nd"
                            : index == 0 || index == 3 || index == 6
                            ? "id-home-project-wrapper-3rd"
                            : "id-home-project-wrapper"
                        }
                      >
                        <img
                          className="mb-10"
                          src={item?.image}
                          alt=""
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="id-section-container">
            <img
              src={homeBanner[5].image}
              alt=""
              style={{ width: "100%", objectFit: "cover" }}
            />
          </div>

          <div className="international-news">
            <div className="news-title">
              <h5 className="news-title__text" style={{ color: "black" }}>
                NEWS
              </h5>
            </div>
            <div className="news-list-container">
              {news &&
                news.map((list, index) => (
                  <Link
                    to={`/news/${list.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    className="id-news-wrapper"
                  >
                    <div className="news-date">
                      {moment(list.createdAt).format("DD-MMM-YY")}
                    </div>
                    <div className="news-box">
                      <div className="news-tag-border">{list.tag}</div>
                    </div>
                    <div className="news-title-content">{list.title}</div>
                    <div className="news-icon">
                      <i className="far fa-clone"></i>
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          <div className="id-section-container">
            <div className="id-content-wrapper">
              <div className="id-content-center">
                <h4 className=" text-bold text-black"></h4>
                <h4 className="mb-10 text-bold text-black">
                  <p>Link ideas. Build Future. </p>
                  <p>
                    Nohara Japan leading transformation and productivity
                    improvement of construction industry by using Building
                    Information Modelling.
                  </p>
                </h4>
                <img
                  src={homeBanner[6].image}
                  alt=""
                  style={{ width: "80%", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>

          <div className="id-section-download">
            <div className="id-download-button-wrapper">
              <h4>
                <a className="id-download-btn" onClick={this.openModal}>
                  Download Techical Data
                </a>
              </h4>
            </div>
            <div className="id-download-button-wrapper">
              <img
                src={require("../../assets/images/brochure-download.png")}
                alt=""
                style={{ width: "50%", objectFit: "cover" }}
              />
            </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    news: state.news.news,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    setNews: (data) => dispatch(setNews(data)),
  };
};

export default connect(mapStateToprops, mapDispatchToprops)(Home);
