import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { getNews, getYears } from "../../redux/actions/newsAction";

import NavbarNews from "../NavBar/NavbarNews";
import FooterNews from "../Footer/FooterNews";
import NewsRightSide from "./newsRightSide";
import moment from "moment";

class News extends Component {
  onDeleteHandler = async (id) => {
    try {
      const { data } = await axios({
        method: "DELETE",
        url: `${api}/api/v1/nohara/news/delete/${id}`,
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJyb2xlIjoiQWRtaW4iLCJpYXQiOjE1OTk1NTA0NTZ9.zXEOZ80pxtvlW_gde9ShwYe_nswyv9SIU7q8U1U8TqE`,
        },
      });

      if (data && data.status === "success") {
        await alert(data.message);
        await this.props.getNews();
      }
    } catch (error) {
      console.log(error);
      alert("error");
    }
  };

  getImage(content) {
    if (content) {
      let _img = content.split('src="');
      if (_img.length > 0 && _img[1]) {
        _img = _img[1].split('"');

        return _img[0];
      }
      return "";
    }
  }

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }

    this.props.getNews();
    this.props.getYears();
  }

  render() {
    const { news, years, tags, isLogin } = this.props;
    return (
      <>
        <NavbarNews />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/news.png")}
              alt=""
              style={{ opacity: 0.65 }}
            />
            <div className="news-top-card">
              <div className="news-top-card__top">
                <div>
                  <h3>NEWS</h3>
                </div>
                <div className="text-subtitle">Let us hear from you</div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <div className="news-content" style={{ marginTop: "20px" }}>
            <div>
              <span style={{ cursor: "pointer" }}>
                {" "}
                <Link to={`/`}>HOME</Link>
              </span>{" "}
              <span>{` > `}</span>{" "}
              <span style={{ cursor: "pointer" }}>News List</span>
            </div>
          </div>
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Content */}
          <div className="news-content">
            <div className="news-list-content">
              {news ? (
                news.map((list) => (
                  <Link
                    to={`/news/${list.id}`}
                    key={list.id}
                    className="news-list-item"
                  >
                    <div className="news-list-image">
                      <div className="news-image">
                        <img src={`${this.getImage(list.content)}`} alt="" />
                      </div>
                    </div>
                    <div className="news-list-headline">
                      <p style={{ marginBottom: "20px" }}>
                        {moment(list.createdAt).format("DD/MM/YY")}
                      </p>
                      <h3 style={{ marginBottom: "10px" }}>
                        {list.title}{" "}
                        {isLogin && (
                          <>
                            <Link to={`/editnews/${list.id}`}>
                              <i className="fa fa-edit"></i>
                            </Link>{" "}
                            |{" "}
                            <a>
                              <i
                                className="fa fa-trash"
                                onClick={() => this.onDeleteHandler(list.id)}
                              ></i>
                            </a>
                          </>
                        )}
                      </h3>
                      <div>
                        <p>
                          {ReactHtmlParser(
                            list?.desc ? list.desc : "<span></span>"
                          )}{" "}
                        </p>
                      </div>
                      <div className="news-tag">tag: {list.tag}</div>
                    </div>
                  </Link>
                ))
              ) : (
                <div>Can't find any News</div>
              )}
            </div>
            <NewsRightSide />
          </div>

          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Footer */}
          <div className="id-section-download">
            <div className="id-download-button-wrapper">
              <h4>
                <Link to="/indonesia/contactus" className="id-download-btn">
                  Download Techical Data
                </Link>
              </h4>
            </div>
            <div className="id-download-button-wrapper">
              <img
                src={require("../../assets/images/brochure-download.png")}
                alt=""
                style={{ width: "50%", objectFit: "cover" }}
              />
            </div>
          </div>

          <FooterNews />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    news: state.news.news,
    years: state.news.years,
    tags: state.news.tags,
    isLogin: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNews: () => dispatch(getNews()),
    getYears: () => dispatch(getYears()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
