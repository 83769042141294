import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import authReducer from "../redux/reducers/authReducer";
import newsReducer from './reducers/newsReducer'

import thunk from "redux-thunk";
const store = createStore(
  combineReducers({
    auth: authReducer,
    news: newsReducer
  }),
  compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
