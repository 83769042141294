import axios from 'axios'
import api from '../../configs/api'

export const isVietLang = () => dispatch => {
  dispatch({type: 'VIET_LANG'});
};

export const signIn = user => {
  console.log("starting", user);
  return async dispatch => {
    console.log("dispatching");
    try {
      const { data } = await axios({
        method: 'POST',
        url: `${api}/api/v1/nohara/auth/loginUser`,
        data: {
            email: user.email,
            password: user.password
        }
      })

      if(data && data.data) {
        console.log('data login: ', data.data)
        localStorage.setItem('token', data.data)
        return dispatch({ type: 'SIGNIN_SUCCESS', payload: data.data})   
      }
      throw new Error('Email or Password not match')
    } catch (error) {
      alert(error)
      console.log('console error: ', error)
      dispatch({ type: 'SIGNIN_ERROR', error})   
    }
  };
};

export const signUp = newUser => {
  return async dispatch => {
    try {
     const { data: createdUser } = await axios({
       method: 'POST',
       url: `${api}/users/general/register`,
       data: {
         ...newUser
       }
     })
     console.log(createdUser, 'signup succ')
     dispatch({ type: 'SIGNUP_SUCCESS', payload: createdUser})
    } catch (error) {
      dispatch({ type: 'SIGNUP_ERROR', error })
      console.log(error.response)
    }
  };
};

export const checkLogin = token => {
  return {
    type: "CHECK_LOGIN",
    payload: { token }
  };
};

// Log user out
export const signOut = () => dispatch => {
  localStorage.removeItem("token");
  dispatch({type: 'SIGNOUT_SUCCESS'});
};

// export const resetPassword = (email) => {
//   return async dispatch => {

//   }
// }
