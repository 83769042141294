import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { setNews } from "../../redux/actions/newsAction";
import LinkButtons from "../Utils/linkButton";

class Home extends Component {
  state = {
    news: null,
  };

  getNews = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/showall`,
      });

      if (data && data.status === "success") {
        this.setState(
          {
            news: data.message,
          },
          () => {
            console.log(this.state.news);
            this.props.setNews(data.message);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.getNews();
    window.scrollTo(0, 0);
  }

  render() {
    const { news } = this.props;
    return (
      <div className="welcome-wrapper" id="welcome">
        <div className="top-welcome">
          <div className="top-welcome-quote">
            <h1 className="quote-h1">Link Ideas. Build Future.</h1>
          </div>
          <LinkButtons />
          <div className="top-welcome-description">
            <h4>The Link You Want To Open Is Under Construction</h4>
          </div>
        </div>
        <div className="bottom-welcome">
          <Link
            to="/news"
            className="btn-welcome-news"
            target="_blank"
            rel="noopener noreferrer"
          >
            News
          </Link>
          <div className="welcome-news-wrapper">
            <div className="news-list" style={{ marginTop: "20px" }}>
              {news ? (
                news.map((list) => (
                  <div key={list.id} className="news-list-item">
                    <Link
                      to={`/news/${list.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {new Date(list.createdAt).toISOString().substr(0, 10)} ---{" "}
                      {list.title}
                    </Link>
                  </div>
                ))
              ) : (
                <div>Can't find any News</div>
              )}
            </div>
          </div>
          <div className="bottom-welcome-copyright">
            {" "}
            &copy; Copyright Nohara International 2020
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    news: state.news.news,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    setNews: (data) => dispatch(setNews(data)),
  };
};

export default connect(mapStateToprops, mapDispatchToprops)(Home);
