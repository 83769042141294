import React, { Component } from "react";
import Footer from "../Footer/Footer";
import DownloadModal from "../Utils/Modal";

import Navbar from "../NavBar/NavBar";
import DeviderComponent from "../Utils/Devider";
import ContactUsComponent from "../Utils/ContactUs";
import NipoTopComponent from "../Utils/NipoTop";
import NipoBenefitComponent from "../Utils/NipoBenefit";
import BoxTitleBgComponent from "../Utils/BoxTitleBG";
import BenefitBoxComponent from "../Utils/BenefitBox";

class Nipo extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="TSD_NIPO_ALL.pdf"
        />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/nipo.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>NIPO</h3>
                </div>
                <div className="text-subtitle">
                  Bitumen, Polymer Liquid and Cement Based Waterproofing
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <DeviderComponent height={"60px"} />
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="maris-content">
              <div className="maris-content-title">
                <h2>NOHARA X NIPO</h2>
              </div>
              <div className="maris-content-p">
                At Nohara we are selective in our products range and we will
                prequalify each brand to ensure we bring good quality products
                to our customer.
              </div>
              <div className="maris-content-p">
                All NIPO products are directly imported from Singapore and are
                also fully supported by technical support from NIPO technical
                experts. Nipo is a Singapore company engaged in the development
                of building materials since 1980.
              </div>
            </div>
          </div>
          {/* Content */}
          <ContactUsComponent />
          {/* content nipo start */}
          <div className="maris-list-container">
            <BoxTitleBgComponent title={"Cementitious waterproofing system"} />
            <NipoTopComponent
              image={"products/name/1.png"}
              url1={"products/icons/1.png"}
              url2={"products/icons/6.png"}
              subtitle={`
              A 2-part copolymer modified cementitious waterproofing system suitable for resurfacing on concrete and other construction materials.
              A smooth slurry can be prepared by mixing with a slow speed drill. This can then be applied by a trowel, roller or brush.
              <br/>
              <br/>
              RECOMMENDED USES:<br/>
              Flat roof small area, Wet area: Toilets, Balconies, Kitchen
              Canopies, Under Floor topping as an in-between 
              coat, Vertical surface, For waterproofing of drinking water, tanks, reservoirs, and clear wells
              `}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "Double Protection",
                  desc: "With more chemical additive for more effective water leakage prevention.",
                  image: "icons/7.png",
                },
                {
                  title: "Green label",
                  desc: "Certifed as a non-toxic, non-VOC based material by Singapore Green Label. Save for human use.",
                  image: "icons/1.png",
                },
                {
                  title: "Flexible",
                  desc: "Anti-crack material that provides resistance when expansion and shrinkage occurs",
                  image: "icons/15.png",
                },
                {
                  title: "Anti UV ",
                  desc: "Prevent water leakage on concrete roofing and has good resistance for UV.",
                  image: "icons/2.png",
                },
              ]}
            />

            <DeviderComponent />

            <NipoTopComponent
              image={"products/name/2.png"}
              url1={"products/icons/11.png"}
              url2={"products/icons/12.png"}
              subtitle={`
                GLASSFIBRE NETTING is designed to provide strong reinforcement for most waterproofing emulsions, joints on pre-fabricated panel wall, dry wall, plaster ceiling and repair of cracks. GLASSFIBRE NETTING reduces the constraint of working with loose and inconsistent distribution of strands. Hence, retaining the integrity of materials.
                <br/>
                <br/>
                OUTSTANDING FEATURES: <br/>
                Great Strength, Will not Trap Air that cause Blisters and Bubbles, Easy to handle, Minimum Wastage.
              `}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "Great Strength",
                  desc: "Retaining the integrity material. Can be used for better reinforcement.",
                  image: "icons/10.png",
                },
                {
                  title: "Air Bubble Free",
                  desc: "Minimizes trapped air with the fiber surface to keep the material solid",
                  image: "icons/11.png",
                },
                {
                  title: "Long Lasting",
                  desc: "NIPO Fibernett does not decompose and will not rust over time.",
                  image: "icons/32.png",
                },
                {
                  title: "No Crack",
                  desc: "High quality material with tensile strength that prevent cracks on waterproofing layer",
                  image: "icons/30.png",
                },
              ]}
            />
          </div>

          <ContactUsComponent />
          <div className="maris-list-container">
            <BoxTitleBgComponent title={"Acrylic waterproofing system"} />
            <NipoTopComponent
              image={"products/name/3.png"}
              url1={"products/icons/15.png"}
              url2={"products/icons/16.png"}
              subtitle={`
                NipoClad Acrylic is a water-based
                environmentally-friendly liquid applied acrylic
                polymer coating which can be used in a wide
                range of applications including: Waterproofing, Vapour-proofing, Concealing unsightly hairline cracks, Sound Absorption and Anti-carbonation.
                <br/><br/>
                RECOMMENDED USES: <br/>
                Concrete substrates, Metal substrates, Clay roof tiles, Refurbishment of old roofs, Vertical wall
              `}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "Coverage up to 33 m2",
                  desc: "Wide spreadability, with a range of 0.3 kg per m2 per coat",
                  image: "icons/9.png",
                },
                {
                  title: "More Economic",
                  desc: "More economical with a wide spread so that the consumption of material is less",
                  image: "icons/13.png",
                },
                {
                  title: "300% Elongation",
                  desc: "Has a high elongation to prevent cracking of the material",
                  image: "icons/28.png",
                },
                {
                  title: "Anti UV",
                  desc: "UV-resistant feature allows for use in outdoor areas exposed to direct sunlight",
                  image: "icons/2.png",
                },
              ]}
            />

            <DeviderComponent />

            <NipoTopComponent
              image={"products/name/4.png"}
              url1={"products/icons/2.png"}
              url2={"products/icons/3.png"}
              subtitle={`Nipo ThermoShield+ is designed through the
              state-of-the-art technology for excellent solar
              reflectance, thermal insulation and waterproofing
              properties.
              <br/>
              <br/>
              Unlike the common Hollow Glass or Ceramic 
              Bead technology, the unique technology 
              incorporates the synergetic effect of solar 
              reflectance and thermal insulation of the pigment.`}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "Excelent Waterproofing",
                  desc: "Contains thermal silica pigment and thermal silica coating which provides a waterproof coating",
                  image: "icons/26.png",
                },
                {
                  title: "Solar Reflectance",
                  desc: "Using synergetic effect of solar reflectance and thermal insulation of the pigment in order to reduce surface temperature of buildings ",
                  image: "icons/35.png",
                },
                {
                  title: "Soundproofing",
                  desc: "Dampening rain sound on the roof. Reduces sound transmission through the roof.",
                  image: "icons/33.png",
                },
                {
                  title: "Reduces Energy Consumption",
                  desc: "Protecting the environment by reducing urban heat island effects.",
                  image: "icons/34.png",
                },
              ]}
            />
          </div>

          <ContactUsComponent />
          <div className="maris-list-container">
            <BoxTitleBgComponent title={"Bituminous waterproofing system"} />
            <NipoTopComponent
              image={"products/name/5.png"}
              url1={"products/icons/9.png"}
              url2={"products/icons/10.png"}
              subtitle={`
              A non-fibrated, stable, water-borne bitumen emulsion.
              It is a non-flammable, odour and taint-free, water-borne bituminous primer. It promotes adhesion between the substrate and the subsequent bituminuos waterproofing membrane – Sheet or Liquid applied.
              <br/>
              <br/>
              RECOMMENDED USES. For the priming of: Old and new concrete, Metal roofs, Refurbishment of old roofs.
              ADVANTAGES: Water-borne, non-toxic, Act as a bonding agent to old substrates, Easy to apply.
              `}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "Non Toxic",
                  desc: "Contains non-toxic components that are safe for humans use.",
                  image: "icons/20.png",
                },
                {
                  title: "Bonding Agent",
                  desc: "Promotes adhesion between the substrate and the subsequent bituminuos waterproofing membrane ",
                  image: "icons/29.png",
                },
                {
                  title: "Air Bubble Free",
                  desc: "Prevent air bubble trapped on surface that can cause blisters and peel-off",
                  image: "icons/11.png",
                },
                {
                  title: "More Economic",
                  desc: "Application with only 1 time coating applied. The consumption is about 0,5 kg / m2",
                  image: "icons/13.png",
                },
              ]}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/name/6.png"}
              url1={"products/icons/7.png"}
              url2={"products/icons/8.png"}
              subtitle={`
              The environmentally friendly one-part water-borne bituminous elastomeric.The colour is brownish black and dries to a tough flexible black coating.
              <br/>
              <br/>
              When applied to specified thickness, 
              NIPOPRUF Bituminous Elastomeric is suitable 
              for general waterproofing use with the ability to 
              bridge cracks up to 2mm in width. 
              <br/>
              <br/>
              RECOMMENDED USE: Exposed Roof, Concealed Roof, Retaining Wall, Lift Pit, Wet Area, Basement, Gutter, Concrete Tank.
              `}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "More Economic",
                  desc: "with 0.5 kg can cover up to 1 m2 per layer of substrate surface.",
                  image: "icons/13.png",
                },
                {
                  title: "500% Elongation",
                  desc: "suitable for general and waterproofing use with the ability to bridge cracks up to 2mm in width. it can perform in immersed area",
                  image: "icons/28.png",
                },
                {
                  title: "Green Label Certified",
                  desc: "suitable for general and waterproofing use with the ability to bridge cracks up to 2mm in width. it can perform in immersed area",
                  image: "icons/1.png",
                },
                {
                  title: "Good Bonding to Substrate",
                  desc: "Promotes adhesion between the substrate and the subsequent bituminuos waterproofing membrane ",
                  image: "icons/29.png",
                },
              ]}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/name/7.png"}
              url1={"products/icons/5.png"}
              url2={"products/icons/4.png"}
              subtitle={`
              The environmentally friendly one-part liquid applied Nipo R-Pruf is a water-borne product which is effective in CONSTRAINING root directional growth. It works in areas where root traps and deflectors do not.
              <br/>
              <br/>
              When applied to specified thickness, Nipo 
              R-Pruf is an effective Root Inhibitor. Can be added directly to cement mortar for the purpose of constructing structures such as planter box. It will slow down and ultimately stops the length growth of the roots. The influence of Nipo R-Pruf on the root growth is localized. Root sprouts in the immediate area which has no contact with the Nipo R-Pruf remain healthy and unaffected.
              <br/>
              Combined features of the barrier, the site, and barrier installation and maintenance are critical to effectiveness of Nipo R-Pruf.
              `}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "Protect Material",
                  desc: "Inhibit the root growth penetration to concrete substrate",
                  image: "icons/14.png",
                },
                {
                  title: "Excellent Strength",
                  desc: "Protects the concrete surface from the push of growing roots and prevents cracking of the substrate area.",
                  image: "icons/10.png",
                },
                {
                  title: "Heat Resistance",
                  desc: "Resistant to hot weather condition and doesn't crack.",
                  image: "icons/6.png",
                },
                {
                  title: "Economical",
                  desc: "can also be used as a 2-component solution with a mixing ratio of equal parts to most cement mortar. ",
                  image: "icons/13.png",
                },
              ]}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/name/8.png"}
              url1={"products/icons/17.png"}
              url2={"products/icons/18.png"}
              subtitle={`
              A solvent-borne, thick black Bituminous Compound formulated with specially selected fibre, for a semi stiff paste. It provides a soft and flexible bond which hardens and softens with changes of temperature.
              <br/>
              <br/>
              ADVANTAGES: Does not require a primer, Maintains a viscous jelly state permanently, Strong creep performance, Easy to handle, Self-healing, Anti-leaking, Anti-fatigue, Stress-free, Cold-applied, In-can long shelf life, Free from asbestos, Can be applied on non-porous substrates, Protects against rust, Sound/Vibration dampening
              `}
            />
            <NipoBenefitComponent
              benefit={[
                {
                  title: "Without Primer",
                  desc: "Can be used immediately after the area is cleaned without having to use a primer",
                  image: "icons/24.png",
                },
                {
                  title: "Viscosious Jelly State",
                  desc: "Maintains a viscous jelly state permanently so it stays flexible and doesn't crack after a while",
                  image: "icons/25.png",
                },
                {
                  title: "Anti Leaking",
                  desc: "Prevent the water penetration to the substrate.",
                  image: "icons/26.png",
                },
                {
                  title: "Self-healing",
                  desc: "Can return to its original shape even under pressure",
                  image: "icons/36.png",
                },
              ]}
            />
          </div>

          <ContactUsComponent />
          <BenefitBoxComponent
            product={"NIPO"}
            benefit={[
              {
                title: "Strong R&D Center",
                desc: "Established in 1980, Nipo International started as manufacturer of bitumen-based coatings. Now constantly explores new tech with government agencies and Institutes of Higher Learnings in developing solutions in material sciences in Singapore HQ.",
              },
              {
                title: "Expert in Liquid-applied coatings",
                desc: "Over the years, NIPO has developed expertise in high spec liquid-applied coatings to protect building and structures, include solutions for waterproofing, thermal insulation, anti-corrosion, sound absorption, adhesives, etc.",
              },
              {
                title: "Lots of Choice Solution",
                desc: "NIPO products consist of many basic material components. Polymer based, Bituminous based and Water based. makes it easy to bond with the substrate material",
              },
              {
                title: "Perfect Combination Solution",
                desc: "NIPO products can be combined with other NIPO products. So that the perfect combination is created to overcome the problem of leakage",
              },
            ]}
          />
          <ContactUsComponent />

          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                Learn more of NIPO product properties
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                Technical Data Sheet
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Nipo;
