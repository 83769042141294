import React, { Component } from "react";


import { connect } from 'react-redux'
import Navbar from '../NavBar/NavbarVietnam'
import Footer from '../Footer/FooterVietnam'
import { Link } from 'react-router-dom'

class ProductVietnam extends Component {
  state = {
    langChoise: 'VIET',
    langText: {
      eng: {
        sub1: {
          text: "We at Nohara intend to continue creating the future of homes, cities, and living."
        },
        sub2: {
          text: "Using the strength of the technical capacity we have cultivated in the 420 years since our establishment in 1598, we focus on our core businesses of building materials, construction, and urban spatial design and construction while devoting energy to creating unique new businesses."
        },
        sub3: {
          text: "Brai Torch-on membrane waterproofing"
        },
        sub4: {
          text: "Bitumen polymer membrane solution for general waterproofing application"
        },
        sub5: {
          text: "Maris Polymers Liquid Polyurethane system"
        },
        sub6: {
          text: "NIPO Speciality construction chemicals"
        },
        sub7: {
          text: "Bitumen Primer, coating, and anti-root chemical"
        },
        sub8: {
          text: "Laticrete"
        },
        sub9: {
          text: "Concrete floor densifier, hardener, and sealer."
        },
      },
      viet: {
        sub1: {
          text: "Tại Nohara, chúng tôi mong muốn tạo dựng tương lai cho những ngôi nhà, thành phố và cuộc sống tốt đẹp hơn."
        },
        sub2: {
          text: "Sử dụng thế mạnh về năng lực kỹ thuật  mà tập đoàn đã tích lũy hơn 420 năm kể từ khi thành lập vào năm 1598, tập đoàn Nohara tập trung vào các lĩnh vực kinh doanh cốt lõi là vật liệu xây dựng, thiết kế và xây dựng không gian đô thị đồng thời liên tục kiến tạo những ngành kinh doanh mới"
        },
        sub3: {
          text: "Màng chống thấm khò lửa Brai"
        },
        sub4: {
          text: "Giải pháp màng Bitum Polyme trong lĩnh vực chống thấm"
        },
        sub5: {
          text: "Hệ Polyurethane của Maris Polymers"
        },
        sub6: {
          text: "Hóa chất xây dựng chuyên dụng NIPO"
        },
        sub7: {
          text: "Lớp lót Bitum và hóa chất ức chế rễ cây"
        },
        sub8: {
          text: "Laticrete"
        },
        sub9: {
          text: "Chất làm đặc , tăng cứng và chất trám khe cho sàn bê tông."
        },
      }
    }
  }

  componentDidMount() {
    console.log('mounted')
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if(header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { isEn } = this.props
    const { langText } = this.state
    return (
      <>
        <Navbar />
        <div className={isEn ? "home-container" : "home-container viet-theme"}>
          {/* Top Image  */}
            <div className="contact-top-image">
              <img src={require("../../assets/images/products_r01.jpg")} alt=""/>
              <div className="contact-top-card">
                <div className="contact-top-card__top">
                  <div><h3>PRODUCT</h3></div>
                  <div className="text-subtitle">OUR PRODUCT</div>
                </div>
              </div>
            </div>
          {/* Devider */}
          <div style={{ width: '100vw', height: '40px'}}>
            &nbsp;
          </div>

          <div className="product-top-text">
            <p className="product-top-text__title">{ langText[isEn ? 'eng' : 'viet'].sub1.text }</p>
            <p>{ langText[isEn ? 'eng' : 'viet'].sub2.text }</p>
          </div>
          {/* Content */}
          <div className="products-list-container">
            <div className="products-list-left">
              <div className="products-list-img">
                <Link to="/vietnam/brai">
                  <img src={require('../../assets/images/brai_product_page.jpg')} alt=""/>
                </Link>
              </div>
              <div className="products-list-text">
                <div className="products-list-title mb-30" style={{fontFamily: !isEn ? "VNINormal" : "", fontWeight: !isEn ? "bold" : ""}}>{ langText[isEn ? 'eng' : 'viet'].sub3.text }</div>
                <div className="products-list-paragraph">
                  <p className="mb-30">{ langText[isEn ? 'eng' : 'viet'].sub4.text }</p>
                </div>
              </div>
            </div>
            <div className="products-list-right">
              <div className="products-list-img-right">
                <img src={require('../../assets/images/maris_product_page.jpg')} alt=""/> 
              </div>
              <div className="products-list-text-right">
                <div className="products-list-title mb-30" style={{fontFamily: !isEn ? "VNINormal" : "", fontWeight: !isEn ? "bold" : ""}}>{ langText[isEn ? 'eng' : 'viet'].sub6.text }</div>
                <div className="products-list-paragraph-right">
                  <p className="mb-30">Speciality liquid based polyurethane waterproofing for high performance usage</p>
                </div>
              </div>
            </div>
            <div className="products-list-left">
              <div className="products-list-img">
                <Link to="/vietnam/nipo">
                  <img src={require('../../assets/images/nipo_product_page.jpg')} alt=""/>
                </Link>
              </div>
              <div className="products-list-text">
                <div className="products-list-title mb-30" style={{fontFamily: !isEn ? "VNINormal" : "", fontWeight: !isEn ? "bold" : ""}}>{ langText[isEn ? 'eng' : 'viet'].sub6.text }</div>
                <div className="products-list-paragraph">
                  <p className="mb-30">{ langText[isEn ? 'eng' : 'viet'].sub7.text }</p>
                </div>
              </div>
            </div>
            <div className="products-list-right">
              <div className="products-list-img-right">
                <Link to="/vietnam/laticrete">
                  <img src={require('../../assets/images/laticrete_product_page.png')} alt=""/>
                </Link>
              </div>
              <div className="products-list-text-right">
                <div className="products-list-title mb-30" style={{fontFamily: !isEn ? "VNINormal" : "", fontWeight: !isEn ? "bold" : ""}}>{ langText[isEn ? 'eng' : 'viet'].sub8.text }</div>
                <div className="products-list-paragraph-right">
                  <p className="mb-30">{ langText[isEn ? 'eng' : 'viet'].sub9.text }</p>
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <div style={{ width: '100vw', height: '40px'}}>
            &nbsp;
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = state => {
  return {
    isEn: state.auth.isEn
  }
}

export default connect(mapStateToprops)(ProductVietnam);
