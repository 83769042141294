import React, { Component } from "react";
import Footer from '../Footer/Footer'

import Navbar from '../NavBar/NavBar'

class Brai extends Component {

  componentDidMount() {
    console.log('mounted')
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if(header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    return (
      <>
        <Navbar />
        <div className="home-container">
          <div className="coming-soon">
            <div>
              <div className="coming-title">COMING SOON</div>
              <div className="coming-subtitle">Content Under Construction</div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Brai;
