import React, { Component } from "react";
import { Link } from "react-router-dom";
import DownloadModal from "../Utils/Modal";

import { connect } from "react-redux";

import Navbar from "../NavBar/NavbarVietnam";
import Footer from "../Footer/FooterVietnam";

class NipoVietnam extends Component {
  state = {
    modalIsOpen: false,
    langChoise: "VIET",
    langText: {
      eng: {
        sub1: {
          text: "At Nohara we are selective in our products range and we will prequalify each brand to ensure we bring good quality products to our customer.",
        },
        sub2: {
          text: "All NIPO products are directly imported from Singapore and are also fully supported by technical support from NIPO technical experts.",
        },
        sub3: {
          text: "Nipo is a Singapore company engaged in the development of building materials since 1980. Key NIPO products that we distribute in Indonesia are THERMOSHIELD heat reﬂective paint, NIPOPRIME primer bitumen, and NIPO R-Pruf anti root chemical.",
        },
        sub4: {
          text: "NipoPrime WB-81 is a water-based, non-ﬁbrated, stable, water-borne bituminous primer.",
        },
        sub5: {
          text: "Function as a bonding agent to old substrates, seal pores on rough porous concrete to prevent air bubbles. Nipoprime is easy to apply, be it brush applied or spray applied to damp (not wet) or dry surfaces.",
        },
        sub6: {
          text: "Depending on substrates, approximately 0.2 L of NipoPrime WB-81 can cover about 1m2 per coat.",
        },
        sub7: {
          text: "Strong adhesion promoter that bonds the subsequent bitumen-based coatings such as Nipo R-pruf & Brai membranes.",
        },
        sub8: {
          text: "Nipoprime has a thick yet non-clumping material texture. thus making it easier to install in the site project.",
        },
        sub9: {
          text: "By using Nipoprime it will cover the pores on the surface and prevent air bubbles from occurring, that can damage the waterproofing layer.",
        },
        sub10: {
          text: "Nipoprime material can be dilute with 1:1 ratio with water. It has higher coverage compared to other material to maximize project profit.",
        },
        sub11: {
          text: "We're happy to answer your inquiries! Contact us for more information. +84 916282875 or duong-vg@nohara-inc.co.jp",
        },
        sub12: {
          text: "Nipo ThermoShield+ heat reflective paint, is designed through the state-of-the-art technology for excellent solar reflectance, thermal insulation and waterproofing properties.",
        },
        sub13: {
          text: "Unlike the common Hollow Glass or Ceramic Bead technology, unique technology of using Titania coating on hollow glass beads, incorporates the synergetic effect of solar reﬂectance and thermal insulation of the pigment.",
        },
        sub14: {
          text: "This brings with it, excellent results in Total Solar Reflectance (TSR) Index in order to reduce surface temperature of buildings.",
        },
        sub15: {
          text: "Increases indoor comfort by reducing room heat, making the room cooler and more comfortable so that activities inside are more productive.",
        },
        sub16: {
          text: "Save energy, save cost. Thermoshield reﬂects the heat and UV radiation. Save on electricity costs by reducing the use of air conditioners.",
        },
        sub17: {
          text: "Protecting the environment by reducing urban heat island effects. Thermoshield is an innovative materials suitable to be used in green buildings that bring beneﬁts to the environment.",
        },
        sub18: {
          text: "NIPO R Pruf Root inhibitor coating.",
        },
        sub19: {
          text: "The environmentally friendly one-part liquid applied Nipo R-Pruf is a water-borne product which is effective in CONSTRAINING root directional growth. It works in areas where root traps and deﬂectors do not. Nipo R-Pruf can prevent cracks by constraining root direction growth away from building structure.",
        },
        sub20: {
          text: "Protects construction materials from undesirable root penetration.",
        },
        sub21: {
          text: "Safe for plants. No damage to plants and yet providing long term protection .",
        },
        sub22: {
          text: "NIPO R-Pruf is waterproof and easy to apply as coating.",
        },
        sub23: {
          text: "Learn more of NIPO product properties",
        },
        sub24: {
          text: "Technical Data Sheet",
        },
        sub25: {
          text: "Contact Us",
        },
      },
      viet: {
        sub1: {
          text: "Tại Nohara, chúng tôi chọn lọc những những sản phẩm của mình và những thương hiệu nổi tiếng để mang đến những sản phẩm chất lượng tốt cho khách hàng",
        },
        sub2: {
          text: "Tất cả các sản phẩm Nipo được nhập khẩu trực tiếp từ Singapore và cũng được các chuyên gia kỹ thuật của NIPO hỗ trợ hoàn toàn về mặt kỹ thuật.",
        },
        sub3: {
          text: "Nipo là một công ty Singapore tham gia phát triển vật liệu xây dựng từ năm 1980. Các sản phẩm chính của NIPO mà chúng tôi phân phối tại Việt Nam là sơn lót Bitum NIPO PRIME và hóa chất ức chế phát triển rễ cây NIPO R-Pruf.",
        },
        sub4: {
          text: "NipoPrime WB-81 là sơn lót bitum gốc nước, không kết hạt, ổn định.",
        },
        sub5: {
          text: "Có chức năng làm chất kết dính với lớp nền cũ, bịt kín các lỗ rỗng trên bê tông xốp thô để ngăn chặn bọt khí. Nipoprime rất dễ thi công, có thể là cọ quét hoặc phun lên bề mặt ẩm (không ướt) hoặc khô.",
        },
        sub6: {
          text: "Tùy thuộc vào hiện trạng nền cũ, khoảng 0,2 L NipoPrime WB-81 có thể phủ khoảng 1m2 mỗi lớp.",
        },
        sub7: {
          text: "Làm tăng mạnh khả năng bám dính giúp liên kết các lớp phủ tiếp theo như Nipo R-pruf và màng chống thấm Brai",
        },
        sub8: {
          text: "Nipoprime có kết cấu vật liệu dày nhưng không vón cục. Do đó làm cho việc thi công dễ hơn tại dự án.",
        },
        sub9: {
          text: "Bằng cách sử dụng Nipoprime, sản phẩm sẽ che phủ các lỗ rỗng trên bề mặt và ngăn chặn bọt khí xuất hiện có thể làm hỏng lớp chống thấm.",
        },
        sub10: {
          text: "Vật liệu nipoprime có thể được pha loãng với nước theo tỷ lệ 1: 1. Sản phẩm có độ che phủ cao hơn so với các vật liệu khác để tối đa hóa lợi nhuận của dự án.",
        },
        sub11: {
          text: "Chúng tôi rất vui để trả lời những thắc mắc của các bạn! Liên hệ chúng tôi để biết thêm thông tin tại (+84) 916282875",
        },
        sub12: {
          text: "",
        },
        sub13: {
          text: "",
        },
        sub14: {
          text: "",
        },
        sub15: {
          text: "",
        },
        sub16: {
          text: "",
        },
        sub17: {
          text: "",
        },
        sub18: {
          text: "Lớp phủ ức chế rễ cây NIPO R-Pruf",
        },
        sub19: {
          text: "Nipo R-Pruf dạng lỏng thân thiện với môi trường là một sản phẩm gốc nước có hiệu quả trong việc ức chế sự phát triển theo hướng của rễ cây. Sản phẩm có tác dụng làm chệch hướng của rễ. Nipo R-Pruf có thể ngăn ngừa các vết nứt bằng cách hạn chế sự phát triển của rễ và hướng nó ra khỏi cấu trúc công trình.",
        },
        sub20: {
          text: "Bảo vệ công trình khỏi sự xâm nhập của rễ.",
        },
        sub21: {
          text: "An toàn cho cây trồng. Không nguy hại đến cây trồng và bảo vệ lâu dài",
        },
        sub22: {
          text: "NIPO R-pruf không thấm nước và dễ thi công như lớp phủ bên ngoài",
        },
        sub23: {
          text: "Tìm hiểu thêm về các đặc tính của sản phẩm NIPO",
        },
        sub24: {
          text: "Bảng dữ liệu kỹ thuật",
        },
        sub25: {
          text: "Liên hệ chúng tôi",
        },
      },
    },
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { isEn } = this.props;
    const { langText, modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="nipo.pdf"
        />
        <div className={isEn ? "home-container" : "home-container viet-theme"}>
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/nipo/nipo-top.jpg")}
              alt=""
            />
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "60px" }}>&nbsp;</div>
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="maris-content">
              <div className="maris-content-title">
                <h2>NOHARA X NIPO</h2>
              </div>
              <div className="maris-content-p">
                {langText[isEn ? "eng" : "viet"].sub1.text}
              </div>
              <div className="maris-content-p">
                {langText[isEn ? "eng" : "viet"].sub2.text}
              </div>
              <div className="maris-content-p">
                {langText[isEn ? "eng" : "viet"].sub3.text}
              </div>
            </div>
          </div>
          {/* Content */}
          <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                {langText[isEn ? "eng" : "viet"].sub11.text}
              </p>
              <Link to="/vietnam/contactus" className="maris-contact__button">
                {langText[isEn ? "eng" : "viet"].sub25.text}
              </Link>
            </div>
          </div>
          {/* content nipo start */}
          <div className="maris-list-container">
            <div className="nipo-list-left">
              <div className="nipo-list-img">
                <img
                  src={require("../../assets/images/nipo/wb-81.jpg")}
                  alt=""
                />
              </div>
              <div className="nipo-list-text">
                <div className="nipo-list-paragraph">
                  <p className="mb-15">
                    {langText[isEn ? "eng" : "viet"].sub4.text}
                  </p>
                  <p className="mb-15">
                    {langText[isEn ? "eng" : "viet"].sub5.text}
                  </p>
                  <p className="mb-15">
                    {langText[isEn ? "eng" : "viet"].sub6.text}
                  </p>
                </div>
              </div>
            </div>

            {/* nipo benefit start */}
            <div className="nipo-benefit-container">
              <div className="nipo-benefit">
                <div className="nipo-benefit-img">
                  <img
                    src={require("../../assets/images/nipo/strong.jpg")}
                    alt=""
                  />
                </div>
                <div className="nipo-benefit-text">
                  <p>{langText[isEn ? "eng" : "viet"].sub7.text}</p>
                </div>
              </div>
              <div className="nipo-benefit">
                <div className="nipo-benefit-img">
                  <img
                    src={require("../../assets/images/nipo/non-clumping.jpg")}
                    alt=""
                  />
                </div>
                <div className="nipo-benefit-text">
                  <p>{langText[isEn ? "eng" : "viet"].sub8.text}</p>
                </div>
              </div>
              <div className="nipo-benefit">
                <div className="nipo-benefit-img">
                  <img
                    src={require("../../assets/images/nipo/no-bubble.jpg")}
                    alt=""
                  />
                </div>
                <div className="nipo-benefit-text">
                  <p>{langText[isEn ? "eng" : "viet"].sub9.text}</p>
                </div>
              </div>
              <div className="nipo-benefit">
                <div className="nipo-benefit-img">
                  <img
                    src={require("../../assets/images/nipo/cost-effective.jpg")}
                    alt=""
                  />
                </div>
                <div className="nipo-benefit-text">
                  <p>{langText[isEn ? "eng" : "viet"].sub10.text}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                {langText[isEn ? "eng" : "viet"].sub11.text}
              </p>
              <Link to="/vietnam/contactus" className="maris-contact__button">
                {langText[isEn ? "eng" : "viet"].sub25.text}
              </Link>
            </div>
          </div>
          <div className="maris-list-container">
            <div className="nipo-list-left">
              <div className="nipo-list-img">
                <img
                  src={require("../../assets/images/nipo/rpruf.jpg")}
                  alt=""
                />
              </div>
              <div className="nipo-list-text">
                <div className="nipo-list-paragraph">
                  <p className="mb-15">
                    {langText[isEn ? "eng" : "viet"].sub18.text}
                  </p>
                  <p className="mb-15">
                    {langText[isEn ? "eng" : "viet"].sub19.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* nipo benefit start */}
          <div className="nipo-benefit-container">
            <div className="nipo-benefit">
              <div className="nipo-benefit-img">
                <img
                  src={require("../../assets/images/nipo/crack.jpg")}
                  alt=""
                />
              </div>
              <div className="nipo-benefit-text">
                <p>{langText[isEn ? "eng" : "viet"].sub20.text}</p>
              </div>
            </div>
            <div className="nipo-benefit">
              <div className="nipo-benefit-img">
                <img
                  src={require("../../assets/images/nipo/save-for-plant.jpg")}
                  alt=""
                />
              </div>
              <div className="nipo-benefit-text">
                <p>{langText[isEn ? "eng" : "viet"].sub21.text}</p>
              </div>
            </div>
            <div className="nipo-benefit">
              <div className="nipo-benefit-img">
                <img
                  src={require("../../assets/images/nipo/non-clumping.jpg")}
                  alt=""
                />
              </div>
              <div className="nipo-benefit-text">
                <p>{langText[isEn ? "eng" : "viet"].sub22.text}</p>
              </div>
            </div>
          </div>
          {/* nipo benefit end */}
          {/* content nipo end */}
          {/* Datasheet */}
          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                {langText[isEn ? "eng" : "viet"].sub23.text}
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                {langText[isEn ? "eng" : "viet"].sub24.text}
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    isEn: state.auth.isEn,
  };
};

export default connect(mapStateToprops)(NipoVietnam);
