import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
// import "./Footer.css";

class FooterVietnam extends Component {
  state = {
    langText: {
      eng: {
        sub9: {
          text: "Click here for inquiries"
        },
        sub10: {
          text: "(+84) 916282875"
        },
        sub11: {
          text: "Operating hours: 8:00 - 17:00 "
        },
        sub12: {
          text: "duong-vg@nohara-inc.co.jp"
        },
      },
      viet: {
        sub9: {
          text: "Bấm vào đây cho những giải đáp thắc mắc"
        },
        sub10: {
          text: "(+84) 916282875"
        },
        sub11: {
          text: "Giờ mở cửa: 8:00 - 17:00 "
        },
        sub12: {
          text: "duong-vg@nohara-inc.co.jp"
        },
      }
    }
  }

  render() {
    const { isEn } = this.props
    const { langText } = this.state
    return (
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-top-inquiry">
            <Link to="/vietnam/contactus" className="footer-btn">
            { langText[`${isEn ? 'eng' : 'viet'}`].sub9.text }
            </Link>
          </div>
          <div className="footer-contact">
            <div className="footer-phone">{ langText[`${isEn ? 'eng' : 'viet'}`].sub10.text }</div>
            <div className="footer-operation">
              <p>{ langText[`${isEn ? 'eng' : 'viet'}`].sub11.text }</p>
              <div style={{borderBottom: '1px dashed #fff', paddingTop: '20px'}}></div>
            </div>
            <a className="footer-email" href={`mailto:${langText[`${isEn ? 'eng' : 'viet'}`].sub12.text }`}>{ langText[`${isEn ? 'eng' : 'viet'}`].sub12.text }</a>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-company">
            <div>
              <div className="footer-logo">
                <img src={require('../../assets/images/logo/nohara-vietnam-bottom.png')} alt=""/>
              </div>
              {/* <div className="footer-logo">
                <img src={require('../../assets/images/logo_international.png')} alt=""/>
              </div> */}
            </div>
            <div className="footer-navigation mb-15">
              <Link className="navigation-text" to="/vietnam">HOME</Link>
            </div>
            <div className="footer-navigation mb-15">
              <div className="mb-30"><Link className="navigation-text" to="/vietnam/product">PRODUCT</Link></div>
              <div className="mb-15"><Link className="navigation-text" to="/vietnam/brai">BRAI</Link></div>
              <div className="mb-15"><Link className="navigation-text" to="/vietnam/nipo">NIPO</Link></div>
              <div className="mb-15"><Link className="navigation-text" to="/vietnam/laticrete">LATICRETE</Link></div>

            </div>
            <div className="footer-navigation">
              <Link className="navigation-text" to="/vietnam/aboutus">ABOUT US</Link>
            </div>
            <div className="footer-navigation">
              <Link className="navigation-text" to="/news">NEWS</Link>
            </div>
          </div>
          <div className="footer-sosial">
            <div className="mb-15">Sosial media</div>
            <div className="mb-15">
              <a href="https://www.instagram.com/noharainternational/" target="_blank" rel="noopener noreferrer" className="navigation-text">
                <div className="sosial-logo">
                  <img src={require('../../assets/images/ig-icon.png')} className="img-sosial" alt=""/>
                  <p style={{marginLeft: '10px'}}>instagram</p>
                </div>
              </a>
            </div>
            <div className="mb-15">
              <a href="https://www.facebook.com/noharainternational" target="_blank" rel="noopener noreferrer" className="navigation-text">
                <div className="sosial-logo">
                  <img src={require('../../assets/images/fb-icon.png')} className="img-sosial" alt=""/>
                  <p style={{marginLeft: '10px'}}>facebook</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-end">
          &copy; Copyright Nohara International 2020
        </div>
      </div>
    );
  }
}

const mapStateToprops = state => {
  return {
    isEn: state.auth.isEn
  }
}
export default connect(mapStateToprops)(FooterVietnam);
