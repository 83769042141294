import React, { Component } from "react";
// import { Link } from "react-router-dom";

import axios from "axios";
import api from "../../configs/api";
import { connect } from "react-redux";

import Navbar from "../NavBar/NavbarVietnam";
import Footer from "../Footer/FooterVietnam";

class ContactUsVietnam extends Component {
  state = {
    name: null,
    phone: null,
    affiliation: null,
    email: null,
    message: null,
    langChoise: "VIET",
    langText: {
      eng: {
        sub1: {
          text: "Let us hear from you",
        },
        sub2: {
          text: "Inquiry form",
        },
        sub3: {
          text: "Please fill in the required items from the form below and send.",
        },
        sub4: {
          text: "A representative will contact you within 3 business days.",
        },
        sub5: {
          text: "For immediate assistance +84 916282875",
        },
        sub6: {
          text: "Name",
        },
        sub7: {
          text: "Company / Affiliation",
        },
        sub8: {
          text: "Contact Number",
        },
        sub9: {
          text: "Email Address",
        },
        sub10: {
          text: "Message",
        },
        sub11: {
          text: "CONFIRM",
        },
      },
      viet: {
        sub1: {
          text: "Hãy để chúng tôi lắng nghe bạn",
        },
        sub2: {
          text: "Mẫu yêu cầu",
        },
        sub3: {
          text: "Vui lòng điền vào các mục cần thiết từ biểu mẫu bên dưới và gửi.",
        },
        sub4: {
          text: "Đại diện của chúng tôi sẽ liên hệ với bạn trong vòng 3 ngày làm việc",
        },
        sub5: {
          text: "",
        },
        sub6: {
          text: "Tên",
        },
        sub7: {
          text: "Công ty/Chi nhánh",
        },
        sub8: {
          text: "Số liên hệ",
        },
        sub9: {
          text: "Địa chỉ emai",
        },
        sub10: {
          text: "Tin nhắn",
        },
        sub11: {
          text: "Xác nhận",
        },
      },
    },
  };

  changeHanlder = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  pushToServer = async (value) => {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${api}/api/v1/nohara/messages/add`,
        data: value,
      });

      if (data && data.status === "success") {
        alert(data.message);
        this.props.history.push("/");
      }
    } catch (error) {
      alert(error);
    }
  };

  onSubmitHandler = () => {
    const { name, phone, affiliation, email, message } = this.state;

    if (!name || !phone || !email) {
      alert("Name, contact number, email cannot empty");
    } else {
      console.log(this.state);
      const data = {
        name,
        phone,
        affiliation,
        email,
        message,
      };

      this.pushToServer(data);
    }
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { isEn } = this.props;
    const { langText } = this.state;
    return (
      <>
        <Navbar />
        <div className={isEn ? "home-container" : "home-container viet-theme"}>
          {/* Top Image  */}
          <div className="contact-top-image">
            <img src={require("../../assets/images/contact_us.jpg")} alt="" />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3 className="text-title">CONTACT US</h3>
                </div>
                <div className="text-subtitle">
                  {langText[isEn ? "eng" : "viet"].sub1.text}
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <div className="devider">&nbsp;</div>
          {/* Content */}
          <div className="center-container">
            <div className="contact-title">
              <h4>{langText[isEn ? "eng" : "viet"].sub2.text}</h4>
            </div>
            <div className="contact-text">
              {langText[isEn ? "eng" : "viet"].sub3.text}
            </div>
            <div className="contact-text">
              {langText[isEn ? "eng" : "viet"].sub4.text}
            </div>
            <div className="contact-text-phone">
              {langText[isEn ? "eng" : "viet"].sub5.text}
            </div>
            <div className="contact-input-container">
              <div className="input-container">
                <label className="input-label">
                  {langText[isEn ? "eng" : "viet"].sub6.text}
                </label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="input-box"
                  onChange={this.changeHanlder}
                />
              </div>
              <div className="input-container">
                <label className="input-label">
                  {langText[isEn ? "eng" : "viet"].sub7.text}
                </label>
                <input
                  name="affiliation"
                  id="affiliation"
                  type="text"
                  className="input-box"
                  onChange={this.changeHanlder}
                />
              </div>
              <div className="input-container">
                <label className="input-label">
                  {langText[isEn ? "eng" : "viet"].sub8.text}
                </label>
                <input
                  name="phone"
                  id="phone"
                  type="text"
                  className="input-box"
                  onChange={this.changeHanlder}
                />
              </div>
              <div className="input-container">
                <label className="input-label">
                  {langText[isEn ? "eng" : "viet"].sub9.text}
                </label>
                <input
                  name="email"
                  id="email"
                  type="text"
                  className="input-box"
                  onChange={this.changeHanlder}
                />
              </div>
              <div className="input-container-textarea">
                <label className="input-label-textarea">
                  {langText[isEn ? "eng" : "viet"].sub10.text}
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows="9"
                  type="text"
                  className="input-box-textarea"
                  onChange={this.changeHanlder}
                />
              </div>
              <div className="contact-btn" onClick={this.onSubmitHandler}>
                {langText[isEn ? "eng" : "viet"].sub11.text}
              </div>
            </div>
            {/* Devider */}
            <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    isEn: state.auth.isEn,
  };
};

export default connect(mapStateToprops)(ContactUsVietnam);
