import React, { Component } from "react";
// import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="news-footer-container">
        <div className="news-footer-end">
          &copy; Copyright Nohara International 2020
        </div>
      </div>
    );
  }
}

export default Footer;
