import React, { Component } from "react";
import Footer from "../Footer/Footer";
import DownloadModal from "../Utils/Modal";

import Navbar from "../NavBar/NavBar";
import DeviderComponent from "../Utils/Devider";
import ContactUsComponent from "../Utils/ContactUs";
import BenefitBoxComponent from "../Utils/BenefitBox";
import LeftImageComponent from "../Utils/LeftImage";
import RightImageComponent from "../Utils/RightImage";
import BoxTitleComponent from "../Utils/BoxTitle";
import BoxTitleBgComponent from "../Utils/BoxTitleBG";
import DoubleBoxComponent from "../Utils/DoubleBox";
import NipoTopComponent from "../Utils/NipoTop";

class Laticrete extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="TDS_LATICRETE_GUARD_EA.pdf"
        />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/laticrete.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>LATICRETE</h3>
                </div>
                <div className="text-subtitle">
                  Faster Maintenance Epoxy Acrylic Flooring Solution
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <DeviderComponent height={"60px"} />
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="maris-content">
              <div className="maris-content-title">
                <h2>NOHARA X LATICRETE</h2>
              </div>
              <div className="maris-content-p">
                At Nohara we are selective in our products range and we will
                prequalify each brand to ensure we bring good quality products
                to our customer.
              </div>
              <div className="maris-content-p">
                LATICRETE is a customer focused, world-wide manufacturer
                marketer of green flooring and facade materials, used in
                residential, commercial and industrial applications. For over 60
                years, LATICRETE has provided the technology, products and
                service making innovative, permanent installations and repairs
                for the construction market. Architecturally specified and
                professionally endorsed, LATICRETE offers a dedication to
                quality that results in globally proven solutions for virtually
                every flooring, waterproofing and facade application.
              </div>
            </div>
          </div>
          {/* Content */}
          <ContactUsComponent />
          <div className="maris-list-container">
            <BoxTitleBgComponent
              title={"Why do you need Fast Return To Service product?"}
            />
            <div className="text-center">
              Fast return to service is the advantage of Laticrete Guard UA
              which is intended for factory or warehouse floor repairs in a
              short period of time. With only 1 x 24 hour repair time, Laticrete
              Guard EA allows the floor area to be used productively again. With
              this minimal time, warehouse or factory owners will not lose too
              much production time.
            </div>
            <DeviderComponent />
            <DoubleBoxComponent
              benefit={[
                {
                  image: "icons/37.png",
                  desc: "Plan your maintenance time for only 12 hours",
                },
                {
                  image: "icons/38.png",
                  desc: "Decrease downtime period during maintenance",
                },
                {
                  image: "icons/39.png",
                  desc: "Reduce lost revenue/hour caused by maintenance time",
                },
                {
                  image: "icons/40.png",
                  desc: "Faster return to productive period",
                },
              ]}
            />
          </div>

          <ContactUsComponent />
          <div className="maris-list-container">
            <BoxTitleComponent title={"LATICRETE GUARD EA"} />
            <h1 className="text-center">PRODUCT FEATURE</h1>
            <DeviderComponent />
            <NipoTopComponent
              orientation={true}
              image={"products/kbf/5.png"}
              url1={"products/icons/19.png"}
              url2={"products/icons/20.png"}
              subtitle={`By using Laticrete guard EA, you can maintain the floor faster. The fast return 
                to service system is an advantage of Laticrete Guard EA which helps you shorten the 
                maintenance period to 12 hours. So you can be active again in just 1 x 12 hours.`}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/kbf/7.png"}
              url1={"products/icons/21.png"}
              url2={"products/icons/22.png"}
              subtitle={`Another advantage of Laticrete Guard EA is the nature of the material that 
                is UV resistant so that it can be used in outdoor areas. This allows the use of not 
                only the factory area, but even outside areas, such as parking areas, roof decks 
                and so on.`}
            />

            <DeviderComponent />
            <NipoTopComponent
              orientation={true}
              image={"products/kbf/6.png"}
              url1={"products/icons/23.png"}
              url2={"products/icons/24.png"}
              subtitle={`Safe and non-toxic basic components are one of the important parameters 
                in material selection. Laticrete Guard EA has been legally proven to be a safe 
                and non-toxic material. This is evidenced by the Green Label certificate from Singapore.`}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/kbf/8.png"}
              url1={"products/icons/25.png"}
              url2={"products/icons/26.png"}
              subtitle={`Laticrete Guard EA has the characteristics of being resistant to chemicals. 
                So it is appropriate for use in chemical factories though. The characteristics of 
                this material ensure the quality of your floor is safe from chemical reactions that 
                may occur. Make your floor more durable and long lasting`}
            />
          </div>

          <ContactUsComponent />
          <BenefitBoxComponent
            product={"Laticrete"}
            benefit={[
              {
                title: "Innovative",
                desc: "Our innovations transform our industry with new solutions and opportunities for architects, designers, and installers",
              },
              {
                title: "Committed to Sustainability",
                desc: "We are committed to improving the sustainability of our industry and health and safety of our customers and employees",
              },
              {
                title: "Enabling Iconic Design",
                desc: "We are the go-to brand for the world’s most demanding and iconic projects everywhere people live, work and are together.",
              },
              {
                title: "Trusted for Life",
                desc: "One can trust our products to last for a lifetime, same as one can trust our lifelong support.",
              },
            ]}
          />
          <ContactUsComponent />

          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                Learn more of Laticrete product properties
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                Technical Data Sheet
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Laticrete;
