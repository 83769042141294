import React, { Component } from "react";
import Footer from "../Footer/Footer";

import axios from "axios";
import api from "../../configs/api";

import Navbar from "../NavBar/NavBar";

class AboutUs extends Component {
  state = {
    messages: null,
  };

  getMessage = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/messages/showall`,
      });

      if (data && data.status === "success") {
        console.log("message: ", data.message);
        this.setState(
          {
            messages: data.message,
          },
          () => console.log("message state: ", this.state)
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  pretierDate = (value) => {
    let date = new Date(value);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (String(day).length < 2) day = "0" + day;
    if (month < 10) month = "0" + month;

    return `${day}/${month}/${year}`;
  };

  componentDidMount() {
    this.getMessage();
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { messages } = this.state;
    return (
      <>
        <Navbar />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img src={require("../../assets/images/company_r01.jpg")} alt="" />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>Customer Message</h3>
                </div>
                {/* <div className="text-subtitle">Company Profile</div> */}
              </div>
            </div>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Content */}
          <div className="about-table-container">
            <table className="about-table">
              <tbody>
                {messages &&
                  messages.map((list, index) => (
                    <tr className="tr-message" key={index}>
                      <td className="td-email">
                        <a href={`mailto:${list.email}`}>
                          {index + 1}. {list.email}
                        </a>
                      </td>
                      <td className="td-detail">
                        <div>
                          Name: <strong>{list.name}</strong>
                        </div>
                        <div>Company / Affiliation: {list.affiliation}</div>
                        <div>Contact number: {list.phone} </div>
                        <div>Message: {list.message}</div>
                        <div>Date: {this.pretierDate(list.createdAt)}</div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default AboutUs;
