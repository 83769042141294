import React from "react";

const ContactUsComponent = () => {
  return (
    <div class="maris-contact-container">
      <div class="maris-contact">
        <p class="maris-contact__text">
          We're happy to answer your inquiries! Contact us for more product
          information. +62 811-1575-893 or indo_sales@nohara-inc.co.jp
        </p>
        <a class="maris-contact__button" href="/indonesia/contactus">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default ContactUsComponent;
