import React from "react";
import { Link } from "react-router-dom";

const ProductDescriptionComponent = ({
  title,
  image,
  description,
  product,
}) => {
  return (
    <div className="products-list-container">
      <p className="text-center text-bold mb10">{title}</p>
      <div className="products-list-img">
        {image ? (
          <img src={require(`../../assets/images/${image}`)} alt="" />
        ) : (
          <div className="border">Product Image</div>
        )}
      </div>

      <div className="product-content-wrapper">
        <p className="product-content-desc">{description}</p>
        {product?.length > 0 && (
          <p className="product-content-desc">Product:</p>
        )}
      </div>

      {product?.length > 0 &&
        product.map((item, index) => (
          <div key={index} className="product-content-wrapper">
            <p className="product-content-desc">{item.list}</p>
            <a href={item.link} className="product-content-desc link-product">
              Learn More
            </a>
          </div>
        ))}
    </div>
  );
};

export default ProductDescriptionComponent;
