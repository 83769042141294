import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut, isVietLang } from "../../redux/actions/authAction";

class NavbarVietnam extends Component {
  state = {
    langText: {
      eng: {
        sub10: {
          text: "(+84) 916282875",
        },
        sub11: {
          text: "Operating hours: 8:00 - 17:00 ",
        },
        sub12: {
          text: "duong-vg@nohara-inc.co.jp",
        },
      },
      viet: {
        sub10: {
          text: "(+84) 916282875",
        },
        sub11: {
          text: "Giờ mở cửa: 8:00 - 17:00 ",
        },
        sub12: {
          text: "duong-vg@nohara-inc.co.jp",
        },
      },
    },
  };
  btnChoiseLang = () => {
    this.props.isVietLang();
  };
  onSignOutHandler = () => {
    console.log("logout");
    this.props.signOut();
  };
  render() {
    const { authenticated, isEn } = this.props;
    const { langText } = this.state;
    return (
      <div
        className={isEn ? "navbar-container" : "navbar-container viet-theme"}
      >
        <div className="padding-page navbar-lang">
          <div className="navbar-subcontainer">&nbsp;</div>
          <a
            onClick={() => this.btnChoiseLang()}
            className="navbar-subcontainer"
          >
            <span>Select Language: </span>
            <span style={{ color: `${isEn ? "#fff" : "black"}` }}>
              English &nbsp;
            </span>{" "}
            /{" "}
            <span style={{ color: `${isEn ? "black" : "#fff"}` }}>
              &nbsp;Vietnam
            </span>
          </a>
        </div>
        <div className="padding-page navbar-country">
          <Link to="/" className="country-welcome">
            Welcome to Nohara International Network!
          </Link>
          <a
            className="country-name"
            href="http://www.nohara.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            INDIA
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com/indonesia"
            target="_blank"
            rel="noopener noreferrer"
          >
            INDONESIA
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com/vietnam"
            target="_blank"
            rel="noopener noreferrer"
          >
            VIETNAM
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            SINGAPORE
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            CHINA
          </a>
          <a
            className="country-name"
            href="https://nohara-inc.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            JAPAN
          </a>

          {/* <a className="navbar-subcontainer navbar-subcontainer__country">
            <img src={require('../../assets/images/nohara_international_logo.png')} alt=""/>
          </a>
          <a href="https://nohara-inc.co.jp/en/" className="navbar-subcontainer navbar-subcontainer__country">
            <img src={require('../../assets/images/nohara_holding_logo.png')} alt=""/>
          </a> */}
        </div>
        <div id="noharaHeader" className="navbar-head padding-page">
          <div className="navbar-head__logo">
            <div className="head-icon">
              <img
                src={require("../../assets/images/logo/nohara-vietnam-side.png")}
                alt=""
              />
            </div>
            {/* <div className="head-logo">
              <img src={require('../../assets/images/logo_international.png')} alt=""/>
            </div> */}
          </div>
          <div className="navbar-mobile">
            <div className="dropdown">
              <div className="navigation-text">
                <i className="fa fa-bars" style={{ fontSize: "20px" }}></i>
              </div>
              <div className="dropdown-menu">
                <Link className="navigation-text" to="/vietnam">
                  HOME
                </Link>
                <Link className="navigation-text" to="/vietnam/product">
                  PRODUCT
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/vietnam/brai"
                >
                  BRAI
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/vietnam/nipo"
                >
                  NIPO
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/vietnam/laticrete"
                >
                  LATICRETE
                </Link>
                <Link className="navigation-text" to="/vietnam/aboutus">
                  ABOUT US
                </Link>
                <Link className="navigation-text" to="/news">
                  NEWS
                </Link>
                {authenticated ? (
                  <div>
                    <Link className="navigation-text" to="/addnews">
                      ADD NEWS
                    </Link>
                    <Link className="navigation-text" to="/message">
                      MESSAGE
                    </Link>
                  </div>
                ) : null}
                <Link className="navigation-text" to="/vietnam/contactus">
                  CONTACT US
                </Link>
                {authenticated ? (
                  <a className="logout" onClick={this.onSignOutHandler}>
                    LOGOUT
                  </a>
                ) : (
                  <Link to="/login" className="navigation-text">
                    <i className="fa fa-user-o"></i> LOGIN
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="navbar-head__navigation">
            <div>
              <Link className="navigation-text" to="/vietnam">
                HOME
              </Link>
            </div>
            <div className="dropdown">
              <Link className="navigation-text" to="/vietnam/product">
                PRODUCT
              </Link>
              <div className="dropdown-content">
                <Link className="navigation-text" to="/vietnam/brai">
                  BRAI
                </Link>
                <Link className="navigation-text" to="/vietnam/nipo">
                  NIPO
                </Link>
                <Link className="navigation-text" to="/vietnam/laticrete">
                  LATICRETE
                </Link>
              </div>
            </div>
            <div>
              <Link className="navigation-text" to="/vietnam/aboutus">
                ABOUT US
              </Link>
            </div>
            <div>
              <Link
                className="navigation-text"
                to="/news"
                target="_blank"
                rel="noopener noreferrer"
              >
                NEWS
              </Link>
            </div>
            {authenticated ? (
              <div className="dropdown">
                <div className="navigation-text">ADMIN</div>
                <div className="dropdown-content">
                  <Link className="navigation-text" to="/addnews">
                    ADD NEWS
                  </Link>
                  <Link className="navigation-text" to="/message">
                    MESSAGE
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
          <div className="navbar-head__contact">
            <div>{langText[`${isEn ? "eng" : "viet"}`].sub10.text}</div>
            <div className="operation-contact">
              {langText[`${isEn ? "eng" : "viet"}`].sub11.text}
            </div>
          </div>
          <div className="navbar-head__right">
            <div>
              <Link
                to="/vietnam/contactus"
                className="navigation-text nav-right-text"
              >
                <i className="fa fa-envelope-o"></i> CONTACT US
              </Link>
            </div>
            <div className="navigation-text">
              {authenticated ? (
                <div
                  className="navigation-text nav-right-text"
                  onClick={this.onSignOutHandler}
                >
                  LOGOUT
                </div>
              ) : (
                <Link to="/login" className="navigation-text nav-right-text">
                  <i className="fa fa-user-o"></i> LOGIN
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    authenticated: state.auth.authenticated,
    isEn: state.auth.isEn,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    isVietLang: () => dispatch(isVietLang()),
  };
};

export default connect(mapStateToprops, mapDispatchToprops)(NavbarVietnam);
