import axios from "axios";
import api from "../../configs/api";

export const getNews = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/showall`,
      });
      console.log("get news: ", data);
      dispatch({ type: "GET_NEWS_SUCCESS", payload: data.message });
    } catch (error) {
      dispatch({ type: "GET_NEWS_ERROR", error });
      console.log(error.response);
    }
  };
};

export const getYears = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/years`,
      });
      console.log("get years: ", data);
      dispatch({ type: "GET_YEARS_SUCCESS", payload: data.message });
    } catch (error) {
      dispatch({ type: "GET_YEARS_ERROR", error });
      console.log(error.response);
    }
  };
};

export const setNews = (data) => {
  console.log("news store", data);
  return (dispatch) => {
    dispatch({
      type: "SET_NEWS",
      payload: data,
    });
  };
};
