import React, { Component } from "react";

class Brai extends Component {
  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="home-container">
          <div className="coming-soon" style={{ alignItems: "center" }}>
            <div>
              <div className="coming-subtitle">Page Construction</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Brai;
