import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { getYears } from "../../redux/actions/newsAction";
import moment from "moment";

class NewesRightSide extends Component {
  getImage(content) {
    if (content) {
      let _img = content.split('src="');
      if (_img.length > 0 && _img[1]) {
        _img = _img[1].split('"');

        return _img[0];
      }
      return "";
    }
  }

  componentDidMount() {
    this.props.getYears();
  }

  render() {
    const { years, tags } = this.props;
    return (
      <div className="news-list-right-box">
        <div style={{ marginBottom: "30px" }}>
          <div className="right-box-title">Archive</div>
          {years?.length > 0 &&
            years.map((list, index) => (
              <div className="right-box-list" key={index}>
                <span style={{ color: "#9DCB62" }}>{">"} &nbsp;</span>
                {`${moment().month(list.bulan).format("MMMM")} ${
                  list.tahun
                } ( ${list.jumlah_artikel} )`}
              </div>
            ))}
        </div>
        <div>
          <div className="right-box-title">Category</div>
          <div style={{ backgroundColor: "#FAFAFA", padding: "10px 0" }}>
            {tags?.length > 0 &&
              tags.map((list, index) => (
                <div style={{ padding: "2px 10px" }} key={index}>
                  <div className="right-box-tag">
                    {`${list.tag == "" ? "Non Category" : list.tag} ( ${
                      list.total_articles
                    } )`}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    years: state.news.years,
    tags: state.news.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getYears: () => dispatch(getYears()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewesRightSide);
