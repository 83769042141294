import React, { Component } from "react";
import Footer from '../Footer/Footer'
// import { Link } from "react-router-dom";

import axios from 'axios'
import api from '../../configs/api'

import Navbar from '../NavBar/NavBar'

class ContactUs extends Component {
  state = {
    name: null,
    phone: null,
    affiliation: null,
    email: null,
    message: null
  }

  changeHanlder = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  pushToServer = async (value) => {
    try {
      const { data } = await axios({
        method: 'POST',
        url: `${api}/api/v1/nohara/messages/add`,
        data : value
      })

      if(data && data.status === 'success') {
        alert(data.message)
        this.props.history.push('/')
      }
    } catch (error) {
      alert(error)
    }
  }

  onSubmitHandler = () => {
    const {name, phone, affiliation, email, message} = this.state

    if(!name || !phone || !email) {
      alert('Name, contact number, email cannot empty')
    } else {
      console.log(this.state)
      const data = { 
        name,
        phone,
        affiliation,
        email,
        message
      }

      this.pushToServer(data)
    }
  }

  componentDidMount() {
    console.log('mounted')
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if(header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    return (
      <>
        <Navbar />
        <div className="home-container">
          {/* Top Image  */}
            <div className="contact-top-image">
              <img src={require("../../assets/images/contact_us.jpg")} alt=""/>
              <div className="contact-top-card">
                <div className="contact-top-card__top">
                  <div><h3 className='text-title'>CONTACT US</h3></div>
                  <div className="text-subtitle">Let us hear from you</div>
                </div>
              </div>
            </div>
          {/* Devider */}
          <div className="devider">
            &nbsp;
          </div>
          {/* Content */}
          <div className="center-container">
            <div className="contact-title">
              <h4>Inquiry form</h4>
            </div>
            <div className="contact-text">
              Please fill in the required items from the form below and send.
            </div>
            <div className="contact-text">
              A representative will contact you within 3 business days.
            </div>
            <div className="contact-text-phone">
              For immediate assistance +62 811-1575-893
            </div>
            <div className="contact-input-container">
              <div className="input-container">
                <label className="input-label">Name</label>
                <input name="name" id="name" type="text" className="input-box" onChange={this.changeHanlder}/>
              </div>
              <div className="input-container">
                <label className="input-label">Company / Affiliation</label>
                <input name="affiliation" id="affiliation" type="text" className="input-box" onChange={this.changeHanlder}/>
              </div>
              <div className="input-container">
                <label className="input-label">Contact Number</label>
                <input name="phone" id="phone" type="text" className="input-box" onChange={this.changeHanlder}/>
              </div>
              <div className="input-container">
                <label className="input-label">Email Address</label>
                <input name="email" id="email" type="text" className="input-box" onChange={this.changeHanlder}/>
              </div>
              <div className="input-container-textarea">
                <label className="input-label-textarea">Message</label>
                <textarea name="message" id="message" rows="9" type="text" className="input-box-textarea" onChange={this.changeHanlder}/>
              </div>
              <div className="contact-btn" onClick={this.onSubmitHandler}>
                CONFIRM
              </div>
            </div>
            {/* Devider */}
            <div style={{ width: '100vw', height: '40px'}}>
              &nbsp;
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default ContactUs;
