import React, { Component } from "react";

import { connect } from 'react-redux'

import Navbar from '../NavBar/NavbarVietnam'
import Footer from '../Footer/FooterVietnam'

class AboutUsVietnam extends Component {
  state = {
    langChoise: 'VIET',
    langText: {
      eng: {
        sub1: {
          text: "Name of company"
        },
        sub2: {
          text: "VPĐD NOHARA INTERNATIONAL PTE. LTD. TẠI TP.HCM"
        },
        sub3: {
          text: "Address"
        },
        sub4: {
          text: "Viettel Complex Building, Floor 12 285 Cach Mang Thang Tam Street, Ward 12, District 10, HCMC"
        },
        sub5: {
          text: "Phone"
        },
        sub6: {
          text: "+84 916282875"
        },
        sub7: {
          text: "Company description"
        },
        sub8: {
          text: "'Sales & Distribution of Construction Materials in Indonesia. We supply functional building materials and high-quality construction technology which can elevate the environment of Indonesian living life."
        },
        sub9: {
          text: "About Nohara Japan"
        },
        sub10: {
          text: "'Nohara Holdings, Inc.'"
        },
        sub11: {
          text: "'Using the strength of the technical capacity the group have cultivated in the 420 years since our establishment in 1598, Nohara Holdings focus on core businesses of building materials, construction, and urban spatial design and construction while devoting energy to creating unique new businesses."
        },
      },
      viet: {
        sub1: {
          text: "Tên Công Ty"
        },
        sub2: {
          text: "VPĐD NOHARA INTERNATIONAL PTE. LTD. TẠI TP.HCM"
        },
        sub3: {
          text: "Địa chỉ"
        },
        sub4: {
          text: "Tòa nhà Viettel Complex, Tầng 12, 285 đường Cách Mạng Tháng Tám, Phường 12, Quận 10, TP Hồ Chí Minh, Việt Nam"
        },
        sub5: {
          text: "Điện thoại"
        },
        sub6: {
          text: "(+84) 916282875"
        },
        sub7: {
          text: "Mô tả về công ty"
        },
        sub8: {
          text: "Kinh doanh và phân phối vật liệu xây dựng ở Việt Nam. Chúng tôi cung cấp các loại vật liệu xây dựng chất lượng cao với sứ mệnh nâng cao chất lượng môi trường sống tại Việt Nam "
        },
        sub9: {
          text: "Giới thiệu về Nohara Nhật Bản"
        },
        sub10: {
          text: "Tập đoàn Nohara"
        },
        sub11: {
          text: "Sử dụng thế mạnh về năng lực kỹ thuật  mà tập đoàn đã tích lũy hơn 420 năm kể từ khi thành lập vào năm 1598, tập đoàn Nohara tập trung vào các lĩnh vực kinh doanh cốt lõi là vật liệu xây dựng, thiết kế và xây dựng không gian đô thị, đồng thời liên tục kiến tạo những ngành kinh doanh mới"
        },
      }
    }
  }

  componentDidMount() {
    console.log('mounted')
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if(header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { isEn } = this.props
    const { langText } = this.state
    return (
      <>
        <Navbar />
        <div className={isEn ? "home-container" : "home-container viet-theme"}>
          {/* Top Image  */}
            <div className="contact-top-image">
              <img src={require("../../assets/images/company_r01.jpg")} alt=""/>
              <div className="contact-top-card">
                <div className="contact-top-card__top">
                  <div><h3>ABOUT US</h3></div>
                  <div className="text-subtitle">Company Profile</div>
                </div>
              </div>
            </div>
          {/* Devider */}
          <div style={{ width: '100vw', height: '40px'}}>
            &nbsp;
          </div>
          {/* Content */}
          <div className="about-table-container">
            <table className="about-table">
              <tbody>
                <tr className="tr-phone"> 
                  <td className="td-title">{ langText[isEn ? 'eng' : 'viet'].sub1.text }</td>
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub2.text }</td>
                </tr>
                <tr className="tr-phone">
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub3.text }</td>
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub4.text }</td>
                </tr>
                <tr className="tr-phone">
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub5.text }</td>
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub6.text }</td>
                </tr>
                <tr className="tr-phone">
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub7.text }</td>
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub8.text }</td>
                </tr>
                <tr className="tr-phone">
                  <td>{ langText[isEn ? 'eng' : 'viet'].sub9.text }</td>
                  <td>
                  { langText[isEn ? 'eng' : 'viet'].sub10.text } <br />
                  { langText[isEn ? 'eng' : 'viet'].sub11.text }
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <iframe title="Nohara Location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15677.732839410304!2d106.6798846!3d10.7780948!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x97169d1fc37df0b6!2sViettel%20Tower!5e0!3m2!1sid!2sid!4v1612327433742!5m2!1sid!2sid" width="100%" height="450" style={{border:0}} aria-hidden="false"></iframe>
          </div>
          {/* Devider */}
          <div style={{ width: '100vw', height: '40px'}}>
            &nbsp;
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = state => {
  return {
    isEn: state.auth.isEn
  }
}

export default connect(mapStateToprops)(AboutUsVietnam);
