import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../redux/actions/authAction";

class NavBar extends Component {
  onSignOutHandler = () => {
    console.log("logout");
    this.props.signOut();
  };
  render() {
    const { authenticated } = this.props;
    return (
      <div className="navbar-container">
        {/* <div className="padding-page navbar-lang">
          <div className="navbar-subcontainer">&nbsp;</div>
          <div className="navbar-subcontainer">EN / BAHASA</div>
        </div> */}
        <div className="padding-page navbar-country">
          <Link to="/" className="country-welcome">
            Welcome to Nohara International Network!
          </Link>
          <a
            className="country-name"
            href="http://www.nohara.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            INDIA
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com/indonesia"
            target="_blank"
            rel="noopener noreferrer"
          >
            INDONESIA
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            VIETNAM
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            SINGAPORE
          </a>
          <a
            className="country-name"
            href="https://nohara-international.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            CHINA
          </a>
          <a
            className="country-name"
            href="https://nohara-inc.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            JAPAN
          </a>

          {/* <a className="navbar-subcontainer navbar-subcontainer__country">
            <img src={require('../../assets/images/nohara_international_logo.png')} alt=""/>
          </a>
          <a href="https://nohara-inc.co.jp/en/" className="navbar-subcontainer navbar-subcontainer__country">
            <img src={require('../../assets/images/nohara_holding_logo.png')} alt=""/>
          </a> */}
        </div>
        <div id="noharaHeader" className="navbar-head padding-page">
          <div className="navbar-head__logo">
            <div className="head-icon">
              <img
                src={require("../../assets/images/logo-kesamping.png")}
                alt=""
              />
            </div>
            {/* <div className="head-logo">
              <img src={require('../../assets/images/logo_international.png')} alt=""/>
            </div> */}
          </div>
          <div className="navbar-mobile">
            <div className="dropdown">
              <div className="navigation-text">
                <i className="fa fa-bars" style={{ fontSize: "20px" }}></i>
              </div>
              <div className="dropdown-menu">
                <Link className="navigation-text" to="/indonesia">
                  HOME
                </Link>
                <Link className="navigation-text" to="/indonesia/product">
                  PRODUCT
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/indonesia/nipo"
                >
                  NIPO
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/indonesia/flortech"
                >
                  FLORTECH
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/indonesia/laticrete"
                >
                  LATICRETE
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/indonesia/insutech"
                >
                  INSUTECH
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/indonesia/brai"
                >
                  BRAI
                </Link>
                <Link
                  className="navigation-text"
                  style={{ paddingLeft: "35px" }}
                  to="/indonesia/maris"
                >
                  MARIS
                </Link>
                <Link className="navigation-text" to="/indonesia/aboutus">
                  ABOUT US
                </Link>
                <Link className="navigation-text" to="/news">
                  NEWS
                </Link>
                {authenticated ? (
                  <div>
                    <Link className="navigation-text" to="/addnews">
                      ADD NEWS
                    </Link>
                    <Link className="navigation-text" to="/message">
                      MESSAGE
                    </Link>
                  </div>
                ) : null}
                <Link className="navigation-text" to="/indonesia/contactus">
                  CONTACT US
                </Link>
                {authenticated ? (
                  <a className="logout" onClick={this.onSignOutHandler}>
                    LOGOUT
                  </a>
                ) : (
                  <Link to="/login" className="navigation-text">
                    <i className="fa fa-user-o"></i> LOGIN
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="navbar-head__navigation">
            <div>
              <Link className="navigation-text" to="/indonesia">
                HOME
              </Link>
            </div>
            <div className="dropdown">
              <Link className="navigation-text" to="/indonesia/product">
                PRODUCT
              </Link>
              <div className="dropdown-content">
                <Link className="navigation-text" to="/indonesia/nipo">
                  NIPO
                </Link>
                <Link className="navigation-text" to="/indonesia/flortech">
                  FLORTECH
                </Link>
                <Link className="navigation-text" to="/indonesia/laticrete">
                  LATICRETE
                </Link>
                <Link className="navigation-text" to="/indonesia/insutech">
                  INSUTECH
                </Link>
                <Link className="navigation-text" to="/indonesia/brai">
                  BRAI
                </Link>
                <Link className="navigation-text" to="/indonesia/maris">
                  MARIS
                </Link>
              </div>
            </div>
            <div>
              <Link className="navigation-text" to="/indonesia/aboutus">
                ABOUT US
              </Link>
            </div>
            <div>
              <Link className="navigation-text" to="/news" target="_blank">
                NEWS
              </Link>
            </div>
            {authenticated ? (
              <div className="dropdown">
                <div className="navigation-text">ADMIN</div>
                <div className="dropdown-content">
                  <Link className="navigation-text" to="/addnews">
                    ADD NEWS
                  </Link>
                  <Link className="navigation-text" to="/message">
                    MESSAGE
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
          <div className="navbar-head__contact">
            <div>+62 811-1575-893</div>
            <div className="operation-contact">
              Operating hours: 8:30 - 17:30
            </div>
          </div>
          <div className="navbar-head__right">
            <div>
              <Link
                to="/indonesia/contactus"
                className="navigation-text nav-right-text"
              >
                <i className="fa fa-envelope-o"></i> CONTACT US
              </Link>
            </div>
            <div className="navigation-text">
              {authenticated ? (
                <div
                  className="navigation-text nav-right-text"
                  onClick={this.onSignOutHandler}
                >
                  LOGOUT
                </div>
              ) : (
                <Link to="/login" className="navigation-text nav-right-text">
                  <i className="fa fa-user-o"></i> LOGIN
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToprops, mapDispatchToprops)(NavBar);
