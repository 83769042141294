import React, { Component } from "react";
import { Link } from 'react-router-dom'
// import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-top-inquiry">
            <Link to="/indonesia/contactus" className="footer-btn">
              Click here for inquiries
            </Link>
          </div>
          <div className="footer-contact">
            <div class="footer-phone">Nohara Care</div>
            <div className="footer-phone">+62 811-1575-893</div>
            <div className="footer-operation">
              <p>Operating hours : 8.30 - 17:30</p>
              <div style={{borderBottom: '1px dashed #fff', paddingTop: '20px'}}></div>
            </div>
            <a className="footer-email" href="mailto:indo_sales@nohara-inc.co.jp">indo_sales@nohara-inc.co.jp</a>
          </div>
        </div>
        
        <div className="footer-end">
          &copy; Copyright Nohara Indonesia 2020
        </div>
      </div>
    );
  }
}

export default Footer;
