import React from "react";
import ReactHtmlParser from "react-html-parser";

const NipoTopComponent = ({
  orientation,
  title,
  subtitle,
  subtitle2,
  image,
  url1,
  url2,
}) => {
  return (
    <div className={`nipo-list-${orientation ? "left" : "right"}`}>
      <div
        className="instagram-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "50%", marginRight: "10px" }}>
          <div className="image-container">
            <img src={require(`../../assets/images/${url1}`)} alt="" />
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div className="image-container">
            <img src={require(`../../assets/images/${url2}`)} alt="" />
          </div>
        </div>
      </div>
      <div className={`nipo-list-text-left`}>
        {image && (
          <div className="image-container">
            <img src={require(`../../assets/images/${image}`)} alt="" />
          </div>
        )}

        <div className={`nipo-list-paragraph-left`}>
          <h1>{title}</h1>
          <p className="mb15">{ReactHtmlParser(subtitle)}</p>
          <p className="mb15">{subtitle2}</p>
        </div>
      </div>
    </div>
  );
};

export default NipoTopComponent;
