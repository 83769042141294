import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

//Indonesia
import Home from "./components/Home/Home";
import Product from "./components/Product/product";
import ContactUs from "./components/ContactUs/contactus";
import Brai from "./components/Brai/brai";
import Maris from "./components/Maris/maris";
import Nipo from "./components/Nipo/nipo";
import Flortech from "./components/Flortech";
import Insutech from "./components/Insutech";
import Laticrete from "./components/Laticrete";
import AboutUs from "./components/AboutUs/aboutus";

//Viatnam
import HomeVietnam from "./components/Home/HomeVietnam";
import AboutUsVietnam from "./components/AboutUs/aboutusViet";
import ProductVietnam from "./components/Product/productVietnam";
import BraiVietnam from "./components/Brai/braiVietnam";
import NipoVietnam from "./components/Nipo/nipoVietnam";
import HardolassVietnam from "./components/Hardolas/hardolasVietnam";
import ContactUsVietnam from "./components/ContactUs/contactusVietnam";

//Global
import Welcome from "./components/Home/Welcome";
import Login from "./components/Login/login";
import NewsInter from "./components/News/newsInter";
import NewsListInter from "./components/News/newsListInter";
import AddNews from "./components/AdminPage/addNews";
import EditNews from "./components/AdminPage/editNews";
import Message from "./components/Message/message";
import ComingSoon from "./components/ComingSoon/comingSoon";
import GlobalComingSoon from "./components/ComingSoon/globalComingSoon";

import store from "./redux/store";
import { Provider } from "react-redux";

import { checkLogin } from "./redux/actions/authAction";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const token = localStorage.getItem("token");

const trackingId = "UA-181414959-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

if (token) {
  store.dispatch(checkLogin(token));
}

class App extends React.Component {
  componentDidMount() {
    console.log("this is application");
    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }
  // componentWillUnmount() {
  //   window.removeEventListener("scroll");
  // }
  render() {
    return (
      <>
        {
          <Provider store={store}>
            <Router history={history}>
              {/* WEBSITE */}
              <Route exact path="/" component={Welcome} />

              {/* Indonesian Page */}
              <Route exact path="/indonesia" component={Home} />
              <Route exact path="/indonesia/product" component={Product} />
              <Route exact path="/indonesia/brai" component={Brai} />
              <Route exact path="/indonesia/maris" component={Maris} />
              <Route exact path="/indonesia/nipo" component={Nipo} />
              <Route exact path="/indonesia/flortech" component={Flortech} />
              <Route exact path="/indonesia/laticrete" component={Laticrete} />
              <Route exact path="/indonesia/insutech" component={Insutech} />
              <Route exact path="/indonesia/aboutus" component={AboutUs} />
              <Route exact path="/indonesia/company" component={Home} />
              <Route exact path="/indonesia/contactus" component={ContactUs} />

              {/* Vietnam Page */}
              <Route exact path="/vietnam" component={HomeVietnam} />
              <Route exact path="/vietnam/aboutus" component={AboutUsVietnam} />
              <Route exact path="/vietnam/product" component={ProductVietnam} />
              <Route exact path="/vietnam/brai" component={BraiVietnam} />
              <Route exact path="/vietnam/nipo" component={NipoVietnam} />
              <Route
                exact
                path="/vietnam/laticrete"
                component={HardolassVietnam}
              />
              <Route
                exact
                path="/vietnam/contactus"
                component={ContactUsVietnam}
              />

              {/* Global Page */}
              <Route exact path="/message" component={Message} />
              <Route exact path="/addnews" component={AddNews} />
              <Route exact path="/news" component={NewsInter} />
              {/* <Route exact path="/international-news" component={NewsInter} /> */}
              <Route exact path="/news/:newsId" component={NewsListInter} />
              {/* <Route exact path="/international-news/:newsId" component={NewsListInter} /> */}
              <Route exact path="/editnews/:newsId" component={EditNews} />
              <Route exact path="/comingsoon" component={ComingSoon} />
              <Route
                exact
                path="/underconstruction"
                component={GlobalComingSoon}
              />
              <Route exact path="/login" component={Login} />
            </Router>
          </Provider>
        }
      </>
    );
  }
}

export default App;
