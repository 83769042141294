import React, { Component } from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import DownloadModal from "../Utils/Modal";

import Navbar from "../NavBar/NavBar";
import DeviderComponent from "../Utils/Devider";
import ContactUsComponent from "../Utils/ContactUs";
import BenefitBoxComponent from "../Utils/BenefitBox";
import NipoTopComponent from "../Utils/NipoTop";

class Insutech extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="TDS_INSUTECH_ALL_PRODUCT.pdf"
        />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/insutech.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>INSUTECH</h3>
                </div>
                <div className="text-subtitle">
                  Torch on Bituminous Membrane Waterproofing
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <DeviderComponent height={"60px"} />
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="maris-content">
              <div className="maris-content-title">
                <h2>NOHARA X INSUTECH</h2>
              </div>
              <div className="maris-content-p">
                At Nohara we are selective in our products range and we will
                prequalify each brand to ensure we bring good quality products
                to our customer.
              </div>
              <div className="maris-content-p">
                INSUTECH is a leading international company for insulation,
                construction and packaging technology. We innovate and
                manufacture a wide range of customized materials for commercial
                and personal usage. With more than 35 years of experience,
                INSUTECH managed to occupy the position of the world’s leading
                supplier for waterproofing insulation, thermal insulation and
                innovative construction solutions. Over the years, INSUTECH
                expanded and developed its production facilities to produce
                high-quality & customized EPS foam Exterior and interior
                decorations solutions, that can be utilized in most modern
                buildings.
              </div>
            </div>
          </div>
          {/* Content */}
          <ContactUsComponent />
          <div className="maris-list-container">
            <NipoTopComponent
              orientation={true}
              image={"products/kbf/9.png"}
              url1={"products/icons/41.png"}
              url2={"products/icons/43.png"}
              subtitle={`Bitumax Bituminous Membrane is made of quality material which makes the membrane flexible. With this flexible nature, bitumax bituminous membrane is easily applied to overlap.`}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/kbf/11.png"}
              url1={"products/icons/36.png"}
              url2={"products/icons/35.png"}
              subtitle={`With a good bitumen content, the fuel membrane bitumax is easy to bond with the substrate. So the time required for installation is shorter than usual`}
            />

            <DeviderComponent />
            <NipoTopComponent
              orientation={true}
              image={"products/kbf/10.png"}
              url1={"products/icons/37.png"}
              url2={"products/icons/38.png"}
              subtitle={`The bitumax bituminous membrane is proven to be easier in the torching process. In just a short time, the membrane is ready to be bonded to the substrate.`}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/kbf/12.png"}
              url1={"products/icons/39.png"}
              url2={"products/icons/41.png"}
              subtitle={`Bitumax bituminous membranes are suitable for use in tropical climates. With quite extreme temperature changes, bitumax bituminous membranes are able to withstand good expansion and shrinkage properties`}
            />
          </div>

          <ContactUsComponent />
          <BenefitBoxComponent
            product={"Insutech"}
            benefit={[
              {
                title: "Well Experienced",
                desc: "More than 35 years of experience, INSUTECH managed to occupy the position of the world’s leading supplier for waterproofing insulation, thermal insulation and innovative construction solutions.",
              },
              {
                title: "International Certified",
                desc: `We are "ISO" (ISO-9001-2015, ISO-14001-2015, ISO-45001-2018) and "CE" (CE-2020-2022) Certificated, which means that all of our products and solutions, comply with the European Standards.`,
              },
              {
                title: "High Quality Product",
                desc: "We are Committed to provide the highest quality, as well as maintaining professional safety and integrity standards.",
              },
              {
                title: "Support Our Clients",
                desc: "We offer full support to our clients even after delivery, through engineering consultancy, technical assistance, quality inspection, and recommendation of certified contractors and applicators.",
              },
            ]}
          />
          <ContactUsComponent />

          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                Learn more of Insutech product properties
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                Technical Data Sheet
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Insutech;
