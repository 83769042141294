import React, { Component } from "react";
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux'
import DownloadModal from '../Utils/Modal'

import Navbar from '../NavBar/NavbarVietnam'
import Footer from '../Footer/FooterVietnam'

import UnderConstruction from '../ComingSoon/com-underconstrucion'


class HardolassVietnam extends Component {
  state = {
    modalIsOpen: false,
    langChoise: 'VIET',
    langText: {
      eng: {
        sub1: {
          text: "At Nohara we are selective in our products range and we will prequalify each brand to ensure we bring good quality products to customer.",
          text2: "All Laticrete products are directly imported from Singapore and are also fully supported by technical support from Laticrete expertise.",
          text3: "For over 60 years, LATICRETE has provided the technology, products and service making innovative, permanent installations and repairs for the construction market. Architecturally specified and professionally endorsed, LATICRETE offers a dedication to quality that results in globally proven solutions."
        },
        // sub2: {
        //   text: "Dr.Hardolass is a high purity nano-glass coating agent made in Japan. Being a nano-glass coating, it repels water, dirts, bacteria, and also makes it easy to be clean with just water or a damp cloth."
        // },
        sub3: {
          text: "HOW DOES DR. HARDOLASS WORK?"
        },
        sub4: {
          text: "Mechanism: The Formation of a Nano-Glass Layer"
        },
        sub5: {
          text: "The components bond to the surface by rubbing the coating agent against it. Moisture in the air reacts with the nano-glass component, which creates a chemical bond. The bond allows the nano-glass components to connect to each other, which forms into a nano-glass layer."
        },
        sub6: {
          text: "Mechanism: Antibacterial/antiviral mechanism"
        },
        sub7: {
          text: `The antibacterial and antiviral components integrated in the nano glass layer reacts to the components in the air
          (such as O<sub>2</sub> and H<sub>2</sub>O). This reaction activates the radical of the antibacterial and antiviral component. 
          This radical ions destroys the envelope of the virus and bacteria, enhance inactivating it. This process results in surpressing the viral spread.`
        },
        sub8: {
          text: `The antibacterial and antiviral components integrated in the nano glass layer reacts to the components in the air
          (such as O<sub>2</sub> and H<sub>2</sub>O). This reaction activates the radical of the antibacterial and antiviral component. 
          This radical ions destroys the envelope of the virus and bacteria, enhance inactivating it. This process results in surpressing the viral spread.`
        },
        sub9: {
          text: "CLICK HERE FOR DR.HARDOLASS PRESENTATION DOWNLOAD"
        },
        sub10: {
          text: "PRODUCT FEATURES"
        },
        sub11: {
          text: "What are the features that make Dr. Hardolass coating stand out from other products?"
        },
        sub12: {
          text: "The excellent adhesion quality of the coating to the applied surface. Dr Hardolass being a nano coating, it is strongly bonded to the surface with its quantum force instead of relying on adhesives like regular disinfecting coating."
        },
        sub13: {
          text: "Dr Hardolass has a continuous reaction with the moisture in the air to produce activated radical ions that inactivate the viruses and bacteria."
        },
        sub14: {
          text: "Durable coating with 9H hardness to overcome de-bonding issue on the coated surface during cleaning."
        },
        sub15: {
          text: "The nano glass coating of Dr.Hardolass has the properties to resist acid as well as alkali that can damage the surface."
        },
        sub16: {
          text: "Being a nano-glass coating, it repels water, dirts, bacteria, and also makes it easy to be clean with just water or a damp cloth."
        },
        sub17: {
          text: "We're happy to answer your inquiries! Contact us for more information. +84 916282875 or duong-vg@nohara-inc.co.jp"
        },
        sub18: {
          text: "Certified for anti-virus and anti-bacterial performance by SIAA Japan"
        },
        sub19: {
          text: "What is SIAA"
        },
        sub20: {
          text: "The Society of International sustaining growth for Antimicrobial Articles (SIAA) is an organization of manufacturers and antimicrobial testing institutions of antimicrobial agents, fungicides, and treated Kohkin/Boukabi products whose goal is the popularization of appropriately treated and secure Kohkin/Boukabi products. With the opinions of industry and customer representatives, specialists, and the government, SIAA maintains rules governing the quality and safety of treated Kohkin products and certifies the labeling and use of SIAA marks, symbols for the security of products that comply with the rules and standards."
        },
        sub21: {
          text: "The safety of the antibacterial agent used in the product complies with SIAA voluntary standards"
        },
        sub22: {
          text: `"CLICK HERE FOR MORE INFORMATION ABOUT SIAA CERTIFICATION"`
        },
        sub23: {
          text: "EASY & DURABLE APPLICATION"
        },
        sub24: {
          text: "Where to apply Dr.hardolass coating?"
        },
        sub25: {
          text: "Prevent the spread of the virus in the health care facilities"
        },
        sub26: {
          text: "Prevent the spread of the virus in public area on buildings with high occupancy"
        },
        sub27: {
          text: "Prevent the spread of the virus on sharing meeting table"
        },
        sub28: {
          text: "Protextion for your customers"
        },
        sub29: {
          text: "... Especially for Frequently touched items"
        },
        sub30: {
          text: "PARTNERS"
        },
        sub31: {
          text: "We look forward to seeing you soon!"
        },
        sub32: {
          text: "Hardolass coating can be applied onto any solid material. You may be interested in knowing more about Hardolass? Why dont we start opening our partnership from right now!"
        },
        sub33: {
          text: "Let's protect people from virus and bacteria togehter with us! Why dont you join our Hardolass applicators network and start working on the job to protect people? Please feel free to leave your message to us anytime!"
        },
        sub34: {
          text: "Learn more of Dr.Hardolass product properties"
        },
        sub35: {
          text: "Technical Data Sheet"
        },
        sub36: {
          text: "Contact Us"
        }
      },
      viet: {
        sub1: {
          text: "Tại Nohara, chúng tôi chọn lọc các dòng sản phẩm của mình và sẽ đánh giá từng thương hiệu để đảm bảo mang đến những sản phẩm chất lượng tốt cho khách hàng.",
          text2: "Tất cả các sản phẩm của Laticrete đều được nhập khẩu trực tiếp từ Singapore và cũng được hỗ trợ hoàn toàn bởi đội ngũ chuyên gia kỹ thuật của Laticrete.",
          text3: "Trong hơn 60 năm, LATICRETE đã cung cấp công nghệ, sản phẩm và dịch vụ tạo ra các công trình thi công và sửa chữa một cách sáng tạo , có giá trị vĩnh viễn cho thị trường xây dựng. Được chỉ định về mặt kiến trúc và được xác nhận một cách chuyên nghiệp, LATICRETE cống hiến cho chất lượng, mang lại các giải pháp hiệu quả đã được chứng minh trên toàn cầu."
        },
        sub2: {
          text: "Dr.Hardolass là chất phủ kính nano có độ tinh khiết cao được sản xuất tại Nhật Bản. Là một lớp phủ nano kính, đẩy lùi nước, tạp chất, vi khuẩn và cũng giúp dễ dàng làm sạch chỉ bằng nước hoặc khăn ẩm."
        },
        sub3: {
          text: "DR. HADOLASS hoạt động như thế nào?"
        },
        sub4: {
          text: "Cơ chế: Tạo thành lớp kính Nano"
        },
        sub5: {
          text: "Các thành phần liên kết với bề mặt bằng cách lau chất phủ lên lên bề mặt vật cần phủ. Độ ẩm trong không khí phản ứng với thành phần thủy tinh nano, tạo ra liên kết hóa học. Liên kết cho phép các thành phần kính nano kết nối với nhau, tạo thành một lớp kính nano."
        },
        sub6: {
          text: "Cơ chế: Kháng khuẩn/kháng virut"
        },
        sub7: {
          text: `"Các thành phần kháng khuẩn và kháng vi-rút được tích hợp trong lớp kính nano sẽ phản ứng với các thành phần trong không khí (như O<sub>2</sub> and H<sub>2</sub>O). Phản ứng này kích hoạt gốc của thành phần kháng khuẩn và kháng vi-rút. Các ion gốc này phá hủy lớp vỏ của vi rút và vi khuẩn làm vi khuẩn vi rút không còn hoạt động. Quá trình này ngăn chặn tốc độ lây lan của virus."`
        },
        sub8: {
          text: `"Các thành phần kháng khuẩn và kháng vi-rút được tích hợp trong lớp kính nano sẽ phản ứng với các thành phần trong không khí (như O<sub>2</sub> and H<sub>2</sub>O). Phản ứng này kích hoạt gốc của thành phần kháng khuẩn và kháng vi-rút. Các ion gốc này phá hủy lớp vỏ của vi rút và vi khuẩn làm vi khuẩn vi rút không còn hoạt động. Quá trình này ngăn chặn tốc độ lây lan của virus."`
        },
        sub9: {
          text: "BẤM VÀO ĐÂY ĐỂ TẢI XUỐNG BẢNG GIỚI THIỆU SẢN PHẨM DR. HADOLASS"
        },
        sub10: {
          text: "ĐẶC ĐIỂM SẢN PHẨM"
        },
        sub11: {
          text: "Đặc điểm nổi bật của Dr. Hadolass so với các sản phẩm khác là gì?"
        },
        sub12: {
          text: "Chất lượng bám dính vượt trội của lớp phủ lên bề mặt thi công. Dr Hardolass là một lớp phủ nano, nó được liên kết chặt chẽ với bề mặt bằng lực lượng tử thay vì dựa vào chất kết dính như lớp phủ khử trùng thông thường."
        },
        sub13: {
          text: "Dr Hardolass có phản ứng liên tục với độ ẩm trong không khí để tạo ra các ion gốc hoạt hóa giúp vô hiệu hóa vi rút và vi khuẩn."
        },
        sub14: {
          text: "Lớp phủ bền với độ cứng 9H trên thang đo độ cứng để tăng liên kết trên bề mặt phủ trong quá trình lau chùi"
        },
        sub15: {
          text: "Lớp phủ kính Nano của Dr. Hadolass có tính năng kháng acid cũng như kiềm, là nguyên nhân làm hỏng bề mặt"
        },
        sub16: {
          text: "Là một lớp phủ nano kính, đẩy lùi nước, tạp chất, vi khuẩn và cũng giúp dễ dàng lau sạch chỉ bằng nước hoặc khăn ẩm."
        },
        sub17: {
          text: "Chúng tôi rất vui để trả lời những thắc mắc của các bạn! Liên hệ chúng tôi để biết thêm thông tin tại (+84) 916282875"
        },
        sub18: {
          text: "Được chứng nhận về hiệu suất chống vi rút và chống vi khuẩn bởi SIAA Nhật Bản"
        },
        sub19: {
          text: "SIAA là gì"
        },
        sub20: {
          text: "Hiệp hội phát triển bền vững quốc tế về kháng khuẩn (SIAA) là một tổ chức gồm các nhà sản xuất và cơ quan kiểm tra chất kháng khuẩn, thuốc diệt nấm và các sản phẩm Kohkin / Boukabi được xử lý với mục tiêu là phổ biến các sản phẩm Kohkin / Boukabi được xử lý thích hợp và an toàn. Với ý kiến ​​của các đại diện ngành và khách hàng, các chuyên gia và chính phủ, SIAA duy trì các quy tắc quản lý chất lượng và độ an toàn của các sản phẩm Kohkin đã qua xử lý và chứng nhận việc ghi nhãn và sử dụng nhãn hiệu SIAA, biểu tượng cho sự an toàn của các sản phẩm tuân thủ các quy tắc và tiêu chuẩn"
        },
        sub21: {
          text: "Tính an toàn của chất kháng khuẩn được sử dụng trong sản phẩm tuân theo các tiêu chuẩn của SIAA."
        },
        sub22: {
          text: `"BẤM VÀO ĐÂY ĐỂ BIẾT THÊM THÔNG TIN VỀ CHỨNG NHẬN SIAA"`
        },
        sub23: {
          text: "SỬ DỤNG DỄ DÀNG"
        },
        sub24: {
          text: "Phủ Dr. Hadolass ở đâu"
        },
        sub25: {
          text: "Ngăn chặn sự lây lan của Virut trong các cơ sở y tế"
        },
        sub26: {
          text: "Ngăn chặn sự lây lan của vi rút trong khu vực công cộng, trên các tòa nhà có nhiều người ở"
        },
        sub27: {
          text: "Ngăn chặn sự lây lan vi rut ở bàn họp chung"
        },
        sub28: {
          text: "Bảo vệ khách hàng của bạn"
        },
        sub29: {
          text: "Đặc biệt đối với những nơi thường xuyên chạm vào"
        },
        sub30: {
          text: "ĐỐI TÁC"
        },
        sub31: {
          text: "Chúng tôi mong sớm gặp lại bạn."
        },
        sub32: {
          text: "Lớp phủ cứng có thể được áp dụng trên bất kỳ vật liệu rắn nào. Bạn có thể muốn biết thêm về Hardolass? Tại sao chúng ta không bắt đầu hợp tác như đối tác ngay từ bây giờ!"
        },
        sub33: {
          text: "Hãy cùng chúng tôi bảo vệ con người khỏi virus và vi khuẩn! Tại sao bạn không tham gia mạng lưới ứng viên Hardolass của chúng tôi và bắt đầu thực hiện công việc bảo vệ mọi người? Xin vui lòng để lại tin nhắn của bạn cho chúng tôi bất cứ lúc nào!"
        },
        sub34: {
          text: "Tìm hiểu thêm về các đặc tính của sản phẩm Dr. Hadolass"
        },
        sub35: {
          text: "Bảng tài liệu kỹ thuật"
        },
        sub36: {
          text: "Liên hệ chúng tôi"
        }
      }
    }
  }

  openModal = () => {
    this.setState({
      modalIsOpen : true
    })
  }
 
  closeModal = () => {
    this.setState({
      modalIsOpen : false
    })
  }

  componentDidMount() {
    console.log('mounted')
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if(header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { isEn } = this.props
    const { langChoise, langText, modalIsOpen } = this.state
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName='hardolass.pdf'
        />
        <div className={isEn ? "home-container" : "home-container viet-theme"}>
          {/* Top Image start  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/laticrete/lan-banner.jpg")}
              alt=""
            />
          </div>
          {/* Top Image end */}
          {/* Devider start */}
          <div style={{ width: "100vw", height: "60px" }}>&nbsp;</div>
          {/* Devider end */}
          {/* Slider start  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          {/* Slider end */}
          <div className="nohara-x-brai">
            <div className="maris-content">
              <div className="maris-content-title">
                <h2>NOHARA x LATICRETE</h2>
              </div>
              <div className="maris-content-p">
                { langText[isEn ? 'eng' : 'viet'].sub1.text }
              </div>
              <div className="maris-content-p">
                { langText[isEn ? 'eng' : 'viet'].sub1.text2 }
              </div>
              <div className="maris-content-p">
                { langText[isEn ? 'eng' : 'viet'].sub1.text3 }
              </div>
            </div>
          </div>
          {/* contact ribbon start */}
          <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                { langText[isEn ? 'eng' : 'viet'].sub17.text }
              </p>
              <Link to="/vietnam/contactus" className="maris-contact__button">
                { langText[isEn ? 'eng' : 'viet'].sub36.text }
              </Link>
            </div>
          </div>
          <UnderConstruction />
          {/* contact ribbon end */}

          {/* content 1 start */}
          {/* <div className="hardolas-content-container">
            <div className="hardolas-container">
              <div className="hardolas-top-img">
                <img
                  src={require("../../assets/images/hardolas/kbf3.jpg")}
                  alt=""
                />
              </div>
              <div>
                <p className="hardolas-title hardolas-bold">
                { langText[isEn ? 'eng' : 'viet'].sub3.text }
                </p>
                <p className="hardolas-subtitle">
                { langText[isEn ? 'eng' : 'viet'].sub4.text }
                </p>
              </div>
              <div className="nano-glass-img">
                <img
                  src={require("../../assets/images/hardolas/mechanism-1.jpg")}
                  alt=""
                />
              </div>
              <div>
                { langText[isEn ? 'eng' : 'viet'].sub5.text }
              </div>

              <div>
                <br/>
                <p className="hardolas-subtitle">
                  { langText[isEn ? 'eng' : 'viet'].sub6.text }
                </p>
              </div>
              <div className="nano-glass-img">
                <img
                  src={require("../../assets/images/hardolas/mechanism-2.jpg")}
                  alt=""
                />
              </div>
              <div>
                { ReactHtmlParser(langText[isEn ? 'eng' : 'viet'].sub7.text)}
              </div>
            </div>
          </div> */}
          {/* content 1 end */}
          {/* content 2 start */}
          {/* <div className="hardolas-applications-container">
            <div className="app-wrapper">
              <div className="sia-full-img">
                <img
                  src={require("../../assets/images/hardolas/app1.jpg")}
                  alt=""
                />
              </div>
              <div className="sia-text-desc">
                <p>
                <p>
                  { ReactHtmlParser(langText[isEn ? 'eng' : 'viet'].sub8.text)}
                </p>
                </p>
              </div>
              <div className="sia-full-img">
                <img
                  src={require("../../assets/images/hardolas/app2.png")}
                  alt=""
                />
              </div>
            </div>
          </div> */}
          {/* content 2 end */}
          {/* content 3 start */}
          {/* <div className="hardolas-content-container">
            <div className="hardolas-container">
              <div className="hardolas-presentation-download">
                <img
                  className="download-img"
                  src={require("../../assets/images/hardolas/download.jpg")}
                  alt=""
                />
                <a href={require('../../assets/tds/hardolass_presentation.pdf')} className="hardolas-button-download" download>
                  { langText[isEn ? 'eng' : 'viet'].sub9.text }
                </a>
              </div>
              <div className="feature-title">{ langText[isEn ? 'eng' : 'viet'].sub10.text }</div>
              <div className="feature-subtitle">
                { langText[isEn ? 'eng' : 'viet'].sub11.text }
              </div>
              <div className="feature-with-bg">
                <img
                  src={require("../../assets/images/hardolas/f1.png")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub12.text }
                </p>
              </div>
              <div className="feature-with-bg-right">
                <img
                  src={require("../../assets/images/hardolas/f2.png")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub13.text }
                </p>
              </div>
              <div className="feature-with-bg">
                <img
                  src={require("../../assets/images/hardolas/f3.png")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub14.text }
                </p>
              </div>
              <div className="feature-with-bg-right">
                <img
                  src={require("../../assets/images/hardolas/f4.png")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub15.text }
                </p>
              </div>
              <div className="feature-with-bg">
                <img
                  src={require("../../assets/images/hardolas/f5.png")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub16.text }
                </p>
              </div>
            </div>
          </div> */}
          {/* content 3 end */}

          {/* contact ribbon start */}
          {/* <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                { langText[isEn ? 'eng' : 'viet'].sub17.text }
              </p>
              <Link to="/vietnam/contactus" className="maris-contact__button">
                { langText[isEn ? 'eng' : 'viet'].sub36.text }
              </Link>
            </div>
          </div> */}
          {/* contact ribbon end */}

          {/* content 4 start */}
          {/* <div className="hardolas-content-container">
            <div className="hardolas-container">
              <div className="hardolas-certified-center">
                <div className="hardolas-certified-title">
                  { langText[isEn ? 'eng' : 'viet'].sub18.text }
                </div>
                <div className="certified-img-list">
                  <img
                    src={require("../../assets/images/hardolas/anti-bacterial.jpg")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/images/hardolas/anti-virus.jpg")}
                    alt=""
                  />
                </div>
                <div className="siaa-description">
                  <h3>{ langText[isEn ? 'eng' : 'viet'].sub19.text }</h3>
                  <p>
                    { langText[isEn ? 'eng' : 'viet'].sub20.text }
                  </p>
                </div>
                <div>
                  { langText[isEn ? 'eng' : 'viet'].sub21.text }
                </div>
                <div className="siaa-test">
                  <img
                    src={require("../../assets/images/hardolas/siaa-test.png")}
                    alt=""
                  />
                </div>
                <a href="https://www.kohkin.net/en_index/en_siaa.html" className="laboratory-download-btn">
                  { langText[isEn ? 'eng' : 'viet'].sub22.text }
                </a>
              </div>
            </div>
          </div> */}
          {/* content 4 end */}
          {/* content 5 start */}
          {/* <div className="hardolas-applications-container">
            <div className="video-title">
              <h3>{ langText[isEn ? 'eng' : 'viet'].sub23.text }</h3>
            </div>
            <div className="hardolas-video-wrapper">
              <div className="video-wrapper">
                <video className="video-tag" controls>
                  <source src={require('../../assets/video/1.mp4')} type="video/mp4"></source>
                </video>
              </div>
              <div className="video-wrapper">
                <video className="video-tag" controls>
                  <source src={require('../../assets/video/2.mp4')} type="video/mp4"></source>
                </video>
              </div>
              <div className="video-wrapper">
                <video className="video-tag" controls>
                  <source src={require('../../assets/video/3.mp4')} type="video/mp4"></source>
                </video>
              </div>
            </div>
            <div className="coating-location-title">
              <h3>{ langText[isEn ? 'eng' : 'viet'].sub24.text }</h3>
            </div>
            <div className="hardolas-coating-lacation">
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/facilities.jpg")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub25.text }
                </p>
              </div>
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/building.jpg")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub26.text }
                </p>
              </div>
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/office.jpg")}
                  alt=""
                />
                <p>{ langText[isEn ? 'eng' : 'viet'].sub27.text }</p>
              </div>
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/shops.jpg")}
                  alt=""
                />
                <p>{ langText[isEn ? 'eng' : 'viet'].sub28.text }</p>
              </div>
            </div>

            <div className="touch-item-title">
              <h3>{ langText[isEn ? 'eng' : 'viet'].sub29.text }</h3>
            </div>
            <div className="hardolas-coating-lacation">
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/elevator.jpg")}
                  alt=""
                />
              </div>
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/MOBILE.jpg")}
                  alt=""
                />
              </div>
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/cash-register.jpg")}
                  alt=""
                />
              </div>
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/ATM.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div> */}
          {/* content 5 end */}
          {/* content 6 start */}
          {/* <div className="hardolas-applications-container hardolas-partner-container">
            <div
              className="hardolas-partner-title"
              style={{ color: "#0c894f" }}
            >
              { langText[isEn ? 'eng' : 'viet'].sub30.text }
            </div>
            <div className="hardolas-partner-title">
              { langText[isEn ? 'eng' : 'viet'].sub31.text }
            </div>
            <div className="hardolas-coating-lacation">
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/area.jpg")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub32.text }
                </p>
              </div>
              <div className="location-wrapper">
                <img
                  src={require("../../assets/images/hardolas/app-partner.jpg")}
                  alt=""
                />
                <p>
                  { langText[isEn ? 'eng' : 'viet'].sub33.text }
                </p>
              </div>
            </div>
          </div>
          <div className="hardolas-content-container">
            <div className="hardolas-container">
              <div className="hardolas-presentation-download">
                <img
                  className="download-img"
                  src={require("../../assets/images/hardolas/download.jpg")}
                  alt=""
                />
                <a href={require('../../assets/tds/hardolass_presentation.pdf')} className="hardolas-button-download" download>
                { langText[isEn ? 'eng' : 'viet'].sub9.text }
                </a>
              </div>
            </div>
          </div> */}
          {/* content 6 end */}

          {/* Datasheet start*/}
          {/* <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                { langText[isEn ? 'eng' : 'viet'].sub34.text }
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>{ langText[isEn ? 'eng' : 'viet'].sub35.text }</button>
            </div>
          </div> */}
          {/* Datasheet end */}
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = state => {
  return {
    isEn: state.auth.isEn
  }
}

export default connect(mapStateToprops)(HardolassVietnam);
