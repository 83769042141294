import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from '../../redux/actions/authAction'

class NavbarNews extends Component {
  onSignOutHandler = () => {
    console.log('logout')
    this.props.signOut()
  }
  render() {
    return (
      <div className="navbar-news-container">
        <div className="padding-page navbar-news-lang">
          <div className="navbar-news-subcontainer">&nbsp;</div>
          <div className="navbar-news-subcontainer">EN / BAHASA</div>
        </div>
        <div className="padding-page navbar-news-country">
          <Link to="/" className="country-welcome">Welcome to Nohara International Network!</Link>
          <a className="country-name" href="http://www.nohara.in/" target="_blank" rel="noopener noreferrer">INDIA</a>
          <a className="country-name" href="https://nohara-international.com/indonesia" target="_blank" rel="noopener noreferrer">INDONESIA</a>
          <a className="country-name" href="https://nohara-international.com" target="_blank" rel="noopener noreferrer">VIETNAM</a>
          <a className="country-name" href="https://nohara-international.com" target="_blank" rel="noopener noreferrer">SINGAPORE</a>
          <a className="country-name" href="https://nohara-international.com" target="_blank" rel="noopener noreferrer">CHINA</a>
          <a className="country-name" href="https://nohara-inc.co.jp/" target="_blank" rel="noopener noreferrer">JAPAN</a>
        </div>
      </div>
    );
  }
}

const mapStateToprops = state => {
  return {
    authenticated: state.auth.authenticated
  };
};

const mapDispatchToprops = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(
  mapStateToprops,
  mapDispatchToprops
)(NavbarNews);
