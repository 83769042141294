import React, { Component } from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import DownloadModal from "../Utils/Modal";

import Navbar from "../NavBar/NavBar";

class Maris extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="TDS_MARIS_ALL_PRODUCT.pdf"
        />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/brai_2.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>MARIS</h3>
                </div>
                <div className="text-subtitle">
                  Liquid Based Polyurethane Waterproofing
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "60px" }}>&nbsp;</div>
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="maris-content">
              <div className="maris-content-title">
                <h2>NOHARA X MARIS</h2>
              </div>
              <div className="maris-content-p">
                At Nohara we are selective in our products range and we will
                prequalify each brand to ensure we bring good quality products
                to customer
              </div>
              <div className="maris-content-p">
                All Maris products are directly imported from Singapore and are
                also fully supported by technical support from Maris expertise.
              </div>
              <div className="maris-content-p">
                Maris Polymers is a waterproofing systems house and one of the
                leading European specialist in polyurethane liquid applied
                waterproofing products and cold curing polyurethane resins for
                the Construction Industry. In addition, Maris Polymers proves
                technological leadership by offering a whole range of special
                solutions and customised products.
              </div>
            </div>
          </div>
          {/* Content */}
          <div class="maris-contact-container">
            <div class="maris-contact">
              <p class="maris-contact__text">
                We're happy to answer your inquiries! Contact us for more
                product information. +62 811-1575-893 or
                indo_sales@nohara-inc.co.jp
              </p>
              <a class="maris-contact__button" href="/indonesia/contactus">
                Contact Us
              </a>
              <a
                href="https://share.hsforms.com/17e2EwIu1T8KfGdl1NlRAhg5mbjs"
                target="_blank"
                rel="noopener noreferrer"
                class="maris-contact__button"
              >
                Get Free Sample
              </a>
            </div>
          </div>
          <div className="maris-list-container">
            {/* 1 */}
            <div className="maris-list-left">
              <div className="maris-list-img">
                <img src={require("../../assets/images/maris1.jpg")} alt="" />
              </div>
              <div className="maris-list-text">
                <div className="brai-number mb-15">01</div>
                <div className="brai-list-title mb-15">
                  Leading Polyurethane waterproofing material worldwide
                </div>
                <div className="maris-list-paragraph">
                  <p className="mb-15">
                    Maris Polymers has successful presence in over 70 countries
                    worldwide.
                  </p>
                  <p className="mb-15">
                    Founded in 1969, Maris Polymers is leading European
                    specialist in polyurethane applied waterproofing products.
                  </p>
                </div>
              </div>
            </div>
            <div className="maris-list-right">
              <div className="maris-list-img-right">
                <img src={require("../../assets/images/maris2.jpg")} alt="" />
              </div>
              <div className="maris-list-text-right">
                <div className="brai-number mb-15">02</div>
                <div className="brai-list-title mb-15">
                  Wide range products with latest technology
                </div>
                <div className="maris-list-paragraph-right">
                  <p className="mb-15">
                    Maris Polymers provide various liquid applied type of
                    solution from roof, balconies, walkways, foundations,
                    retaining walls, to portable water tanks.
                  </p>
                  <p className="mb-15">
                    Maris polymers product has wide range of products for all
                    your project needs.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="maris-list-left">
              <div className="maris-list-img">
                <img src={require("../../assets/images/maris32.jpg")} alt="" />
              </div>
              <div className="maris-list-text">
                <div className="brai-number mb-15">03</div>
                <div className="brai-list-title mb-15">
                  Seamless application without joint
                </div>
                <div className="maris-list-paragraph">
                  <p className="mb-15">
                    The advantage of using liquid polyurethane waterproofing is
                    the seamless installation with clean finish look without
                    joints or leak possibilities.
                  </p>
                  <p className="mb-15">
                    The application is also easy by using a roller or airless
                    spray.
                  </p>
                </div>
              </div>
            </div>
            <div className="maris-list-right">
              <div className="maris-list-img-right">
                <img src={require("../../assets/images/maris4.jpg")} alt="" />
              </div>
              <div className="maris-list-text-right">
                <div className="brai-number mb-15">04</div>
                <div className="brai-list-title mb-15">
                  Excellent for heavy duty carpark
                </div>
                <div className="maris-list-paragraph-right">
                  <p className="mb-15">
                    Has a specialty for high performance use, for example in
                    heavy duty carpark buildings.
                  </p>
                  <p className="mb-15">
                    Mariseal system from Maris Polymers is very suitable for
                    exposed installation with heavy traffic because of it’s high
                    performance and UV stable features!
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="maris-list-left">
              <div className="maris-list-img">
                <img src={require("../../assets/images/maris5.jpg")} alt="" />
              </div>
              <div className="maris-list-text">
                <div className="brai-number mb-15">05</div>
                <div className="brai-list-title mb-15">
                  Provide excellent thermal and UV resistance
                </div>
                <div className="maris-list-paragraph">
                  <p className="mb-15">
                    High solid content of polyurethane in Maris Polymers product
                  </p>
                  <p className="mb-15">
                    Maintainance of its mechanical properties, over a
                    temperature span of -30&#176;C to +90&#176;C. Has better
                    thermal and UV resistance properties than other PU
                    waterproofing brands in general.
                  </p>
                </div>
              </div>
            </div>
            <div className="maris-list-right">
              <div className="maris-list-img-right">
                <img src={require("../../assets/images/maris6.jpg")} alt="" />
              </div>
              <div className="maris-list-text-right">
                <div className="brai-number mb-15">06</div>
                <div className="brai-list-title mb-15">
                  Available transparent coating
                </div>
                <div className="maris-list-paragraph-right">
                  <p className="mb-15">
                    No longer need to hack away the tiles on your leaking
                    balconies or terraces to redo your waterproofing. MarisTrans
                    is a transparent, UV-stable waterproof coating that can goes
                    over your existing balcony’s tiles and it is a pedestrian
                    resistant membrane.
                  </p>
                  <p className="mb-15">
                    Save cost, Save time and no more mess for you !
                  </p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="maris-list-left">
              <div className="maris-list-img">
                <img src={require("../../assets/images/maris7.jpg")} alt="" />
              </div>
              <div className="maris-list-text">
                <div className="brai-number mb-15">07</div>
                <div className="brai-list-title mb-15">
                  Non toxic for water tanks and pool
                </div>
                <div className="maris-list-paragraph">
                  <p className="mb-15">
                    Elastic waterproofing coating system for water tanks
                    provided by Mariseal system. Two-components, odourless,
                    solvent-flree, liquid-applied, polyurethane coating.
                  </p>
                  <p className="mb-15">
                    Mariseal 300 is safe to use and certified for direct and
                    permanent contact even for drinking water.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Benefit */}
          <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                We're happy to answer your inquiries! Contact us for more
                information. +62 811-1575-893 or indo_sales@nohara-inc.co.jp
              </p>
              <Link to="/indonesia/contactus" className="maris-contact__button">
                Contact Us
              </Link>
            </div>
          </div>

          <div className="maris-benefit">
            <div className="maris-benefit-box">
              <div className="maris-box-title">
                <h1>What benefits do you get more when choosing Maris?</h1>
              </div>
              <div className="maris-benefit-top">
                <div className="maris-benefit-top-list">
                  <h3>High Application Coverage</h3>
                  <p>
                    Maris Polymers is using pure polyurethane resins with high
                    solid content (Mariseal 250 {">"} 90%), and it provides
                    higher coverage with about 1mm thick for 2 layers of
                    application as compared to other products with low solid
                    content.
                  </p>
                </div>
                <div className="maris-benefit-top-list">
                  <h3>Long-lasting Performance Assurance</h3>
                  <p>
                    Superior elongation and tensile strength. Like our Mariseal
                    250, It can provides excellent adhesion to almost any type
                    of surfaces and it can also bridge cracks up to 2mm wide. In
                    addition, it is also suitable for green roof as it is root
                    resistant.
                  </p>
                </div>
              </div>
              <div className="maris-benefit-top">
                <div className="maris-benefit-top-list">
                  <h3>Complete product solution</h3>
                  <p>
                    Provide various liquid applied type of solutions from roof,
                    balconies, walkways, foundations, retaining walls, to
                    portable water tanks. Available for all your project needs!
                  </p>
                </div>
                <div className="maris-benefit-top-list">
                  <h3>
                    Good Solar Reflectance and Excellent Weather Resistance
                  </h3>
                  <p>
                    Provides high sun reflectivity, contributing to
                    thermo-insulation. The Top Coat of Maris Polymers is also UV
                    and Color stable with non-yellowing and non-chalking finish.
                  </p>
                </div>
              </div>

              <div className="maris-benefit-center">
                <div className="maris-benefit-center-list">
                  <h3>Internationally Recognized</h3>
                  <p>
                    Great number of customers in more than 70 countries
                    worldwide put their trust in Maris Polymers system. Get
                    assistance directly from Maris inventor and expertise.
                  </p>
                </div>
              </div>

              <div className="maris-box-title" style={{ marginTop: "60px" }}>
                <h1>… and even more with partnering with Nohara</h1>
              </div>

              <div className="maris-benefit-top">
                <div className="maris-benefit-top-list">
                  <h3>Nohara Care benefit</h3>
                  <p>
                    Get more networks for your business with support of Nohara
                    exclusive customer service program, Nohara care. We grow
                    together, we succeed together!
                  </p>
                </div>
                <div className="maris-benefit-top-list">
                  <h3>High value product</h3>
                  <p>
                    Get all the benefits of great quality products with good
                    price, along technical supports and Nohara Care benefit for
                    all our customers.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Datasheet */}
          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                Learn more of Maris product properties
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                Technical Data Sheet
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Maris;
