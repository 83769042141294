import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
import api from "../../configs/api";

import ReactHtmlParser from "react-html-parser";

import NewsRightSide from "./newsRightSide";
import NavbarNews from "../NavBar/NavbarNews";
import FooterNews from "../Footer/FooterNews";

import moment from "moment";

function NewsList({ history }) {
  const { newsId } = useParams();
  const [news, setNews] = React.useState(null);

  const getNewsById = async (id) => {
    try {
      const { data } = await Axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/showById/${id}`,
      });

      if (data && data.status === "success") {
        await setNews(data.message);
      }
    } catch (error) {
      alert("Error");
      history.push("/");
    }
  };

  useEffect(() => {
    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }, []);

  useEffect(() => {
    getNewsById(newsId);
    console.log("mounted");
    window.scrollTo(0, 0);
  }, [newsId]);

  return (
    news && (
      <>
        <NavbarNews />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/news.png")}
              alt=""
              style={{ opacity: 0.65 }}
            />
            <div className="news-top-card">
              <div className="news-top-card__top">
                <h5 className="text-subtitle">{news.title}</h5>
              </div>
            </div>
            {/* <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div className="text-subtitle">{news.title}</div>
                <div className="text-subtitle-post">
                  {new Date(news.createdAt).toISOString().substr(0, 10)}
                </div>
              </div>
            </div> */}
          </div>
          <div className="news-content" style={{ marginTop: "20px" }}>
            <div>
              <span className="news-bread-crumb">
                <Link to={`/`}>HOME</Link>
              </span>{" "}
              <span className="news-bread-crumb">{` > `}</span>{" "}
              <span className="news-bread-crumb">
                <Link to={`/news`}>News List</Link>
              </span>
              <span className="news-bread-crumb">{` > `}</span>{" "}
              <span className="news-bread-crumb">{news.title}</span>
            </div>
          </div>

          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Content */}
          <div className="news-content">
            <div className="news-list-content">
              <div
                className="news-content-wrapper"
                style={{ marginBottom: "100px" }}
              >
                <div style={{ marginBottom: "30px" }}>
                  {moment(news.createdAt).format("DD/MM/YY")}
                </div>
                {ReactHtmlParser(news.content)}
                <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                  share
                </div>
              </div>
            </div>
            <NewsRightSide />
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Footer */}

          <div className="id-section-download">
            <div className="id-download-button-wrapper">
              <h4>
                <Link to="/indonesia/contactus" className="id-download-btn">
                  Download Techical Data
                </Link>
              </h4>
            </div>
            <div className="id-download-button-wrapper">
              <img
                src={require("../../assets/images/brochure-download.png")}
                alt=""
                style={{ width: "50%", objectFit: "cover" }}
              />
            </div>
          </div>
          <FooterNews />
        </div>
      </>
    )
  );
}

export default NewsList;
