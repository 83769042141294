import isEmpty from "../../validation/isEmpty";
const initState = {
  authenticated: false,
  error: "",
  signUpSuccess: false,
  isEn: true,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case "VIET_LANG":
      return {
        ...state,
        isEn: !state.isEn
      }
    case "SIGNIN_SUCCESS":
      return {
        ...state,
        authenticated: true,
      };
    case "SIGNIN_ERROR":
      return {
        ...state,
        authenticated: false,
        error: action.error.response,
      };
      case "SIGNUP_SUCCESS":
      return {
        ...state,
        error: null,
        signUpSuccess: true
      };
      case "SIGNUP_ERROR":
      return {
        ...state,
        error: action.error.response,
      };
      case "SIGNOUT_SUCCESS":
      return {
        ...state,
        authenticated: false
      }
    case "CHECK_LOGIN":
      return {
        ...state,
        authenticated: !isEmpty(action.payload.token),
      };
    case "AUTH_LOADING":
      return {
          ...state,
          authLoading: true
      }
    default:
      return state;
  }
};

export default auth;
