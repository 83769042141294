import React, { Component } from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import DownloadModal from "../Utils/Modal";

import Navbar from "../NavBar/NavBar";

class Brai extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="TDS_BRAI_MEMBRANE.pdf"
        />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/brai.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>BRAI</h3>
                </div>
                <div className="text-subtitle">
                  Bitumen Polymer Membrane Waterproofing
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "60px" }}>&nbsp;</div>
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="brai-content">
              <div className="brai-content-title">
                <h2>NOHARA X BRAI</h2>
              </div>
              <div className="brai-content-p">
                At Nohara we are selective in our products range and we will
                prequalify each brand to ensure we bring good quality products
                to customer.
              </div>
              <div className="brai-content-p">
                Product Brai, are directly imported from Italy and also fully
                supported by Brai's experts in Italy and Thailand.
              </div>
              <div className="brai-content-p">
                Product Highlight: MAGNUM APP torch-on membranes waterproofing
                is the ideal solution offered by Brai for all type of roofs and
                for retaining walls which ensure a high protection against
                ascending moisture. The material used is the lastest generation
                of reinforcement in non-woven polyester stabilized with glass
                fiber threads, making installation better and easier.
              </div>
            </div>
          </div>
          {/* Content */}
          <div class="maris-contact-container">
            <div class="maris-contact">
              <p class="maris-contact__text">
                We're happy to answer your inquiries! Contact us for more
                product information. +62 811-1575-893 or
                indo_sales@nohara-inc.co.jp
              </p>
              <a class="maris-contact__button" href="/indonesia/contactus">
                Contact Us
              </a>
              <a
                href="https://share.hsforms.com/17e2EwIu1T8KfGdl1NlRAhg5mbjs"
                target="_blank"
                class="maris-contact__button"
                rel="noopener noreferrer"
              >
                Get Free Sample
              </a>
            </div>
          </div>
          <div className="brai-list-container">
            <div className="brai-list-left">
              <div className="brai-list-img">
                <img src={require("../../assets/images/braiKbf1.jpg")} alt="" />
              </div>
              <div className="brai-list-text">
                <div className="brai-number mb-30">01</div>
                <div className="brai-list-title mb-30">
                  The founder of polymer-bitumen waterproofing membrane
                </div>
                <div className="brai-list-paragraph">
                  <p className="mb-30">Being the first, made a difference!</p>
                  <p className="mb-30">
                    First discovery of bitumen addition with APP polymer by
                    Erwin Brietner resulting in the rise of generation of
                    polymer-bitumen waterproofing membrane, thus the company
                    Brai in 1975.
                  </p>
                  <p className="mb-30">
                    Since then Brai continuing to innovate and constantly
                    contributed to the evolution of waterproofing, because it’s
                    in Brai DNA.
                  </p>
                </div>
              </div>
            </div>
            <div className="brai-list-right">
              <div className="brai-list-img-right">
                <img src={require("../../assets/images/braiKbf2.jpg")} alt="" />
              </div>
              <div className="brai-list-text-right">
                <div className="brai-number mb-30">02</div>
                <div className="brai-list-title mb-30">
                  European quality tailormade for tropical weather
                </div>
                <div className="brai-list-paragraph-right">
                  <p className="mb-30">
                    Compared with most other torch-on-membrane brands in
                    Indonesia that do not have their own factories, Brai
                    products come directly from a production facility in Italy
                    which has a production facility of 30,000 square meters.
                  </p>
                  <p className="mb-30">
                    Brai's products and its manufacturing process complied with
                    European standard QC systems, so Brai's products are ensured
                    to be the highest quality. In addition, Brai products have
                    been adapted to suit installation conditions in tropical
                    climates.
                  </p>
                </div>
              </div>
            </div>
            <div className="brai-list-left">
              <div className="brai-list-img">
                <img src={require("../../assets/images/braiKbf3.jpg")} alt="" />
              </div>
              <div className="brai-list-text">
                <div className="brai-number mb-30">03</div>
                <div className="brai-list-title mb-30">
                  Product guarantee for 10 years
                </div>
                <div className="brai-list-paragraph">
                  <p className="mb-30">
                    Worry-free when you use Brai waterproofing for your
                    building. Not only Brai has a good product but also a
                    technical assistant to provide installation instructions for
                    all our customers.
                  </p>
                  <p className="mb-30">
                    Water leakage that occur are often the result of incorrect
                    installation, therefore Brai realize the importance of
                    installation guidance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Benefit */}
          <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                We're happy to answer your inquiries! Contact us for more
                information. +62 811-1575-893 or indo_sales@nohara-inc.co.jp
              </p>
              <Link to="/indonesia/contactus" className="maris-contact__button">
                Contact Us
              </Link>
            </div>
          </div>

          <div className="brai-benefit">
            <div className="brai-benefit-box">
              <div className="brai-box-title">
                <h1>What benefits do you get more when choosing Brai?</h1>
              </div>
              <div className="benefit-top">
                <div className="benefit-top-list">
                  <h3>Easier application</h3>
                  <p>
                    Better polyester quality makes installation easier. Brai
                    material has a thickness of 3mm with good flexibility and is
                    not brittle, especially for application on the corners.
                  </p>
                </div>
                <div className="benefit-top-list">
                  <h3>Better product stability</h3>
                  <p>
                    Has a smaller product dimensional tolerance, {"<"} 0.3%
                    compared to other products with 1% tolerance. Good product
                    stability and low shrinkage provide confidence, especially
                    for long-term waterproofing installations.
                  </p>
                </div>
              </div>

              <div className="benefit-top">
                <div className="benefit-top-list">
                  <h3>Original brand since 1975</h3>
                  <p>
                    As inventor of polymer bitumen waterproofing membrane, Brai
                    continues to innovate waterproofing system. And assure
                    product quality with European Standard 30,000 m2 production
                    facility in Italy.
                  </p>
                </div>
                <div className="benefit-top-list">
                  <h3>Get assistance from expertise</h3>
                  <p>
                    Get assistance directly from Brai inventor and expertise. We
                    will support all your needs regarding technical service
                    directly from Brai experts in Italy and Thailand.
                  </p>
                </div>
              </div>

              <div className="brai-box-title" style={{ marginTop: "60px" }}>
                <h1>… and even more by partnering with Nohara</h1>
              </div>

              <div className="benefit-top">
                <div className="benefit-top-list">
                  <h3>Nohara Care benefits</h3>
                  <p>
                    Get more networks for your business with support of Nohara
                    exclusive customer service program, Nohara care. We grow
                    together, we succeed together!
                  </p>
                </div>
                <div className="benefit-top-list">
                  <h3>High value product</h3>
                  <p>
                    Get all the benefits of products and supports with
                    competitive prices for choosing Brai!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Product */}
          <div className="brai-product">
            <div className="brai-product-img">
              <img
                src={require("../../assets/images/brai-product.jpg")}
                alt=""
              />
              <div className="brai-product-text">
                <div className="brai-product-title">
                  <h2>Brai MAGNUM Sand 3mm GR</h2>
                </div>
                <div className="brai-product-description">
                  Suitable for most type waterproofing application. Material
                  comes with PE film / sand finish with thickness 3mm.
                </div>
              </div>
            </div>
            <div style={{ width: "60px" }}>&nbsp;</div>
            <div className="brai-product-img">
              <img
                src={require("../../assets/images/product-granule.jpg")}
                alt=""
              />
              <div className="brai-product-text">
                <div className="brai-product-title">
                  <h2>Brai MAGNUM Mineral 45 GR</h2>
                </div>
                <div className="brai-product-description">
                  Available with two colour self protecting mineral chips: green
                  and grey, Magnum Mineral is suitable for exposed installation.
                </div>
              </div>
            </div>
          </div>
          {/* Datasheet */}
          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                Learn more of Brai product properties
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                Technical Data Sheet
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Brai;
