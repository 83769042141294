import React, { Component } from "react";
import { connect } from "react-redux";
import { signOut } from "../../redux/actions/authAction";

class NavBar extends Component {
  onSignOutHandler = () => {
    console.log("logout");
    this.props.signOut();
  };
  render() {
    const { authenticated } = this.props;
    return (
      <div className="navbar-container">
        <div id="noharaHeader" className="navbar-head-inter">
          <div className="navbar-head-inter__logo">
            <div className="head-inter-icon">
              <img
                src={require("../../assets/images/welcome-inter-logo.png")}
                alt=""
              />
            </div>
          </div>
          {/* <div className="navbar-mobile">
            <div className="dropdown">
              <div className="navigation-text"><i className="fa fa-bars" style={{fontSize: '20px'}}></i></div>
              <div className="dropdown-menu">
                <Link className="navigation-text" to="/indonesia">HOME</Link>
                <Link className="navigation-text" to="/indonesia/product">PRODUCT</Link>
                <Link className="navigation-text" style={{paddingLeft: '35px'}} to="/indonesia/brai">BRAI</Link>
                <Link className="navigation-text" style={{paddingLeft: '35px'}} to="/indonesia/maris">MARIS</Link>
                <Link className="navigation-text" style={{paddingLeft: '35px'}} to="/indonesia/nipo">NIPO</Link>
                <Link className="navigation-text" style={{paddingLeft: '35px'}} to="/indonesia/hardolass">DR.HARDOLASS</Link>
                <Link className="navigation-text" to="/indonesia/aboutus">ABOUT US</Link>
                <Link className="navigation-text" to="/news">NEWS</Link>
                {
                  authenticated 
                  ? (
                      <div>
                        <Link className="navigation-text" to="/addnews">ADD NEWS</Link>
                        <Link className="navigation-text" to="/message">MESSAGE</Link>
                      </div>
                    )
                    : null
                }
                <Link className="navigation-text" to="/indonesia/contactus">CONTACT US</Link>
                {
                  authenticated
                  ? <a className="logout" onClick={this.onSignOutHandler}>LOGOUT</a>
                  : <Link to="/login" className="navigation-text"><i className="fa fa-user-o"></i> LOGIN</Link>
                }
              </div>
            </div>
          </div> */}
          <div className="navbar-head-inter__country">
            <div className="dropdown">
              <div className="navigation-text" to="/indonesia/product">
                Select your location
              </div>
              <div className="dropdown-content">
                <a className="navigation-text" href="/">
                  China
                </a>
                <a className="navigation-text" href="https://nohara.in">
                  India
                </a>
                <a className="navigation-text" href="/indonesia">
                  Indonesia
                </a>
                <a className="navigation-text" href="https://nohara-inc.co.jp/">
                  Japan
                </a>
                <a className="navigation-text" href="/vietnam">
                  Vietnam
                </a>
              </div>
            </div>
            {/* <div className="country-wrapper">
							<p className="welcome-msg country-text">Welcome to Nohara International Network</p>
							<p className="country-text country-center">Select your location</p>
							<hr />
							<div className="country-list">
								<Link target="_blank" to="/underconstruction" className="country-text">China</Link>
								<a target="_blank" href="https://nohara.in" className="country-text">India</a>
								<Link target="_blank" to="/indonesia" className="country-text">Indonesia</Link>
								<a target="_blank" href="https://nohara-inc.co.jp/" className="country-text">Japan</a>
								<Link target="_blank" to="/vietnam" className="country-text">Vietnam</Link>
							</div>
						</div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToprops, mapDispatchToprops)(NavBar);
