import React, { Component } from "react";
import { Link } from "react-router-dom";
// import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
        {/* <div className="footer-top">
          <div className="footer-top-inquiry">
            <Link to="/indonesia/contactus" className="footer-btn">
              Click here for inquiries
            </Link>
          </div>
          <div className="footer-contact">
            <div className="footer-phone">Nohara Care</div>
            <div className="footer-phone">+62 811-1575-893</div>
            <div className="footer-operation">
              <p>Operating hours : 8.30 - 17:30</p>
              <div
                style={{ borderBottom: "1px dashed #fff", paddingTop: "20px" }}
              ></div>
            </div>
            <a
              className="footer-email"
              href="mailto:indo_sales@nohara-inc.co.jp"
            >
              indo_sales@nohara-inc.co.jp
            </a>
          </div>
        </div> */}
        <div className="footer-bottom">
          <div className="footer-company">
            <div>
              <div className="footer-logo">
                <img
                  src={require("../../assets/images/logo-kebawah.png")}
                  alt=""
                />
              </div>
              {/* <div className="footer-logo">
                <img src={require('../../assets/images/logo_international.png')} alt=""/>
              </div> */}
            </div>
            <div className="footer-navigation mb-15">
              <Link className="navigation-text" to="/indonesia">
                HOME
              </Link>
            </div>
            <div className="footer-navigation mb-15">
              <div className="mb-30">
                <Link className="navigation-text" to="/indonesia/product">
                  PRODUCT
                </Link>
              </div>
              <div className="mb-15">
                <Link className="navigation-text" to="/indonesia/nipo">
                  NIPO
                </Link>
              </div>
              <div className="mb-15">
                <Link className="navigation-text" to="/indonesia/flortech">
                  FLORTECH
                </Link>
              </div>
              <div className="mb-15">
                <Link className="navigation-text" to="/indonesia/laticrete">
                  LATICRETE
                </Link>
              </div>
              <div className="mb-15">
                <Link className="navigation-text" to="/indonesia/insutech">
                  INSUTECH
                </Link>
              </div>
              <div className="mb-15">
                <Link className="navigation-text" to="/indonesia/brai">
                  BRAI
                </Link>
              </div>
              <div className="mb-15">
                <Link className="navigation-text" to="/indonesia/maris">
                  MARIS
                </Link>
              </div>
            </div>
            <div className="footer-navigation">
              <Link className="navigation-text" to="/indonesia/aboutus">
                ABOUT US
              </Link>
            </div>
            <div className="footer-navigation">
              <Link className="navigation-text" to="/news">
                NEWS
              </Link>
            </div>
          </div>
          <div className="footer-sosial">
            <div className="mb-15">Sosial media</div>
            <div className="mb-15">
              <a
                href="https://www.instagram.com/nohara_indonesia/"
                target="_blank"
                rel="noopener noreferrer"
                className="navigation-text"
              >
                <div className="sosial-logo">
                  <img
                    src={require("../../assets/images/ig-icon.png")}
                    className="img-sosial"
                    alt=""
                  />
                  <p style={{ marginLeft: "10px" }}>instagram</p>
                </div>
              </a>
            </div>
            <div className="mb-15">
              <a
                href="https://www.facebook.com/noharainternational"
                target="_blank"
                rel="noopener noreferrer"
                className="navigation-text"
              >
                <div className="sosial-logo">
                  <img
                    src={require("../../assets/images/fb-icon.png")}
                    className="img-sosial"
                    alt=""
                  />
                  <p style={{ marginLeft: "10px" }}>facebook</p>
                </div>
              </a>
            </div>
          </div>
          <div className="footer-subscribe">
            <div className="mb-15">
              <a
                href="https://share.hsforms.com/19wMoVLelRuqRtdi2fBDxLw5mbjs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footer-subscribe-button">Subscribe</div>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-end">&copy; Copyright Nohara Indonesia 2020</div>
      </div>
    );
  }
}

export default Footer;
