import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { setNews } from "../../redux/actions/newsAction";
import Navbar from "../NavBar/NavbarInter";
import Footer from "../Footer/FooterInter";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import moment from "moment";

const homeBanner = [
  {
    image: require("../../assets/images/banner/inter-banner/inter1.png"),
    mobImage: require("../../assets/images/banner/mobile-banner/mob-a.jpg"),
    link: "https://nohara-inc.co.jp/en/company/message/",
    leftBtn: "LEARN MORE ABOUT NOHARA's WAY",
    leftLink: "https://nohara-inc.co.jp/en/company/message/",
    leftCss: "btn-slide btn-slide__nohara-ways1",
  },
  {
    image: require("../../assets/images/banner/inter-banner/inter2.png"),
    mobImage: require("../../assets/images/banner/mobile-banner/mob-b.jpg"),
    link: "#country",
    leftBtn: "SELECT YOUR LOCATION",
    leftLink: "#country",
    leftCss: "btn-slide btn-slide__nohara-ways2",
  },
  {
    image: require("../../assets/images/banner/inter-banner/inter3.png"),
    mobImage: require("../../assets/images/banner/mobile-banner/mob-c.jpg"),
    link: "/indonesia/contactus ",
    leftBtn: "MAKE AN INQUIRY",
    leftLink: "/indonesia/contactus",
    leftCss: "btn-slide btn-slide__nohara-ways2",
  },
];

const countryList = [
  {
    country: "China",
    img: require("../../assets/images/country/china.jpg"),
    imgText: "Building Material",
    link: "",
    name: "NOHARA SHANGHAI TRADING CO., LTD.",
    desc: "",
    info: ["(Sales & Distribution of Construction Materials)"],
    address:
      "Room 916, Building 7, Xinya Technology Innovation Park, 701 Jiujing Road, Jiuting Town, Songjiang District, Shanghai",
    phones: ["+ 86-21-67816855", "+ 86-135-04032830"],
    email: "china@nohara-inc.co.jp",
  },
  {
    country: "India",
    img: require("../../assets/images/country/india.jpg"),
    imgText: "Building Material",
    link: "https://nohara.in",
    name: "NOHARA TRADING AND SERVICES INDIA PVT. LTD.",
    desc: "",
    info: ["(Sales & Distribution of Construction Materials)"],
    address: "F6 Basement, Lajpat Nagar 3, New Delhi 110024, India",
    phones: ["+ 91-9910161918"],
    email: "info@nohara.in",
  },
  {
    country: "Indonesia",
    img: require("../../assets/images/country/Indonesia.jpg"),
    imgText: "Building Material",
    link: "/indonesia",
    name: "PT. NOHARA ALTA INDONESIA",
    desc: "",
    info: ["(Sales & Distribution of Construction Materials)"],
    address:
      "	Menara Karya 28th Floor. Jl. H. R. Rasuna Said Blok X-5 Kav 1-2. RT.1/RW.2, Kuningan, Kuningan Timur, Setiabudi, Jakarta Selatan, DKI Jakarta -12950.",
    phones: ["+ 62-21-2953-9350"],
    email: "admin@nohara-alta.com",
  },
  {
    country: "Japan",
    img: require("../../assets/images/country/japan.jpg"),
    imgText: "Building Material",
    link: "https://nohara-inc.co.jp/",
    name: "NOHARA HOLDINGS, INC.",
    desc: "Sales of interior materials, remodeling materials, housing equipment, building façade design and engineering, civil engineering component manufacturing and installation, etc.",
    info: [
      "Planning / investigation / design / management and contracting of various works",
      "Accumulation support service",
    ],
    address: "1-1-11 Shinjuku, Shinjuku-ku, Tokyo",
    phones: ["+ 81-34586-0001"],
    email: "info@noharajapan.jp",
  },
  {
    country: "Singapore",
    img: require("../../assets/images/country/singapore.jpg"),
    imgText: "Building Material",
    link: "",
    name: "NOHARA INTERNATIONAL PTE. LTD.",
    desc: "Regional headquarter of Nohara International’s companies in Asia",
    info: [],
    address: "105 Cecil Street #22-00 The Octagon, Singapore 069534",
    phones: ["+ 65-6904-9421"],
    email: "singapore@nohara-inc.co.jp",
  },
  {
    country: "Vietnam",
    img: require("../../assets/images/country/vietnam.jpg"),
    imgText: "Building Material",
    link: "/vietnam",
    name: "NOHARA INTERNATIONAL PTE. LTD.",
    desc: "",
    info: ["(Sales & Distribution of Construction Materials)"],
    address:
      "Floor 15, Lim II Tower, 62A Cach Mang Thang Tam Street, Ward 6, District 3, Ho Chi Minh City, Vietnam",
    phones: ["+ 84-989691354"],
    email: "vietnam@nohara-inc.co.jp",
  },
];

const NextArrow = ({ onClick }) => {
  return (
    <div className="slide__arrowPrev">
      <i className="fas fa-angle-right" onClick={onClick} />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="slide__arrowNext">
      <i className="fas fa-angle-left" onClick={onClick} />
    </div>
  );
};

class Home extends Component {
  state = {
    news: null,
  };

  getNews = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/showall`,
      });

      if (data && data.status === "success") {
        this.setState(
          {
            news: data.message,
          },
          () => {
            console.log(this.state.news);
            this.props.setNews(data.message);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.getNews();
    window.scrollTo(0, 0);
  }

  render() {
    const arrows = {
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    const { news } = this.props;
    console.log("ini news ya", news);
    return (
      <>
        <Navbar />
        <div className="international-wrapper">
          <div className="international-banner">
            <Slider
              dots={false}
              arrows={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              {...arrows}
            >
              {homeBanner.map((list, index) => (
                <a href={list.link} key={index} className="top-image">
                  <img className="desktop" src={list.image} alt="" />
                  <img className="mob" src={list.mobImage} alt="" />
                  <a
                    href={list.leftLink}
                    className={list.leftCss}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="btn-slide__text">{list.leftBtn}</p>
                  </a>
                </a>
              ))}
            </Slider>
          </div>

          <div className="devider"></div>

          <div className="international-desc padding-page">
            <div className="desc-title">
              <p className="desc-title__text">Nohara International Network</p>
            </div>
            <div className="devider"></div>
            <div className="desc-sub">
              <p>
                We at Nohara International intend to continue creating the
                future of homes, cities, and living.
              </p>
            </div>
            <div className="devider"></div>
            <div className="desc-content">
              <p>
                Using the strength of the technical capacity we have cultivated
                in the 420 years since our establishment in 1598, we focus on
                our core businesses of building materials, construction, and
                urban spatial design and construction while devoting energy to
                creating unique new businesses.
              </p>
            </div>
          </div>
          <div className="devider"></div>
          <div id="country" className="country-desc padding-page">
            {countryList.map((list, index) => (
              <div key={index} className="country-card">
                <p className="country-name">{list.country}</p>
                <div className="card-img">
                  <img src={list.img} alt="" />
                  {/* <p className="img-text">{list.imgText}</p> */}
                  {list.link && (
                    <a
                      href={list.link}
                      rel="noopener noreferrer"
                      className="btn-country-card"
                    >
                      GO TO WEBSITE
                    </a>
                  )}
                </div>
                <div className="card-info">
                  <p className="card-info-text" style={{ fontWeight: "bold" }}>
                    {list.name}
                  </p>
                  <p className="card-info-text">{list.desc}</p>
                  {list.info.map((item, index) => (
                    <p key={index} className="card-info-text">
                      {item}
                    </p>
                  ))}
                  <p className="card-info-title">Address</p>
                  <p className="card-info-text">{list.address}</p>
                  <p className="card-info-title">Contact Information</p>
                  {list.phones.map((phone, index) => (
                    <p key={index} className="card-info-text">
                      {phone}
                    </p>
                  ))}
                  <p className="card-info-title">Email</p>
                  <p className="card-info-text">{list.email}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="international-news">
            <div className="news-title">
              <p className="news-title__text">News</p>
            </div>
            <div className="news-list-container">
              {news &&
                news.map((list, index) => (
                  <Link
                    to={`/news/${list.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    className="news-wrapper"
                  >
                    <div className="news-date">
                      {moment(list.createdAt).format("LL")}
                    </div>
                    <div className="news-box">
                      <div className="news-tag-border">{list.tag}</div>
                    </div>
                    <div className="news-title-content">{list.title}</div>
                    <div className="news-icon">
                      <i class="far fa-clone"></i>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
          <div className="devider"></div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    news: state.news.news,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    setNews: (data) => dispatch(setNews(data)),
  };
};

export default connect(mapStateToprops, mapDispatchToprops)(Home);
