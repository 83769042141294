import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { setNews } from "../../redux/actions/newsAction";
import Navbar from "../NavBar/NavbarVietnam";
import Footer from "../Footer/FooterVietnam";

class HomeVietnam extends Component {
  state = {
    news: null,
    langChoise: "VIET",
    langText: {
      eng: {
        sub1: {
          text: "Our Products",
        },
        sub2: {
          text: "Brai Torch-on membrane waterproofing",
        },
        sub3: {
          text: "Bitumen polymer membrane solution for general waterproofing application",
        },
        sub4: {
          text: "Maris Polymers Liquid Polyurethane system",
        },
        sub5: {
          text: "NIPO Speciality construction chemicals",
        },
        sub6: {
          text: "Bitumen Primer, coating, and anti-root chemical",
        },
        sub7: {
          text: "Laticrete",
        },
        sub8: {
          text: "Concrete floor densifier, hardener, and sealer.",
        },
        sub9: {
          text: "Click here for inquiries",
        },
        sub10: {
          text: "+84 916282875",
        },
        sub11: {
          text: "Operating hours: 8:30 - 17:30",
        },
        sub12: {
          text: "duong-vg@nohara-inc.co.jp",
        },
      },
      viet: {
        sub1: {
          text: "Những sản phẩm của chúng tôi",
        },
        sub2: {
          text: "Màng chống thấm khò lửa Brai",
        },
        sub3: {
          text: "Giải pháp màng Bitum Polyme trong lĩnh vực chống thấm",
        },
        sub4: {
          text: "Hệ Polyurethane của Maris Polymers",
        },
        sub5: {
          text: "Hóa chất xây dựng chuyên dụng NIPO",
        },
        sub6: {
          text: "Lớp lót Bitum và hóa chất ức chế rễ cây",
        },
        sub7: {
          text: "Laticrete",
        },
        sub8: {
          text: "Chất làm đặc , tăng cứng và chất trám khe cho sàn bê tông.",
        },
        sub9: {
          text: "Bấm vào đây cho những giải đáp thắc mắc",
        },
        sub10: {
          text: "(+84) 916282875",
        },
        sub11: {
          text: "Giờ mở cửa: 8:00 - 17:00 ",
        },
        sub12: {
          text: "Giờ mở cửa: 8:00 - 17:00 ",
        },
      },
    },
  };

  getNews = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/showall`,
      });

      if (data && data.status === "success") {
        this.setState(
          {
            news: data.message,
          },
          () => {
            console.log(this.state.news);
            this.props.setNews(data.message);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.getNews();
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { news, isEn } = this.props;
    const { langText } = this.state;
    return (
      <>
        <Navbar />
        <div className={isEn ? "home-container" : "home-container viet-theme"}>
          {/* Top Image  */}
          <div className="top-image">
            <img src={require("../../assets/images/topHome.jpg")} alt="" />
            <div className="top-card">
              <div className="top-card__top">
                <div>
                  <span className="text-linkidea">Link ideas.</span> 
                  <span className="text-buildfuture">Build future.</span>
                </div>
                <div className="text-home-japan">
                  思いをつなげ、未来をつくる。
                </div>
              </div>
              <div className="top-card__bottom">
                Sharing and introducing construction and manufacturing
                technology across national borders
              </div>
            </div>
            <Link to="/vietnam/contactus" className="btn-contact">
              come and talk to us &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {`>>`}
            </Link>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "60px" }}>&nbsp;</div>
          {/* Content */}
          <div className="padding-page">
            <div className="content">
              <div className="sub-content">
                <div className="sub-content-top">
                  <h3>NOHARA INTERNATIONAL</h3>
                  <div className="sup-content-top-text">
                    <p>
                      Spreading the know-how and new efficiency initiatives that
                      we have cultivated in the Japanese construction industry,
                      we will cross boundaries and rise above common sense,
                      seeking new growth markets and providing solutions that
                      meet the needs of each country. We focus on our core
                      businesses of building materials, construction, and urban
                      spatial design and construction while devoting energy to
                      creating unique new businesses.
                    </p>
                    <p>
                      Link ideas. Build future. Sharing and introducing
                      construction and manufacturing technology across national
                      borders.
                    </p>
                    <p>
                      • Japan • Indonesia • Singapore • China • India • Vietnam
                      •
                    </p>
                  </div>
                </div>
                <div className="sub-content-banner">
                  <img
                    src={require("../../assets/images/aboutUs.jpg")}
                    alt=""
                  />
                  <Link to="/vietnam/aboutus" className="button-banner">
                    <span>our company {`>>`}</span>
                  </Link>
                </div>
              </div>
              <div className="sub-content-news">
                <div className="news-title">News</div>
                <div className="news-list">
                  {news ? (
                    news.map((list) => (
                      <>
                        <div key={list.id} className="news-list-item">
                          <Link
                            to={`/news/${list.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {new Date(list.createdAt)
                              .toISOString()
                              .substr(0, 10)}{" "}
                            --- {list.title}
                          </Link>
                        </div>
                      </>
                    ))
                  ) : (
                    <div>Can't find any News</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <div className="devider">&nbsp;</div>
          {/* Product */}
          <div className="product-content-container">
            <div className="product-content">
              <div className="product-subcontainer">
                <h2 className="product-title">
                  {langText[`${isEn ? "eng" : "viet"}`].sub1.text}
                </h2>
                <div className="product-card-container">
                  <Link to="/vietnam/brai" className="product-cards">
                    <div className="product-subcard">
                      <div className="product-img">
                        <img
                          src={require("../../assets/images/brai.jpg")}
                          alt=""
                        />
                      </div>
                      <div className="product-text">
                        <div className="product-text-title">
                          <h4>
                            {langText[`${isEn ? "eng" : "viet"}`].sub2.text}
                          </h4>
                        </div>
                        <div className="product-text-description">
                          {langText[`${isEn ? "eng" : "viet"}`].sub3.text}
                        </div>
                      </div>
                    </div>
                  </Link>
                  {/* <Link to="/indonesia/maris" className="product-cards">
                    <div className="product-subcard">
                      <div className="product-img">
                        <img src={require('../../assets/images/maris.jpg')} alt=""/>
                      </div>
                      <div className="product-text">
                        <div className="product-text-title">
                          <h4>{ langText[`${isEn ? 'eng' : 'viet'}`].sub4.text }</h4>
                        </div>
                        <div className="product-text-description">
                          Speciality liquid based polyurethane waterproofing for high performance usage
                        </div>
                      </div>
                    </div>
                  </Link> */}
                  <Link to="/vietnam/nipo" className="product-cards">
                    <div className="product-subcard">
                      <div className="product-img">
                        <img
                          src={require("../../assets/images/nipo.jpg")}
                          alt=""
                        />
                      </div>
                      <div className="product-text">
                        <div className="product-text-title">
                          <h4>
                            {langText[`${isEn ? "eng" : "viet"}`].sub5.text}
                          </h4>
                        </div>
                        <div className="product-text-description">
                          {langText[`${isEn ? "eng" : "viet"}`].sub6.text}
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/vietnam/laticrete" className="product-cards">
                    <div className="product-subcard">
                      <div className="product-img">
                        <img
                          src={require("../../assets/images/laticrete/por-banner.jpg")}
                          alt=""
                        />
                      </div>
                      <div className="product-text">
                        <div className="product-text-title">
                          <h4>
                            {langText[`${isEn ? "eng" : "viet"}`].sub7.text}
                          </h4>
                        </div>
                        <div className="product-text-description">
                          {langText[`${isEn ? "eng" : "viet"}`].sub8.text}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    news: state.news.news,
    isEn: state.auth.isEn,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    setNews: (data) => dispatch(setNews(data)),
  };
};

export default connect(mapStateToprops, mapDispatchToprops)(HomeVietnam);
