import React, { Component } from "react";
import Footer from '../Footer/Footer'
import { Redirect } from "react-router-dom";
// import { Link } from "react-router-dom";


import { connect } from "react-redux";
import { signIn } from "../../redux/actions/authAction";

import Navbar from '../NavBar/NavBar'

class Login extends Component {
  state = {
    email: "",
    password: "",
    error: "",
    signedInUser: ""
  };

  changeHanlder = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmitHandler = async () => {
    console.log(this.state)

    const user = {
      email: this.state.email,
      password: this.state.password
    }
    
    await this.props.signIn(user)
  }

  componentDidMount() {
    console.log('mounted')
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if(header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { authenticated } = this.props
    if (authenticated) return <Redirect to="/" />;
    return (
      <>
        <Navbar />
        <div className="home-container">
          {/* Top Image  */}
          {/* Devider */}
          <div style={{ width: '100vw', height: '40px'}}>
            &nbsp;
          </div>
          {/* Content */}
          <div className="center-container">
            <div className="login-box">
              <div className="login-title">
                <h3 style={{ textAlign: 'center'}}>LOGIN</h3>
              </div>
              <div className="login-input-container">
                <div className="input-container">
                  <label className="input-label">Email</label>
                  <input style={{ textAlign: 'center'}} name="email" id="email" type="email" className="input-box" onChange={this.changeHanlder}/>
                </div>
                <div className="input-container" style={{ marginBottom: '50px'}}>
                  <label className="input-label">Password</label>
                  <input style={{ textAlign: 'center'}} name="password" id="password" type="password" className="input-box" onChange={this.changeHanlder}/>
                </div>

                <div className="login-btn" onClick={this.onSubmitHandler}>
                  LOGIN
                </div>
              </div>
            </div>
            {/* Devider */}
            <div style={{ width: '100vw', height: '40px'}}>
              &nbsp;
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = state => {
  return {
    authenticated: state.auth.authenticated
  };
};

const mapDispatchToprops = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default connect(
  mapStateToprops,
  mapDispatchToprops
)(Login);
