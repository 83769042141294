import React, { Component } from "react";
import Footer from "../Footer/Footer";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { getNews } from "../../redux/actions/newsAction";
// import { Link } from "react-router-dom";

import Navbar from "../NavBar/NavBar";

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${api}/api/v1/nohara/uploadimage/news-images`);
    const data = new FormData();
    data.append("image", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      console.log(response.image_url);
      resolve({
        data: {
          link: response.image_url,
        },
      });
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      console.log(error);
      reject(error);
    });
  });
}

class AdminPage extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    title: "",
    tag: "",
    desc: "",
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  changeHanlder = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  addNews = async (news) => {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${api}/api/v1/nohara/news/add`,
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJyb2xlIjoiQWRtaW4iLCJpYXQiOjE1OTk1NTA0NTZ9.zXEOZ80pxtvlW_gde9ShwYe_nswyv9SIU7q8U1U8TqE`,
        },
        data: news,
      });

      if (data && data.status === "success") {
        alert("Success add news");
        await this.props.history.push("/news");
      }
    } catch (error) {
      alert("error");
    }
  };

  onHandlerSubmitNews = () => {
    const data = {
      title: this.state.title,
      content: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
      tag: this.state.tag,
      desc: this.state.desc,
    };

    console.log("ready: ", data);

    this.addNews(data);
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { editorState } = this.state;
    return (
      <>
        <Navbar />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img src={require("../../assets/images/contact_us.jpg")} alt="" />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>ADD NEWS</h3>
                </div>
                {/* <div className="text-subtitle">Let us hear from you</div> */}
              </div>
            </div>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Content */}
          <div className="desktop-only">
            Sorry, this page only display in desktop size
          </div>
          <div className="admin-container">
            {/* <div className="admin-sidenav">
              <div className="admin-navigation-text">News List</div>
              <div className="admin-navigation-text">Add News</div>
            </div> */}
            <div className="admin-page">
              <div className="add-news">
                <div className="input-container">
                  <label className="input-label">Title</label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    className="input-box"
                    onChange={this.changeHanlder}
                  />
                </div>
                <div className="input-container">
                  <Editor
                    className="editor"
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history', 'image'],
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      image: { uploadCallback: uploadImageCallBack },
                    }}
                    wrapperClassName="news-wrapper"
                    editorClassName="news-input-box"
                  />
                </div>
                <div className="input-container">
                  <label className="input-label">
                    Sort Description (max.400 character)
                  </label>
                  <textarea
                    rows="4"
                    maxlength="400"
                    name="desc"
                    id="desc"
                    type="text"
                    placeholder="Input sort description for your content"
                    className="input-box-textarea"
                    onChange={this.changeHanlder}
                  />
                </div>
                <div>
                  <select
                    name="tag"
                    id="tag"
                    className="input-box"
                    style={{
                      marginTop: "30px",
                      marginBottom: "30px",
                      padding: "10px",
                    }}
                    onChange={this.changeHanlder}
                  >
                    <option value="">Choose Tag</option>
                    <option value="brai">BRAI</option>
                    <option value="maris">MARIS</option>
                    <option value="nipo">NIPO</option>
                    <option value="dr.harbolas">DR.HARDOLASS</option>
                  </select>
                </div>
                {/* <div className="input-container-textarea">
                  <label className="input-label-textarea">News Content</label>
                  <textarea rows="9" type="text" className="input-box-textarea"/>
                </div> */}
                <div className="contact-btn" onClick={this.onHandlerSubmitNews}>
                  Submit
                </div>
              </div>
              {/* <div className="contact-input-container">
                <div className="input-container">
                  <label className="input-label">Title</label>
                  <input type="text" className="input-box"/>
                </div>
                <div className="input-container-textarea">
                  <label className="input-label-textarea">News Content</label>
                  <textarea rows="9" type="text" className="input-box-textarea"/>
                </div>
                <div className="contact-btn">
                  Submit
                </div>
              </div> */}
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const MapStateToDispatch = (dispatch) => {
  return {
    getNews: () => dispatch(getNews()),
  };
};

export default connect(null, MapStateToDispatch)(AdminPage);
