import React from "react";

const NipoBenefitComponent = ({ benefit }) => {
  console.log("benefit: ", benefit);
  return (
    <div className="nipo-benefit-container">
      {benefit.map((list, index) => (
        <div className="nipo-benefit" key={index}>
          {list.image && (
            <div className="nipo-benefit-img">
              <img src={require(`../../assets/images/${list.image}`)} alt="" />
            </div>
          )}

          <div className="nipo-benefit-text">
            <p className="text-bold mb10">{list.title}</p>
            <p>{list.desc}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NipoBenefitComponent;
