import React, { Component } from "react";
import { Link } from "react-router-dom";

class LinkButtons extends Component {
  render() {
    return (
      <div className="top-welcome-country">
        <div className="top-welcome-country-wrapper">
          <p>Welcome to Nohara International Network!</p>
          <div className="top-welcome-country-button">
            <Link to="/underconstruction" className="btn-country-name">
              China
            </Link>
            <a
              href="https://nohara.in"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-country-name"
            >
              India
            </a>
            <Link to="/indonesia" className="btn-country-name">
              Indonesia
            </Link>
          </div>
          <div className="top-welcome-country-button">
            <a
              href="https://nohara-inc.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-country-name"
            >
              Japan
            </a>
            <Link to="/underconstruction" className="btn-country-name">
              Singapore
            </Link>
            <Link to="/vietnam" className="btn-country-name">
              Vietnam
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LinkButtons;
