import React from "react";

const BrandLogoListComponent = ({ height }) => {
  return (
    <div className="nipo-benefit-container">
      <div className="nipo-benefit">
        <div className="nipo-benefit-img">
          <img src={require("../../assets/images/logo/1.png")} alt="" />
        </div>
      </div>
      <div className="nipo-benefit">
        <div className="nipo-benefit-img">
          <img src={require("../../assets/images/logo/2.png")} alt="" />
        </div>
      </div>
      <div className="nipo-benefit">
        <div className="nipo-benefit-img">
          <img src={require("../../assets/images/logo/3.png")} alt="" />
        </div>
      </div>
      <div className="nipo-benefit">
        <div className="nipo-benefit-img">
          <img src={require("../../assets/images/logo/4.png")} alt="" />
        </div>
      </div>
      <div className="nipo-benefit">
        <div className="nipo-benefit-img">
          <img src={require("../../assets/images/logo/5.png")} alt="" />
        </div>
      </div>
      <div className="nipo-benefit">
        <div className="nipo-benefit-img">
          <img src={require("../../assets/images/logo/6.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default BrandLogoListComponent;
