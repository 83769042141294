import React, { Component } from "react";
import Footer from "../Footer/Footer";
import DownloadModal from "../Utils/Modal";

import Navbar from "../NavBar/NavBar";
import DeviderComponent from "../Utils/Devider";
import ContactUsComponent from "../Utils/ContactUs";
import NipoTopComponent from "../Utils/NipoTop";
import BoxTitleBgComponent from "../Utils/BoxTitleBG";
import BoxTitleComponent from "../Utils/BoxTitle";
import BenefitBoxComponent from "../Utils/BenefitBox";

class Flortech extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="TDS_ROKRETE_PU_MF.pdf"
        />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/flortech.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>FLORTECH</h3>
                </div>
                <div className="text-subtitle">
                  Manufacturing and Food Grade Flooring Solution
                </div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <DeviderComponent height={"60px"} />
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="maris-content">
              <div className="maris-content-title">
                <h2>NOHARA X FLORTECH</h2>
              </div>
              <div className="maris-content-p">
                At Nohara we are selective in our products range and we will
                prequalify each brand to ensure we bring good quality products
                to our customer.
              </div>
              <div className="maris-content-p">
                FLORTECH has been specialising in the development of
                polyurethanes and epoxy resins for a wide range of applications
                with the European partners. Flortech develop finished products
                for industrial customers, which are used in the areas of food &
                beverage, warehouses, industrial kitchens, car parks, and many
                other areas. For industrial applications, the enduring
                high-quality products and efficient manufacturing processes are
                important to always stay one step ahead in international
                business. Flortech production facility in Malaysia ensures
                reliability, quality and efficiency through automated processes
                and quality controls. Flortech supply to a wide range of
                customers thru out Asia Pacific e.g. South Korea, Thailand,
                China, Vietnam, etc.{" "}
              </div>
            </div>
          </div>
          {/* Content */}
          <ContactUsComponent />
          <div className="maris-list-container">
            <BoxTitleComponent title={"FLORTECH ROKRETE PU MF "} />
            <h1 className="text-center">PRODUCT FEATURE</h1>
            <DeviderComponent />
            <NipoTopComponent
              orientation={true}
              image={"products/kbf/1.png"}
              url1={"products/icons/27.png"}
              url2={"products/icons/28.png"}
              subtitle={`High temperatures are common in food factories. Due to this, all components in 
                the factory, including the floor, require materials that are resistant to high temperatures. 
                Rokrete PU MF as a quality flooring material features temperature resistance 
                from -40° C to 120° C`}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/kbf/3.png"}
              url1={"products/icons/33.png"}
              url2={"products/icons/32.png"}
              subtitle={`Chemical resistance is a required parameter in food manufacturing. 
                Rokrete PU MF comes with good chemical resistance features, thus ensuring your 
                floor is safe from chemical reactions that may occur during production activities.`}
            />

            <DeviderComponent />
            <NipoTopComponent
              orientation={true}
              image={"products/kbf/2.png"}
              url1={"products/icons/29.png"}
              url2={"products/icons/34.png"}
              subtitle={`Food factories generally have quite extreme temperature changes. Due to the 
                creation of high temperatures during production activities and then a change to a lower 
                temperature occurs. So the factory components must support this possibility. 
                Rokrete PU MF is equipped with a thermal shock resistance feature that makes the 
                factory floor safer`}
            />

            <DeviderComponent />
            <NipoTopComponent
              image={"products/kbf/4.png"}
              url1={"products/icons/31.png"}
              url2={"products/icons/30.png"}
              subtitle={`One of the factors required from a food factory is sterile and hygienic even 
                from bacteria though. All material components used must be able to support these 2 things, 
                including the material used for the floor. Rokrete PU MF as a special material for food 
                factory floors has been certified HACCP which ensures that the material has Anti Microbial 
                components`}
            />
          </div>

          <ContactUsComponent />
          <BenefitBoxComponent
            product={"FLORTECH"}
            benefit={[
              {
                title: "Europan Flooring Technology",
                desc: "FLORTECH has been specialising in the development of polyurethanes and epoxy resins for a wide range of applications with our European partners.",
              },
              {
                title: "Produce High Performance Material",
                desc: "Our production facility in Malaysia ensures reliability, quality and efficiency through automated processes and quality controls.",
              },
              {
                title: "Supply to Wide Range Customer",
                desc: "FLORTECH supply to a wide range of customers thru out Asia Pacific e.g. South Korea, Thailand, China, Vietnam, etc.",
              },
              {
                title: "Focus to Develop Better Quality",
                desc: "FLORTECH many years of experience help us to achieve the development goal quickly and efficiently. Durability, temperature resistance, wear resistance and resistance to external influences are just some of our development goals.  ",
              },
            ]}
          />
          <ContactUsComponent />

          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                Learn more of Flortech product properties
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                Technical Data Sheet
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Flortech;
