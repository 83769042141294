import React, { Component } from "react";
import { Link } from "react-router-dom";
import DownloadModal from "../Utils/Modal";

import { connect } from "react-redux";

import Navbar from "../NavBar/NavbarVietnam";
import Footer from "../Footer/FooterVietnam";

class BraiVietnam extends Component {
  state = {
    modalIsOpen: false,
    langChoise: "VIET",
    langText: {
      eng: {
        sub1: {
          text: "At Nohara we are selective in our products range and we will prequalify each brand to ensure we bring good quality products to customer.",
        },
        sub2: {
          text: "Product Brai, are directly imported from Italy and also fully supported by Brai's experts in Italy and Thailand.",
        },
        sub3: {
          text: "Product Highlight: MAGNUM APP torch-on membranes waterproofing is the ideal solution offered by Brai for all type of roofs and for retaining walls which ensure a high protection against ascending moisture. The material used is the lastest generation of reinforcement in non-woven polyester stabilized with glass fiber threads, making installation better and easier.",
        },
        sub4: {
          text: "We're happy to answer your inquiries! Contact us for more information. +84 916282875 or duong-vg@nohara-inc.co.jp",
        },
        sub5: {
          text: "Contact Us",
        },
        sub6: {
          text: "The founder of polymer-bitumen waterproofing membrane",
        },
        sub7: {
          text: "Being the first, made a difference!",
        },
        sub8: {
          text: "First discovery of bitumen addition with APP polymer by Erwin Brietner resulting in the rise of generation of polymer-bitumen waterproofing membrane, thus the company Brai in 1975.",
        },
        sub9: {
          text: "Since then Brai continuing to innovate and constantly contributed to the evolution of waterproofing, because it’s in Brai DNA.",
        },
        sub10: {
          text: "European quality tailormade for tropical weather",
        },
        sub11: {
          text: "Compared with most other torch-on-membrane brands in Indonesia that do not have their own factories, Brai products come directly from a production facility in Italy which has a production facility of 30,000 square meters.",
        },
        sub12: {
          text: "Brai's products and its manufacturing process complied with European standard QC systems, so Brai's products are ensured to be the highest quality. In addition, Brai products have been adapted to suit installation conditions in tropical climates.",
        },
        sub13: {
          text: "Product guarantee for 10 years",
        },
        sub14: {
          text: "Worry-free when you use Brai waterproofing for your building. Not only Brai has a good product but also a technical assistant to provide installation instructions for all our customers.",
        },
        sub15: {
          text: "Water leakage that occur are often the result of incorrect installation, therefore Brai realize the importance of installation guidance.",
        },
        sub16: {
          text: "What benefits do you get more when choosing Brai?",
        },
        sub17: {
          text: "Easier application",
        },
        sub18: {
          text: "Better polyester quality makes installation easier. Brai material has a thickness of 3mm with good flexibility and is not brittle, especially for application on the corners.",
        },
        sub19: {
          text: "Better product stability",
        },
        sub20: {
          text: "Has a smaller product dimensional tolerance, < 0.3% compared to other products with 1% tolerance. Good product stability and low shrinkage provide confidence, especially for long-term waterproofing installations.",
        },
        sub21: {
          text: "Original brand since 1975",
        },
        sub22: {
          text: "As inventor of polymer bitumen waterproofing membrane, Brai continues to innovate waterproofing system. And assure product quality with European Standard 30,000 m2 production facility in Italy.",
        },
        sub23: {
          text: "Get assistance from expertise",
        },
        sub24: {
          text: "Get assistance directly from Brai inventor and expertise. We will support all your needs regarding technical service directly from Brai experts in Italy and Thailand.",
        },
        sub25: {
          text: "… and even more by partnering with Nohara",
        },
        sub26: {
          text: "Nohara Care benefits",
        },
        sub27: {
          text: "Get more networks for your business with support of Nohara exclusive customer service program, Nohara care. We grow together, we succeed together!",
        },
        sub28: {
          text: "High value product",
        },
        sub29: {
          text: "Get all the benefits of products and supports with competitive prices for choosing Brai!",
        },
        sub30: {
          text: "Brai MAGNUM Sand 3mm GR",
        },
        sub31: {
          text: "Suitable for most type waterproofing application. Material comes with PE film / sand finish with thickness 3mm.",
        },
        sub32: {
          text: "Brai MAGNUM Mineral 45 GR",
        },
        sub33: {
          text: "Available with two colour self protecting mineral chips: green and grey, Magnum Mineral is suitable for exposed installation.",
        },
        sub34: {
          text: "Learn more of Brai product properties",
        },
        sub35: {
          text: "Technical Data Sheet",
        },
      },
      viet: {
        sub1: {
          text: "Tại Nohara, chúng tôi chọn lọc những những sản phẩm của mình và những thương hiệu nổi tiếng để mang đến những sản phẩm chất lượng tốt cho khách hàng",
        },
        sub2: {
          text: "Sản phẩm Brai, được nhập khẩu trực tiếp từ Ý và được hỗ trợ nhanh chóng từ các chuyên gia tại Ý và Thái Lan.",
        },
        sub3: {
          text: "Điểm nổi bật của sản phẩm: Màng chống thấm khò lửa MAGNUM APP là giải pháp lý tưởng được Brai cung cấp cho tất cả các loại mái và tường chắn, đảm bảo độ bảo vệ cao chống lại độ ẩm tăng dần. Vật liệu được sử dụng là thế hệ gia cố mới nhất bằng polyester không dệt được ổn định bằng sợi thủy tinh, giúp việc lắp đặt tốt hơn và dễ dàng hơn.",
        },
        sub4: {
          text: "Chúng tôi rất vui để trả lời những thắc mắc của các bạn! Liên hệ chúng tôi để biết thêm thông tin tại (+84) 916282875",
        },
        sub5: {
          text: "Liên hệ chúng tôi",
        },
        sub6: {
          text: "Người sáng tạo màng chống thấm Bitum",
        },
        sub7: {
          text: "Là người tiên phong, tạo sự khác biệt",
        },
        sub8: {
          text: "Là người đầu tiên phát hiện về việc bổ sung bitum với polyme APP bởi Erwin Brietner dẫn đến sự ra đời của màng chống thấm polyme-bitum, do đó công ty Brai được thành lập vào năm 1975.",
        },
        sub9: {
          text: "Kể từ đó, Brai tiếp tục đổi mới và không ngừng đóng góp vào sự phát triển trong lĩnh vực chống thấm, vì nó là mục tiêu cốt lõi của Brai.",
        },
        sub10: {
          text: "Chất lượng Châu Âu, thiết kế riêng cho khí hậu nhiệt đới",
        },
        sub11: {
          text: "So với hầu hết các thương hiệu màng khò lửa khác ở Việt Nam không có nhà máy riêng, các sản phẩm của Brai được sản xuất  trực tiếp từ một cơ sở sản xuất ở Ý, có cơ sở sản xuất rộng 30.000 mét vuông.",
        },
        sub12: {
          text: "Sản phẩm của Brai và quy trình sản xuất tuân thủ theo hệ thống quản lý chất lượng tiêu chuẩn Châu Âu, vì vậy sản phẩm của Brai được đảm bảo chất lượng cao nhất. Ngoài ra, các sản phẩm của Brai đã được điều chỉnh để phù hợp với điều kiện lắp đặt ở vùng khí hậu nhiệt đới.",
        },
        sub13: {
          text: "Bảo hành sản phẩm trong 10 năm",
        },
        sub14: {
          text: "Đừng lo lắng khi bạn sử dụng chống thấm Brai cho công trình của mình. Brai không chỉ có sản phẩm tốt mà còn có nhân viên kỹ thuật hướng dẫn lắp đặt cho tất cả khách hàng của chúng tôi.",
        },
        sub15: {
          text: "Rò rỉ nước xảy ra thường là kết quả của việc lắp đặt sai, do đó Brai nhận thấy tầm quan trọng của hướng dẫn lắp đặt.",
        },
        sub16: {
          text: "Những ích lợi gì khi bạn chọn Brai",
        },
        sub17: {
          text: "Dễ dàng lắp đặt hơn",
        },
        sub18: {
          text: "Chất lượng polyester tốt hơn giúp việc lắp đặt dễ dàng hơn. Vật liệu Brai có độ dày 3mm, có độ mềm dẻo tốt và không bị giòn, đặc biệt dùng để dán trên các góc.",
        },
        sub19: {
          text: "Sản phẩm ổn định hơn",
        },
        sub20: {
          text: "Có dung sai kích thước sản phẩm nhỏ hơn 0,3% so với các sản phẩm khác có dung sai 1%. Độ ổn định của sản phẩm tốt và độ co ngót thấp mang lại sự tự tin, đặc biệt cho các công trình chống thấm lâu dài.",
        },
        sub21: {
          text: "Thương hiệu từ năm 1975",
        },
        sub22: {
          text: "Là nhà phát minh ra màng chống thấm polyme bitum, Brai tiếp tục đổi mới hệ thống chống thấm. Và đảm bảo chất lượng sản phẩm với tiêu chuẩn Châu Âu 30.000 m2 xưởng sản xuất tại Ý.",
        },
        sub23: {
          text: "Nhận hỗ trợ từ các chuyên gia",
        },
        sub24: {
          text: "Nhận sự hỗ trợ trực tiếp từ nhà sáng lập Brai và các chuyên gia. Chúng tôi sẽ hỗ trợ tất cả các nhu cầu của các bạn liên quan đến dịch vụ kỹ thuật trực tiếp từ các chuyên gia của Brai ở Ý và Thái Lan.",
        },
        sub25: {
          text: "Và thậm chí hơn thế nữa khi hợp tác với Nohara",
        },
        sub26: {
          text: "Những ích lợi từ Nohara",
        },
        sub27: {
          text: "Mở rộng thêm mạng lưới cho doanh nghiệp của bạn với sự hỗ trợ của chương trình dịch vụ khách hàng độc quyền của Nohara. Chúng ta cùng nhau phát triển, cùng nhau thành công!",
        },
        sub28: {
          text: "Sản phẩm giá trị cao",
        },
        sub29: {
          text: "Nhận tất cả các lợi ích của sản phẩm và hỗ trợ với giá cả cạnh tranh khi lựa chọn Brai!",
        },
        sub30: {
          text: "Brai MAGNUM Sr 3mm cát",
        },
        sub31: {
          text: "Thích hợp cho hầu hết các loại ứng dụng chống thấm. Vật liệu đi kèm với màng PE / cát hoàn thiện có độ dày 3mm.",
        },
        sub32: {
          text: "Brai MAGNUM Mineral 45 SR",
        },
        sub33: {
          text: "Có sẵn hai màu tự bảo vệ: xanh lá cây và xám, Magnum Mineral thích hợp để lắp đặt tại những vị trí lộ ra",
        },
        sub34: {
          text: "Tìm hiểu thêm về các đặc tính sản phẩm của Brai",
        },
        sub35: {
          text: "Bảng dữ liệu kỹ thuật",
        },
      },
    },
  };

  openModal = () => {
    console.log("open modal true");
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { isEn } = this.props;
    const { langText, modalIsOpen } = this.state;
    return (
      <>
        <Navbar />
        <DownloadModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          fileName="brai.pdf"
        />
        <div className={isEn ? "home-container" : "home-container viet-theme"}>
          {/* Top Image  */}
          <div className="contact-top-image">
            <img src={require("../../assets/images/brai_r01.jpg")} alt="" />
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "60px" }}>&nbsp;</div>
          {/* Slider  */}
          <div className="center-container">
            <div className="small-slider-container">
              <div className="slider-box">1</div>
              <div className="slider-box">2</div>
              <div className="slider-box">3</div>
            </div>
          </div>
          <div className="nohara-x-brai">
            <div className="brai-content">
              <div className="brai-content-title">
                <h2>NOHARA X BRAI</h2>
              </div>
              <div className="brai-content-p">
                {langText[isEn ? "eng" : "viet"].sub1.text}
              </div>
              <div className="brai-content-p">
                {langText[isEn ? "eng" : "viet"].sub2.text}
              </div>
              <div className="brai-content-p">
                {langText[isEn ? "eng" : "viet"].sub3.text}
              </div>
            </div>
          </div>
          {/* Content */}
          <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                {langText[isEn ? "eng" : "viet"].sub4.text}
              </p>
              <Link to="/vietnam/contactus" className="maris-contact__button">
                {langText[isEn ? "eng" : "viet"].sub5.text}
              </Link>
            </div>
          </div>
          <div className="brai-list-container">
            <div className="brai-list-left">
              <div className="brai-list-img">
                <img src={require("../../assets/images/braiKbf1.jpg")} alt="" />
              </div>
              <div className="brai-list-text">
                <div className="brai-number mb-30">01</div>
                <div className="brai-list-title mb-30">
                  {langText[isEn ? "eng" : "viet"].sub6.text}
                </div>
                <div className="brai-list-paragraph">
                  <p className="mb-30">
                    {langText[isEn ? "eng" : "viet"].sub7.text}
                  </p>
                  <p className="mb-30">
                    {langText[isEn ? "eng" : "viet"].sub8.text}
                  </p>
                  <p className="mb-30">
                    {langText[isEn ? "eng" : "viet"].sub9.text}
                  </p>
                </div>
              </div>
            </div>
            <div className="brai-list-right">
              <div className="brai-list-img-right">
                <img src={require("../../assets/images/braiKbf2.jpg")} alt="" />
              </div>
              <div className="brai-list-text-right">
                <div className="brai-number mb-30">02</div>
                <div className="brai-list-title mb-30">
                  {langText[isEn ? "eng" : "viet"].sub10.text}
                </div>
                <div className="brai-list-paragraph-right">
                  <p className="mb-30">
                    {langText[isEn ? "eng" : "viet"].sub11.text}
                  </p>
                  <p className="mb-30">
                    {langText[isEn ? "eng" : "viet"].sub12.text}
                  </p>
                </div>
              </div>
            </div>
            <div className="brai-list-left">
              <div className="brai-list-img">
                <img src={require("../../assets/images/braiKbf3.jpg")} alt="" />
              </div>
              <div className="brai-list-text">
                <div className="brai-number mb-30">03</div>
                <div className="brai-list-title mb-30">
                  {langText[isEn ? "eng" : "viet"].sub13.text}
                </div>
                <div className="brai-list-paragraph">
                  <p className="mb-30">
                    {langText[isEn ? "eng" : "viet"].sub14.text}
                  </p>
                  <p className="mb-30">
                    {langText[isEn ? "eng" : "viet"].sub15.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Benefit */}
          <div className="maris-contact-container">
            <div className="maris-contact">
              <p className="maris-contact__text">
                {langText[isEn ? "eng" : "viet"].sub4.text}
              </p>
              <Link to="/vietnam/contactus" className="maris-contact__button">
                {langText[isEn ? "eng" : "viet"].sub5.text}
              </Link>
            </div>
          </div>

          <div className="brai-benefit">
            <div className="brai-benefit-box">
              <div className="brai-box-title">
                <h1>{langText[isEn ? "eng" : "viet"].sub16.text}</h1>
              </div>
              <div className="benefit-top">
                <div className="benefit-top-list">
                  <h3>{langText[isEn ? "eng" : "viet"].sub17.text}</h3>
                  <p>{langText[isEn ? "eng" : "viet"].sub18.text}</p>
                </div>
                <div className="benefit-top-list">
                  <h3>{langText[isEn ? "eng" : "viet"].sub19.text}</h3>
                  <p>{langText[isEn ? "eng" : "viet"].sub20.text}</p>
                </div>
              </div>

              <div className="benefit-top">
                <div className="benefit-top-list">
                  <h3>{langText[isEn ? "eng" : "viet"].sub21.text}</h3>
                  <p>{langText[isEn ? "eng" : "viet"].sub22.text}</p>
                </div>
                <div className="benefit-top-list">
                  <h3>{langText[isEn ? "eng" : "viet"].sub23.text}</h3>
                  <p>{langText[isEn ? "eng" : "viet"].sub24.text}</p>
                </div>
              </div>

              <div className="brai-box-title" style={{ marginTop: "60px" }}>
                <h1>{langText[isEn ? "eng" : "viet"].sub25.text}</h1>
              </div>

              <div className="benefit-top">
                <div className="benefit-top-list">
                  <h3>{langText[isEn ? "eng" : "viet"].sub26.text}</h3>
                  <p>{langText[isEn ? "eng" : "viet"].sub27.text}</p>
                </div>
                <div className="benefit-top-list">
                  <h3>{langText[isEn ? "eng" : "viet"].sub28.text}</h3>
                  <p>{langText[isEn ? "eng" : "viet"].sub29.text}</p>
                </div>
              </div>
            </div>
          </div>
          {/* Product */}
          <div className="brai-product">
            <div className="brai-product-img">
              <img
                src={require("../../assets/images/brai-product.jpg")}
                alt=""
              />
              <div className="brai-product-text">
                <div className="brai-product-title">
                  <h2>{langText[isEn ? "eng" : "viet"].sub30.text}</h2>
                </div>
                <div className="brai-product-description">
                  {langText[isEn ? "eng" : "viet"].sub31.text}
                </div>
              </div>
            </div>
            <div style={{ width: "60px" }}>&nbsp;</div>
            <div className="brai-product-img">
              <img
                src={require("../../assets/images/product-granule.jpg")}
                alt=""
              />
              <div className="brai-product-text">
                <div className="brai-product-title">
                  <h2>{langText[isEn ? "eng" : "viet"].sub32.text}</h2>
                </div>
                <div className="brai-product-description">
                  {langText[isEn ? "eng" : "viet"].sub33.text}
                </div>
              </div>
            </div>
          </div>
          {/* Datasheet */}
          <div className="datasheet-container">
            <div className="datasheet-box">
              <div className="datasheet-text">
                {langText[isEn ? "eng" : "viet"].sub34.text}
              </div>
              <button className="datasheet-btn" onClick={this.openModal}>
                {langText[isEn ? "eng" : "viet"].sub35.text}
              </button>
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    isEn: state.auth.isEn,
  };
};

export default connect(mapStateToprops)(BraiVietnam);
