import React from "react";

const BenefitBoxComponent = ({ product, benefit }) => {
  return (
    <div className="brai-benefit">
      <div className="brai-benefit-box">
        <div className="brai-box-title">
          <h1>What benefit do you get more when choosing {product}?</h1>
        </div>
        <div className="benefit-list-container">
          {benefit.map((list, index) => (
            <div className="benefit-list" key={index}>
              <h3>{list.title}</h3>
              <p>{list.desc}</p>
            </div>
          ))}
        </div>

        <div className="brai-box-title" style={{ marginTop: "60px" }}>
          <h1>… and even more by partnering with Nohara</h1>
        </div>

        <div className="benefit-top">
          <div className="benefit-top-list">
            <h3>Nohara Care benefits</h3>
            <p>
              Get more networks for your business with support of Nohara
              exclusive customer service program, Nohara care. We grow together,
              we succeed together!
            </p>
          </div>
          <div className="benefit-top-list">
            <h3>High value product</h3>
            <p>
              Get all the benefits of products and supports with competitive
              prices for choosing Brai!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitBoxComponent;
