import React, { Component } from "react";
import Footer from "../Footer/Footer";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import axios from "axios";
import api from "../../configs/api";

import { connect } from "react-redux";
import { getNews } from "../../redux/actions/newsAction";
import htmlToDraft from "html-to-draftjs";
// import { Link } from "react-router-dom";

import Navbar from "../NavBar/NavBar";

class AdminPage extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    title: "",
    tag: "",
    id: null,
    desc: "",
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  changeHanlder = (e) => {
    console.log("change handler: ", e.target.value);
    this.setState({ [e.target.id]: e.target.value });
  };

  addNews = async (news) => {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `${api}/api/v1/nohara/news/update/${this.state.id}`,
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJyb2xlIjoiQWRtaW4iLCJpYXQiOjE1OTk1NTA0NTZ9.zXEOZ80pxtvlW_gde9ShwYe_nswyv9SIU7q8U1U8TqE`,
        },
        data: news,
      });

      if (data && data.status === "success") {
        alert(data.message);
        await this.props.history.push("/news");
      }
    } catch (error) {
      alert("error");
    }
  };

  getNewsWithId = async (id) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${api}/api/v1/nohara/news/showById/${id}`,
      });

      if (data && data.status === "success") {
        console.log("new with id: ", data.message);
        this.setState(
          {
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(data.message && data.message.content).contentBlocks
              )
            ),
            title: data.message && data.message.title,
            tag: data.message && data.message.tag,
            id: data.message && data.message.id,
            desc: data.message && data.message.desc,
          },
          () => console.log("state : ", this.state)
        );
      }
    } catch (error) {
      console.log(error);
      alert("error");
    }
  };

  onHandlerSubmitNews = () => {
    const data = {
      title: this.state.title,
      content: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
      tag: this.state.tag,
      desc: this.state.desc,
    };

    console.log("ready: ", data);

    this.addNews(data);
  };

  componentDidMount() {
    this.getNewsWithId(this.props.location.pathname.split("/")[2]);
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    const { editorState, title, tag, desc } = this.state;
    return (
      <>
        <Navbar />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img src={require("../../assets/images/contact_us.jpg")} alt="" />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>EDIT NEWS</h3>
                </div>
                {/* <div className="text-subtitle">Let us hear from you</div> */}
              </div>
            </div>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Content */}
          <div className="admin-container">
            {/* <div className="admin-sidenav">
              <div className="admin-navigation-text">News List</div>
              <div className="admin-navigation-text">Add News</div>
            </div> */}
            <div className="admin-page">
              <div className="add-news">
                <div className="input-container">
                  <label className="input-label">Title</label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    value={title}
                    className="input-box"
                    onChange={(value) => this.changeHanlder(value)}
                  />
                </div>
                <div className="input-container">
                  <Editor
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "embedded",
                        "emoji",
                        "remove",
                        "history",
                      ],
                    }}
                    editorState={editorState}
                    wrapperClassName="news-wrapper"
                    editorClassName="news-input-box"
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </div>
                <div className="input-container">
                  <label className="input-label">
                    Sort Description (max.400 character)
                  </label>
                  <textarea
                    rows="4"
                    maxlength="400"
                    name="desc"
                    id="desc"
                    value={desc}
                    type="text"
                    placeholder="Input sort description for your content"
                    className="input-box-textarea"
                    onChange={this.changeHanlder}
                  />
                </div>
                <div>
                  <select
                    name="tag"
                    id="tag"
                    className="input-box"
                    value={tag}
                    style={{
                      marginBottom: "30px",
                      marginTop: "30px",
                      padding: "10px",
                    }}
                    onChange={(value) => this.changeHanlder(value)}
                  >
                    <option value="">Choose Tag</option>
                    <option value="brai">BRAI</option>
                    <option value="maris">MARIS</option>
                    <option value="nipo">NIPO</option>
                    <option value="dr.harbolas">DR.HARDOLASS</option>
                  </select>
                </div>
                {/* <div className="input-container-textarea">
                  <label className="input-label-textarea">News Content</label>
                  <textarea rows="9" type="text" className="input-box-textarea"/>
                </div> */}
                <div className="contact-btn" onClick={this.onHandlerSubmitNews}>
                  Submit
                </div>
              </div>
              {/* <div className="contact-input-container">
                <div className="input-container">
                  <label className="input-label">Title</label>
                  <input type="text" className="input-box"/>
                </div>
                <div className="input-container-textarea">
                  <label className="input-label-textarea">News Content</label>
                  <textarea rows="9" type="text" className="input-box-textarea"/>
                </div>
                <div className="contact-btn">
                  Submit
                </div>
              </div> */}
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

const MapStateToDispatch = (dispatch) => {
  return {
    getNews: () => dispatch(getNews()),
  };
};

export default connect(null, MapStateToDispatch)(AdminPage);
