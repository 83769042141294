import React, { Component } from "react";
import Footer from "../Footer/Footer";

import Navbar from "../NavBar/NavBar";

class AboutUs extends Component {
  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    return (
      <>
        <Navbar />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/about_us.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>ABOUT US</h3>
                </div>
                <div className="text-subtitle">Company Profile</div>
              </div>
            </div>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Content */}
          <div className="about-table-container">
            <table className="about-table">
              <tbody>
                <tr className="tr-phone">
                  <td className="td-title">Name of company</td>
                  <td>PT Nohara Alta Indonesia</td>
                </tr>
                <tr className="tr-phone">
                  <td>Jakarta Office Address </td>
                  <td>
                    Menara Karya 28th Floor. Jl. H. R. Rasuna Said Blok X-5 Kav
                    1-2. RT.1/RW.2, Kuningan, Kuningan Timur, Setiabudi, Jakarta
                    Selatan, DKI Jakarta -12950.
                  </td>
                </tr>
                <tr className="tr-phone">
                  <td>Nohara Care address </td>
                  <td>
                    Ruko Rose Garden 5 no 55. Grand Galaxy City. Bekasi, Jawa
                    Barat. Indonesia 17148.
                  </td>
                </tr>{" "}
                <tr className="tr-phone">
                  <td>Phone</td>
                  <td>+62 811-1575-893</td>
                </tr>
                <tr className="tr-phone">
                  <td>Company description</td>
                  <td>
                    'Sales & Distribution of Construction Materials in
                    Indonesia. We supply functional building materials and
                    high-quality construction technology which can elevate the
                    environment of Indonesian living life.
                  </td>
                </tr>
                <tr className="tr-phone">
                  <td>About Nohara Japan</td>
                  <td>
                    'Nohara Holdings, Inc. <br />
                    'Using the strength of the technical capacity the group have
                    cultivated in the 420 years since our establishment in 1598,
                    Nohara Holdings focus on core businesses of building
                    materials, construction, and urban spatial design and
                    construction while devoting energy to creating unique new
                    businesses.
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <iframe
              title="Nohara Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63462.816833830984!2d106.79435135889783!3d-6.207398899599115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f40f7dd029af%3A0xbf5af7de1145c146!2sPT.%20Indocom%20Indonesia!5e0!3m2!1sid!2sid!4v1675007176038!5m2!1sid!2sid"
              width="100%"
              height="450"
              style={{ border: 0 }}
              aria-hidden="false"
            ></iframe>
          </div>
          {/* Devider */}
          <div style={{ width: "100vw", height: "40px" }}>&nbsp;</div>
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default AboutUs;
