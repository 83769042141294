import React, { Component } from "react";
import Footer from "../Footer/Footer";

import Navbar from "../NavBar/NavBar";
import BoxTitleComponent from "../Utils/BoxTitle";
import BrandLogoListComponent from "../Utils/BrandLogoList";
import DeviderComponent from "../Utils/Devider";
import ProductDescriptionComponent from "../Utils/ProductDesc";

class Product extends Component {
  componentDidMount() {
    console.log("mounted");
    window.scrollTo(0, 0);

    const header = document.getElementById("noharaHeader");

    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      });
    }
  }

  render() {
    return (
      <>
        <Navbar />
        <div className="home-container">
          {/* Top Image  */}
          <div className="contact-top-image">
            <img
              src={require("../../assets/images/banner/new_banner/product_page.png")}
              alt=""
            />
            <div className="contact-top-card">
              <div className="contact-top-card__top">
                <div>
                  <h3>PRODUCT</h3>
                </div>
                <div className="text-subtitle">
                  Our product and solution range
                </div>
              </div>
            </div>
          </div>
          <DeviderComponent />

          <BoxTitleComponent title={"OUR BRAND PARTNER"} />

          <BrandLogoListComponent />

          <BoxTitleComponent title={"WATERPROOFING SOLUTIONS"} />

          <ProductDescriptionComponent
            title="System 1: Simple Concrete Planter Box"
            image="products/3.png"
            description={`
                This system is a solution for a simple planter box on your balcony. By using bitumen based waterproofing Nipo R-Pruf which also functions as a root barrier to prevent root growth which can damage the waterproofing layer and concrete surface.
              `}
            product={[
              {
                list: "NIPO Bitu Seal, Nipo Bitumen Emulsion, NIPO R-Pruf.",
                link: "/indonesia/nipo",
              },
            ]}
          />
          <ProductDescriptionComponent
            title="System 2 : Flat-roof Waterproofing Protection from UV"
            image="products/1.png"
            description={
              "To prevent leaks on flat roofs, a UV-resistant flexible layer is needed which can prevent shrinkage that causing concrete cracks and create water leak. Also use NIPO Clad Putty as a gap filler if there are gaps in the concrete surface."
            }
            product={[
              {
                list: "NIPO Clad Putty, Nipo Clad Polymer.",
                link: "/indonesia/nipo",
              },
            ]}
          />

          <ProductDescriptionComponent
            title="System 3 : Toilet Waterproofing System"
            image="products/4.png"
            description={
              "Toilets waterproofing are required to prevent seepage of water from floor slab and walls as toilets involve use of water and plumbing works. Cement Based waterproofing of toilet floors helps to stops the ingress of water flushing. This is one of the easiest and conventional methods of waterproofing which is gaining popularity due to its ease of construction. Cement Based waterproofing products are easy to mix and apply."
            }
            product={[
              {
                list: "NIPO Bitu Seal, Nipo Polymix 406",
                link: "/indonesia/nipo",
              },
            ]}
          />

          <ProductDescriptionComponent
            title="System 4 : Exposed Artificial Green Roof "
            image="products/6.png"
            description={
              "Installing a membrane on the roof is perfect solution to prevent leaks. Green bituminous membrane is the right choice as a waterproofing solution while creating an artistic impression because it looks like a stretch of grass. Installing the membrane on a flat surface such as a roof is considered the most effective and efficient. Use a seal compound to lock the gap for the movement of water."
            }
            product={[
              {
                list: "NIPOBitu Seal",
                link: "/indonesia/nipo",
              },
              {
                list: "Insutech Green Granule",
                link: "/indonesia/insutech",
              },
            ]}
          />

          <ProductDescriptionComponent
            title="System 5 : Large area roof concealed membrane system"
            image="products/5.png"
            description={
              "Bituminous Sanded membranes can be a solution for waterproofing in large areas. Apply primer as the first coat, then apply torch on membrane and screed over the area above. This screed layer is useful as a protector from the sun"
            }
            product={[
              {
                list: "NIPOBitu Seal, NIPO Bitumen Emulsion Sand",
                link: "/indonesia/nipo",
              },
              {
                list: "Brai Magnum",
                link: "/indonesia/brai",
              },
            ]}
          />

          <BoxTitleComponent title={"FLOORING SOLUTION"} />

          <ProductDescriptionComponent
            title="System 6 : Heavy Duty - Hygenic Flooring System"
            image="products/8.png"
            description={
              "Heavy duty floor areas such as food grade flooring systems require special materials to ensure that the floor layer is able to support activities on the floor. Rokrete PU MF can be a solution for floors with these specifications. With material characteristics that are resistant to extreme temperature changes, anti-microbial, resistant to high temperatures and also resistant to chemicals, it is the perfect material for a food grade flooring system."
            }
            product={[
              {
                list: "Rokrete PU MF",
                link: "/indonesia/flortech",
              },
            ]}
          />

          <ProductDescriptionComponent
            title="System 7 : Fast Return To Service Flooring Maintenance"
            image="products/7.png"
            description={
              "Floor of factory area  is an important component in creating production. For this reason, a short maintenance system is needed so as not to suppress production activities for too long. Laticrete Guard EA comes with a fast return to service system that provides solutions for factory floor repairs with a faster curing time of 1 x 24 hours."
            }
            product={[
              {
                list: "Laticrete Guard EA",
                link: "/indonesia/laticrete",
              },
            ]}
          />

          <DeviderComponent />
          {/* Footer */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Product;
